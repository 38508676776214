import { AllocateTickets } from "@/components/dashboard/accreditation/AllocateTickets";
import { CreateGroupDialog } from "@/components/dashboard/accreditation/create/CreateGroupDialog";
import { CreateIndividualDialog } from "@/components/dashboard/accreditation/create/CreateIndividualDialog";
import { GroupDetails } from "@/components/dashboard/accreditation/GroupDetails";
import { GroupDocuments } from "@/components/dashboard/accreditation/GroupDocuments";
import { GroupList } from "@/components/dashboard/accreditation/GroupList";
import { GroupSettings } from "@/components/dashboard/accreditation/settings/GroupSettings";
import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";
import { Documents } from "@/components/portal/accreditation/Documents";
import { Loading } from "@/components/portal/Loading";
import { BASE_URL } from "@/dashboardQueries";
import { useDarkMode } from "@/hooks";
import {
  AccreditationGroup,
  AccreditationIndividual,
  TicketAllocation,
} from "@/models/accreditation";
import { PaginatedResponse } from "@/models/Dashboard";
import { useAccreditationStore } from "@/store/accreditation";
import { invalidateAccreditationGroups } from "@/utils/accreditation";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  EllipsisVerticalIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios, { AxiosResponse } from "axios";
import { set } from "date-fns";
import { Formik, FormikValues } from "formik";
import {
  BoxesIcon,
  DollarSignIcon,
  DownloadIcon,
  LinkIcon,
  MailIcon,
  PlusIcon,
  Send,
  Shapes,
  Shield,
  TicketIcon,
  UserIcon,
  UserPlus,
  UserPlus2,
} from "lucide-react";
import { send } from "process";
import { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

export const fetchGroup = async (
  groupId: number
): Promise<AccreditationGroup> => {
  const url = `${BASE_URL}/accreditation/groups/${groupId}`;
  const { data } = await axios.get(url);
  return data;
};

const fetchGroups = async (
  page: number,
  filters?: Record<string, any>
): Promise<PaginatedResponse<AccreditationGroup>> => {
  const url = `${BASE_URL}/accreditation/groups?page=${page}&${new URLSearchParams(
    filters
  ).toString()}`;
  const { data } = await axios.get(url);
  return data;
};

const fetchIndividuals = async (
  page: number,
  filters?: Record<string, any>
): Promise<PaginatedResponse<AccreditationIndividual>> => {
  const url = `${BASE_URL}/accreditation/individuals?page=${page}&${new URLSearchParams(
    filters
  ).toString()}`;
  const { data } = await axios.get(url);
  return data;
};

export const resendInvitation = async (individualId: number) => {
  try {
    await axios.post(
      `${BASE_URL}/accreditation/individuals/${individualId}/resend-invite`
    );
    toast.success("📨 Invitation resent successfully");
    console.log("Invitation resent successfully");
  } catch (error) {
    console.error("Error resending invitation:", error);
  }
};

export const AccreditationGroups = () => {
  const {
    createGroupOpen,
    setCreateGroupOpen,
    createIndividualOpen,
    setCreateIndividualOpen,
    createGroupFromTemplateOpen,
    setCreateGroupFromTemplateOpen,
    setAllocateTicketsOpen,
    setIndividual,
  } = useAccreditationStore();
  const isDarkMode = useDarkMode();

  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [groupToEdit, setGroupToEdit] = useState<AccreditationGroup | null>();
  const [groupContactDialogOpen, setGroupContactDialogOpen] = useState(false);

  // Deleting a Group
  const [groupToDeleteDialogOpen, setGroupToDeleteDialogOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<AccreditationGroup | null>(
    null
  );

  // Deleting an Individual
  const [individualToDeleteDialogOpen, setIndividualToDeleteDialogOpen] =
    useState(false);
  const [individualToDelete, setIndividualToDelete] =
    useState<AccreditationIndividual | null>(null);

  // Group Search
  const [groupSearch, setGroupSearch] = useState("");
  const [groupPage, setGroupPage] = useState(1);

  // Deleting a Group Contact
  const [groupContactToDeleteDialogOpen, setGroupContactToDeleteDialogOpen] =
    useState(false);
  const [groupContactToModify, setGroupContactToModify] =
    useState<AccreditationIndividual | null>(null);

  // Fetching group details
  const { data, error, isLoading, isError } = useQuery<AccreditationGroup>(
    ["group", groupId],
    () => fetchGroup(parseInt(groupId)),
    {
      // keepPreviousData: true,
      enabled: !!groupId,
    }
  );

  // Posting data
  const handleSubmit = async (values: FormikValues) => {
    try {
      if (groupId) {
        values["parent"] = groupId;
      }
      const response = await axios.post(
        `${BASE_URL}/accreditation/groups`,
        values
      );
      console.log("Group created successfully:", response.data);
      setCreateGroupOpen(false);
      // Invalidate and refetch the groups query
      queryClient.invalidateQueries(["groups"]);
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  // Deleting data
  const handleDelete = async (groupIdToDelete: number) => {
    try {
      await axios.delete(`${BASE_URL}/accreditation/groups/${groupIdToDelete}`);
      console.log("Group contact deleted successfully");
      navigate(
        `/dashboard/accreditation/groups${groupId ? `/${groupId}` : ""}`
      );
      // Invalidate and refetch the groups query
      invalidateAccreditationGroups(
        queryClient,
        groupId ? parseInt(groupId) : 0
      );
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  // Deleting individual
  const handleIndividualDelete = async (
    individual: AccreditationIndividual
  ) => {
    try {
      await axios.delete(
        `${BASE_URL}/accreditation/individuals/${individual.id}`
      );
      console.log("Individual deleted successfully");
      navigate(`/dashboard/accreditation/groups/${individual.group.id}`);
      invalidateAccreditationGroups(
        queryClient,
        groupId ? parseInt(groupId) : 0
      );
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  // Editing data
  const handleEditOpen = async (groupIdToEdit: number) => {
    setGroupToEdit(null);
    try {
      const group: AxiosResponse<AccreditationGroup> = await axios.get(
        `${BASE_URL}/accreditation/groups/${groupIdToEdit}`
      );
      setGroupToEdit(group.data);
      setCreateGroupOpen(true);
    } catch (error) {}
  };

  const handleDeleteGroupContact = async (individualId: number) => {
    try {
      await axios.delete(
        `${BASE_URL}/accreditation/individuals/${individualId}`
      );
      invalidateAccreditationGroups(
        queryClient,
        groupId ? parseInt(groupId) : 0
      );
    } catch (error) {
      console.error("Error deleting group contact:", error);
    }
  };

  // Edit individual
  const editIndividual = async (individualId: number) => {
    try {
      const individual: AxiosResponse<AccreditationIndividual> =
        await axios.get(
          `${BASE_URL}/accreditation/individuals/${individualId}`
        );
      setIndividual(individual.data);
      setCreateIndividualOpen(true);
    } catch (error) {}
  };

  const closeDeleteGroupDialog = () => {
    setGroupToDeleteDialogOpen(false);
    setTimeout(() => setGroupToDelete(null), 500);
  };

  const closeDeleteIndividualDialog = () => {
    setIndividualToDeleteDialogOpen(false);
    setTimeout(() => setIndividualToDelete(null), 500);
  };

  const closeDeleteGroupContactDialog = () => {
    setGroupContactToDeleteDialogOpen(false);
    setTimeout(() => setGroupContactToModify(null), 500);
  };

  const createGroup = async (request: any) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/accreditation/groups`,
        request
      );
      console.log("Group created successfully:", response.data);
      // Invalidate and refetch the groups query
      queryClient.invalidateQueries(["groups"]);
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const festivalTemplateGroups = [
    { groupName: "Food Vendors", icon: "🍔" },
    { groupName: "Traders", icon: "💵" },
    { groupName: "Art Department", icon: "🖌️" },
    { groupName: "Artists & Performers", icon: "🎶" },
    { groupName: "Bars", icon: "🍻" },
    { groupName: "Security", icon: "🔐" },
    { groupName: "Production", icon: "👷" },
    { groupName: "Ticketing", icon: "🎟️" },
    { groupName: "Waste Management", icon: "♻️" },
    { groupName: "Medical", icon: "🏥" },
    { groupName: "Volunteers", icon: "🧑‍🏭" },
    { groupName: "Press & Media", icon: "📸" },
  ];

  const clubNightGroups = [
    { groupName: "DJs & Performers", icon: "🎧" },
    { groupName: "Guest List", icon: "🤩" },
    { groupName: "Bar Staff", icon: "🍹" },
    { groupName: "Security", icon: "🔐" },
    { groupName: "Promoters", icon: "📣" },
    { groupName: "Photographers & Videographers", icon: "📸" },
  ];

  const templates = [
    {
      id: "festival",
      name: "Festival",
      subtitle: "Large collection of groups at outdoor music festival",
      href: "#",
      template: (
        <div className="ml-5 mt-5 border dark:border-white/5 rounded-md">
          {festivalTemplateGroups.map((trader, index) => (
            <div
              className={`flex p-2 dark:border-white/10 items-center ${
                index === 0 ? "" : "border-t"
              }`}
            >
              <div className="mr-2">{trader.icon}</div>
              <div className="dark:text-white text-sm font-semibold">
                {trader.groupName}
              </div>
            </div>
          ))}
        </div>
      ),
      onClick: async () => {
        await createGroup(
          festivalTemplateGroups.map((group) => {
            return {
              name: group.groupName,
              icon: group.icon,
              parent: groupId ? groupId : null,
            };
          })
        );
        setCreateGroupFromTemplateOpen(false);
      },
    },
    {
      id: "club-night",
      name: "Club Night",
      subtitle: "Small collection of groups for a club night",
      href: "#",
      template: (
        <div className="ml-5 mt-5 border dark:border-white/10 rounded-md">
          {clubNightGroups.map((trader, index) => (
            <div
              className={`flex p-2 dark:border-white/10 items-center ${
                index === 0 ? "" : "border-t"
              }`}
            >
              <div className="mr-2">{trader.icon}</div>
              <div className="dark:text-white text-sm font-semibold">
                {trader.groupName}
              </div>
            </div>
          ))}
        </div>
      ),
      onClick: async () => {
        await createGroup(
          clubNightGroups.map((group) => {
            return {
              name: group.groupName,
              icon: group.icon,
              parent: groupId ? groupId : null,
            };
          })
        );
        setCreateGroupFromTemplateOpen(false);
      },
    },
  ];

  const updateOrder = async (
    model: string,
    payload: Array<{ itemId: number; order: number }>
  ) => {
    try {
      const transformedPayload = payload.map(({ itemId, order }) => ({
        itemId,
        order,
      }));

      await axios.post(
        `${BASE_URL}/accreditation/${model}/order`,
        transformedPayload
      );
    } catch (error) {
      console.error("Error updating order", error);
    }
  };

  const { data: groupsData, isLoading: isLoadingGroups } = useQuery(
    ["groups", groupId, groupSearch],
    () =>
      fetchGroups(groupPage, {
        parent: groupId ? groupId : "null",
        search: groupSearch,
      })
  );

  const { data: individualsData, isLoading: isLoadingIndividuals } = useQuery(
    ["individuals", groupId],
    () => fetchIndividuals(1, { group: groupId ? groupId : "null" })
  );

  const isEmpty =
    groupsData?.results.length == 0 && individualsData?.results.length == 0;

  return (
    <DashboardLayout
      requiredPermissions={["view_user"]}
      pageTitle="Accreditation Groups"
    >
      {/* <AccreditationHorizontalNav activePage="Groups" /> */}

      {isLoadingGroups && isLoadingIndividuals ? (
        <div className="w-full h-screen flex items-center justify-center -mt-64">
          <Loading colour={isDarkMode ? "white" : "gray"} />
        </div>
      ) : (
        <>
          {/* Create group from Template Dialog */}
          <Transition.Root show={createGroupFromTemplateOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              // initialFocus={cancelButtonRef}
              onClose={setCreateGroupFromTemplateOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-75 backdrop-blur-md transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-10 py-10 pt-8 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                      <div className="">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                        >
                          Create Groups from Template
                        </Dialog.Title>
                        <div>
                          <div className="mx-auto">
                            <div className="mt-8 grid grid-cols-2 gap-y-12 xl:gap-x-8">
                              {templates.map((template) => (
                                <div key={template.id}>
                                  <div className="relative">
                                    <div className="relative h-72 w-full overflow-hidden rounded-lg border dark:border-white/5">
                                      {template.template}
                                    </div>
                                    <div className="relative mt-4">
                                      <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                        {template.name}
                                      </h3>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {template.subtitle}
                                      </p>
                                    </div>
                                    <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                                      <div
                                        aria-hidden="true"
                                        className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                                      />
                                    </div>
                                  </div>
                                  <div className="mt-6">
                                    <button
                                      type="button"
                                      id={template.id}
                                      onClick={() => template.onClick()}
                                      className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                                    >
                                      Create from template
                                      <span className="sr-only">
                                        , {template.name}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Create/Edit Group Dialog */}
          <CreateGroupDialog groupId={groupId} groupToEdit={groupToEdit} />

          {/* Create/Edit Individual Dialog */}
          {data && <CreateIndividualDialog group={data} />}

          {data && (
            <>
              <AllocateTickets group={data} /> <GroupSettings group={data} />{" "}
            </>
          )}

          {/* Delete Group Dialog */}
          <Transition.Root show={groupToDeleteDialogOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              // initialFocus={cancelButtonRef}
              onClose={() => closeDeleteGroupDialog()}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-75 backdrop-blur-md transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                          >
                            Delete {groupToDelete?.name}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Are you sure you want to delete? All of your data
                              will be permanently removed forever. This action
                              cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                          onClick={() => {
                            handleDelete(groupToDelete.id);
                            closeDeleteGroupDialog();
                          }}
                        >
                          Yes, delete
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                          onClick={() => closeDeleteGroupDialog()}
                          // ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Delete Group Contact Dialog */}
          <Transition.Root show={groupContactToDeleteDialogOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              // initialFocus={cancelButtonRef}
              onClose={() => closeDeleteGroupContactDialog()}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-75 backdrop-blur-md transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                          >
                            Delete {groupContactToModify?.firstName}{" "}
                            {groupContactToModify?.lastName}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Are you sure you want to delete? All of your data
                              will be permanently removed forever. This action
                              cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                          onClick={() => {
                            handleDeleteGroupContact(groupContactToModify.id);
                            closeDeleteGroupContactDialog();
                          }}
                        >
                          Yes, delete
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                          onClick={() => closeDeleteGroupContactDialog()}
                          // ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Delete Individual Dialog */}
          <Transition.Root show={individualToDeleteDialogOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              // initialFocus={cancelButtonRef}
              onClose={closeDeleteIndividualDialog}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-75 backdrop-blur-md transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                          >
                            Delete {individualToDelete?.firstName}{" "}
                            {individualToDelete?.lastName}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              Are you sure you want to delete? All data related
                              to this individual will be permanently removed
                              forever. This action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                          onClick={() => {
                            handleIndividualDelete(individualToDelete);
                            closeDeleteIndividualDialog();
                          }}
                        >
                          Yes, delete
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                          onClick={() => closeDeleteIndividualDialog()}
                          // ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          <>
            {data && (
              <div className="px-5 py-5 border-b dark:border-white/5">
                <GroupDetails
                  onGroupUpdate={() =>
                    queryClient.invalidateQueries(["group", groupId])
                  }
                  group={data}
                  openCreateGroupDrawer={() => {
                    setGroupToEdit(null);
                    setCreateGroupOpen(true);
                  }}
                />
              </div>
            )}

            <div className="bg-red-0 flex w-full min-h-screen">
              {data && (
                <div className="w-1/4 border-l dark:border-white/5 p-5 order-1">
                  {/* Tickets */}
                  <div className="">
                    <div className="flex items-center">
                      <div
                        data-sidebar="group-label"
                        className="-mt-1 dark:text-gray-500 flex h-8 shrink-0 items-center rounded-md text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                      >
                        Tickets
                      </div>
                      <div className="flex-1 justify-end flex pt-0.5">
                        <button
                          onClick={() => {
                            setAllocateTicketsOpen(true);
                          }}
                          type="button"
                          className="rounded bg-white/10 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                        >
                          <PlusIcon className="h-4 w-4 text-white" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-1">
                      {data.groupAllocatedTickets.length > 0 ? (
                        <div>
                          {data.groupAllocatedTickets.map(
                            (ticketAllocation: TicketAllocation) => (
                              <span className="mr-1.5 mb-1 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                {ticketAllocation.claimed}/
                                {ticketAllocation.quantity}{" "}
                                {ticketAllocation.ticket.name}
                              </span>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="mt-3 h-40 border dark:border-white/10 border-dashed rounded-md flex items-center justify-center px-4">
                          <p className="dark:text-gray-400 text-sm text-center">
                            There are no tickets associated with this group
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Group Contacts */}
                  <div className="mt-6">
                    <div className="flex items-center">
                      <div
                        data-sidebar="group-label"
                        className="-mt-1 dark:text-gray-500 flex h-8 shrink-0 items-center rounded-md text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                      >
                        Group Contacts
                      </div>
                    </div>

                    <div className="mt-1">
                      {data.groupContacts.length > 0 ? (
                        <div>
                          {data.groupContacts.map((contact) => (
                            <div
                              className="mb-2.5 flex w-full"
                              key={contact.id}
                            >
                              <div className="">
                                <div>
                                  <h3 className="dark:text-white text-sm">
                                    {contact.firstName} {contact.lastName}
                                  </h3>
                                </div>
                                <div className="flex items-center cursor-pointer mb-1">
                                  {/* <div className="has-tooltip">
                              <span className="tooltip rounded shadow-lg p-1 bg-red-600">
                                Some Nice Tooltip Text
                              </span>
                              No style
                            </div> */}
                                  <EnvelopeIcon className="h-4 w-4 text-gray-400 mr-1.5" />
                                  <h3 className="text-gray-400 text-xs underline decoration-dotted">
                                    {contact.email}
                                  </h3>
                                </div>
                              </div>
                              <div className="flex-1 justify-end flex items-center">
                                <Menu as="div" className="relative flex-none">
                                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900 dark:hover:text-gray-400">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <EllipsisVerticalIcon
                                      aria-hidden="true"
                                      className="size-5"
                                    />
                                  </Menu.Button>
                                  <Menu.Items className="bg-white absolute right-0 z-10 -ml-1 mt-2 w-48 origin-top-left dark:bg-zinc-800 rounded-md py-1 shadow-lg ring-1 ring-white/10 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                                    <Menu.Item>
                                      <button
                                        onClick={() =>
                                          editIndividual(contact.id)
                                        }
                                        type="button"
                                        className="flex items-center block px-4 py-2 text-sm dark:text-gray-300 text-gray-500 hover:text-gray-800 dark:hover:text-gray-500"
                                      >
                                        <PencilSquareIcon className="h-4 w-4 mr-2" />
                                        Edit
                                      </button>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <button
                                        onClick={() => {
                                          setGroupContactToModify(contact);
                                          setGroupContactToDeleteDialogOpen(
                                            true
                                          );
                                        }}
                                        type="button"
                                        className="flex items-center block px-4 py-2 text-sm dark:text-gray-300 text-gray-500 hover:text-gray-800 dark:hover:text-gray-500"
                                      >
                                        <TrashIcon className="h-4 w-4 mr-2" />
                                        Delete
                                      </button>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <button
                                        onClick={async () => {
                                          await resendInvitation(contact.id);
                                        }}
                                        type="button"
                                        className="flex items-center block px-4 py-2 text-sm dark:text-gray-300 text-gray-500 hover:text-gray-800 dark:hover:text-gray-500"
                                      >
                                        <Send className="h-4 w-4 mr-2" />
                                        Resend invitation
                                      </button>
                                    </Menu.Item>
                                  </Menu.Items>
                                </Menu>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="mt-3 h-40 border dark:border-white/10 border-dashed rounded-md flex items-center justify-center px-4">
                          <p className="dark:text-gray-400 text-sm text-center">
                            There are no group contacts associated with this
                            group
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Documents if it's enabled */}
                  {data.canUploadDocuments && (
                    <div className="mt-6">
                      <div className="flex items-center">
                        <div
                          data-sidebar="group-label"
                          className="-mt-1 dark:text-gray-500 flex h-8 shrink-0 items-center rounded-md text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                        >
                          Documents
                        </div>
                      </div>

                      <div className="mt-1">
                        <Documents groupId={data.uuid} readOnly={true} />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className={data ? "w-3/4" : "w-full"}>
                {isEmpty ? (
                  <div className="w-full pb-36 pt-28">
                    <div className="text-center">
                      <h3 className="mt-2 text-xl font-semibold text-gray-900 dark:text-white">
                        No Groups or Individuals yet
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        An accreditation group is a collection of groups or
                        individuals that are granted access
                      </p>

                      <div className="mt-6 flex justify-center">
                        <div className="w-2/3">
                          <div>
                            <h3 className="font-medium dark:text-zinc-500 text-sm">
                              Get started with
                            </h3>
                          </div>
                          <div className="mt-3 flex justify-center flex-wrap">
                            {[
                              {
                                id: "create-group",
                                name: "Create group",
                                onClick: () => setCreateGroupOpen(true),
                                icon: BoxesIcon,
                              },
                              {
                                id: "create-individual",
                                name: "Create individual",
                                onClick: () => setCreateIndividualOpen(true),
                                icon: UserIcon,
                              },
                              {
                                id: "create-from-template",
                                name: "Create from template",
                                onClick: () =>
                                  setCreateGroupFromTemplateOpen(true),
                                icon: Shapes,
                              },
                              {
                                id: "import",
                                name: "Import (coming soon)",
                                onClick: () => {},
                                icon: DownloadIcon,
                              },
                            ].map((button) => (
                              <button
                                key={button.id}
                                id={button.id}
                                onClick={button.onClick}
                                className="mb-2 mr-2 dark:text-white dark:ring-white/10 flex justify-center rounded-md dark:bg-zinc-800 bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 dark:hover:bg-zinc-800 hover:bg-gray-100"
                              >
                                <button.icon className="h-5 w-5 text-gray-400 mr-1.5" />
                                {button.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <GroupList
                      name="Individuals"
                      onRowClick={(id: number) =>
                        navigate(`/dashboard/accreditation/individuals/${id}`)
                      }
                      handleDelete={(individual: AccreditationIndividual) => {
                        setIndividualToDeleteDialogOpen(true);
                        setIndividualToDelete(individual);
                      }}
                      onOrderUpdate={updateOrder}
                      handleEditOpen={editIndividual}
                      renderRowContent={(
                        individual: AccreditationIndividual
                      ) => (
                        <div className="flex items-center space-x-2">
                          <div>
                            {individual.icon ? (
                              individual.icon
                            ) : (
                              <UserIcon className="h-6 w-6 text-gray-400 mx-1" />
                            )}
                          </div>
                          <div className="dark:text-white text-sm font-semibold min-w-48">
                            <div className="flex">
                              {individual.firstName} {individual.lastName}
                              <div className="flex ml-2">
                                {individual.isGroupContact && (
                                  <div className="has-tooltip">
                                    <span className="inline-flex items-center rounded-md bg-blue-400/10 px-1.5 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30 mr-1">
                                      <Shield className="h-3 w-3" />
                                    </span>
                                    <div className="tooltip rounded shadow-lg px-2 py-1 bg-dark-secondary mt-1 -ml-8 text-xs text-gray-400 border border-white/10 font-light">
                                      Group contact
                                    </div>
                                  </div>
                                )}
                                {individual.invitationSent && (
                                  <div className="has-tooltip">
                                    <span className="inline-flex items-center rounded-md bg-yellow-500/10 px-1.5 py-1 text-xs font-medium text-yellow-400 ring-1 ring-inset ring-yellow-500/20 mr-1.5">
                                      <Send className="h-3 w-3" />
                                    </span>
                                    <div className="tooltip rounded shadow-lg px-2 py-1 bg-dark-secondary mt-1 -ml-8 text-xs text-gray-400 border border-white/10 font-light">
                                      Invitation sent
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="font-light text-xs text-gray-400 underline decoration-dotted">
                              {/* <span className="inline-flex items-center rounded-md bg-green-500/10 px-1.5 py-0.5 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-500/20 mr-2">
                                <DollarSignIcon className="h-2.5 w-2.5 mr-1" />
                                Paid for
                              </span> */}
                              {individual.email}
                            </p>
                          </div>
                          <div className="">
                            {individual.tickets.map((ticket) => (
                              <span className="mr-1.5 mb-1 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                {ticket.allocation.ticket.name}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                      data={individualsData}
                      groupHeading={
                        <h1 className="dark:text-white font-medium text-sm">
                          Individuals
                        </h1>
                      }
                      onSearch={(search: string) =>
                        console.log("searching individuals")
                      }
                      hideOptionsButton={!!groupId}
                    />
                    <GroupList
                      name="Groups"
                      onRowClick={(id: number) =>
                        navigate(`/dashboard/accreditation/groups/${id}`)
                      }
                      handleDelete={(group: AccreditationGroup) => {
                        setGroupToDeleteDialogOpen(true);
                        setGroupToDelete(group);
                      }}
                      onOrderUpdate={updateOrder}
                      handleEditOpen={handleEditOpen}
                      renderRowContent={(group: AccreditationGroup) => (
                        <div className="flex items-center space-x-2">
                          <div>
                            {group.icon ? (
                              group.icon
                            ) : (
                              <BoxesIcon className="h-5 w-5 text-gray-400 mx-1" />
                            )}
                          </div>
                          <div className="dark:text-white text-sm font-semibold">
                            {group.name}
                          </div>
                          <div className="pl-3">
                            {/* here we want to show the hierarchialAllocations */}
                            {group.groupAndChildrenAllocatedTickets.map(
                              (ticketAllocation: TicketAllocation) => (
                                <span className="mr-1.5 mb-1 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                  {ticketAllocation.claimed}/
                                  {ticketAllocation.quantity}{" "}
                                  {ticketAllocation.ticket.name}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      )}
                      data={groupsData}
                      groupHeading={
                        <h1 className="dark:text-white font-medium text-sm">
                          Groups
                        </h1>
                      }
                      onSearch={(search: string) => setGroupSearch(search)}
                      hideOptionsButton={!!groupId}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        </>
      )}
    </DashboardLayout>
  );
};
