/*
  Input primary responsible for:
  - email
  - phone
  - address
  - website

  fallback on just a plain old input field
*/

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { SelectedField } from "../../../../models/form";
import { Field, FieldArray, getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
  hideLabels?: boolean;
  name?: string;
}

export const ContactField = ({
  name,
  inFormBuilder,
  field,
  hideLabels,
}: Props) => {
  const type = field.field.type;
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, name ? name : field.uuid) || "");
  }, [errors]);

  const getBorderColour = (fieldName?: string) => {
    if ((fieldName ? error[fieldName] : error) && !inFormBuilder) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value && !value.startsWith("https://")) {
      value = "https://" + value;
    }
    setFieldValue(field.uuid, value);
  };

  const handleNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "first" | "last"
  ) => {
    const value = e.target.value;
    const currentName = getIn(values, name ? name : field.uuid) || "";
    const [firstName, lastName] = currentName.split(" ");

    if (type === "first") {
      setFieldValue(
        name ? name : field.uuid,
        `${value} ${lastName || ""}`.trim()
      );
    } else {
      setFieldValue(
        name ? name : field.uuid,
        `${firstName || ""} ${value}`.trim()
      );
    }
  };

  return (
    <div className="">
      {/* todo: join the first and last name */}
      {type === "name" && (
        <div className="flex">
          <div className="w-1/2 pr-4">
            <div className="flex justify-between">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                First Name
              </label>
              {field.required && (
                <span
                  id="firstName"
                  className="text-sm leading-6 text-gray-500"
                >
                  Required
                </span>
              )}
            </div>

            <Field
              data-field-type={`${type}-first`}
              value={
                inFormBuilder
                  ? ""
                  : getIn(values, name ? name : field.uuid)?.split(" ")[0] || ""
              }
              name={`${field.uuid}-lastName`}
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(e, "first")
              }
              className={`${getBorderColour()} mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
            />
          </div>
          <div className="w-1/2 pr-4">
            <div className="flex justify-between">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Last Name
              </label>
              {field.required && (
                <span id="lastName" className="text-sm leading-6 text-gray-500">
                  Required
                </span>
              )}
            </div>
            <Field
              data-field-type={`${type}-last`}
              value={
                inFormBuilder
                  ? ""
                  : getIn(values, name ? name : field.uuid)?.split(" ")[1] || ""
              }
              name={`${field.uuid}-lastName`}
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(e, "last")
              }
              className={`${getBorderColour()} mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
            />
          </div>
        </div>
      )}
      {(type === "email" || type === "phoneNumber") && (
        <div>
          {!hideLabels && (
            <>
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {field.name ? field.name : "Untitled"}
                </label>
                {field.required && (
                  <span
                    id="email-optional"
                    className="text-sm leading-6 text-gray-500"
                  >
                    Required
                  </span>
                )}
              </div>

              {field.description && (
                <p className="mt-1 text-sm/6 text-gray-500">
                  {field.description}
                </p>
              )}
            </>
          )}
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {field.field.type === "email" ? (
                <EnvelopeIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              ) : (
                <PhoneIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              )}
            </div>
            <Field
              data-field-type={type}
              // value={inFormBuilder ? "" : field.value}
              id={field.uuid}
              name={name ? name : field.uuid}
              type={type === "email" ? "email" : "tel"}
              placeholder={
                type === "email" ? "something@mail.com" : "+353 87 1234567"
              }
              className={`block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white ${getBorderColour()}`}
            />
          </div>
        </div>
      )}

      {type === "website" && (
        <div>
          {!hideLabels && (
            <>
              <div className="flex justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {field.name ? field.name : "Untitled"}
                </label>
                {field.required && (
                  <span
                    id="email-optional"
                    className="text-sm leading-6 text-gray-500"
                  >
                    Required
                  </span>
                )}
              </div>
              {field.description && (
                <p className="mt-1 text-sm/6 text-gray-500">
                  {field.description}
                </p>
              )}
            </>
          )}
          <div className="mt-2">
            <div
              className={`${getBorderColour()} flex rounded-md bg-white shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 dark:bg-zinc-800`}
            >
              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm pr-2 dark:text-white">
                https://
              </span>
              <Field
                data-field-type={type}
                id={field.uuid}
                name={field.uuid}
                type="text"
                placeholder="www.example.com"
                onChange={handleWebsiteChange}
                className={`${getBorderColour()} block flex-1 bg-white ring-inset ring-1 dark:bg-zinc-800 py-1.5 pl-2.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 border-0 w-full rounded-r-md dark:text-white`}
              />
            </div>
          </div>
        </div>
      )}

      {type === "address" && (
        <>
          <div className="col-span-full">
            {!hideLabels && (
              <>
                <div className="flex justify-between mb-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {field.name ? field.name : "Untitled"}
                  </label>
                  {field.required && (
                    <span
                      id="email-optional"
                      className="text-sm leading-6 text-gray-500"
                    >
                      Required
                    </span>
                  )}
                </div>
                {field.description && (
                  <p className="my-1 text-sm/6 text-gray-500">
                    {field.description}
                  </p>
                )}
              </>
            )}
            <label
              htmlFor={`${field.uuid}.street`}
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Street
            </label>
            <div className="mt-2">
              <Field
                data-field-type="address-street"
                id={`${field.uuid}.street`}
                name={`${name ? name : field.uuid}.street`}
                type="text"
                autoComplete="street-address"
                className={`${getBorderColour(
                  "street"
                )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
              />
            </div>
            {error.street && (
              <div className="mt-2 text-xs text-red-500">{error.street}</div>
            )}
          </div>

          <div className="flex mt-3">
            <div className="w-1/3 pr-2">
              <label
                htmlFor={`${field.uuid}.city`}
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                City
              </label>
              <div className="mt-2">
                <Field
                  data-field-type="address-city"
                  id={`${field.uuid}.city`}
                  name={`${name ? name : field.uuid}.city`}
                  type="text"
                  autoComplete="address-level2"
                  className={`${getBorderColour(
                    "city"
                  )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                />
              </div>
              {error.city && (
                <div className="mt-2 text-xs text-red-500">{error.city}</div>
              )}
            </div>

            <div className="w-1/3 pr-2 pl-2">
              <label
                htmlFor={`${field.uuid}.postcode`}
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Post code
              </label>
              <div className="mt-2">
                <Field
                  data-field-type="address-postcode"
                  id={`${field.uuid}.postcode`}
                  name={`${name ? name : field.uuid}.postcode`}
                  type="text"
                  autoComplete="postal-code"
                  className={`${getBorderColour(
                    "postcode"
                  )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                />
              </div>
              {error.postcode && (
                <div className="mt-2 text-xs text-red-500">
                  {error.postcode}
                </div>
              )}
            </div>

            {/* todo: searchable dropdown with flags for country */}
            <div className="w-1/3 pl-2">
              <label
                htmlFor={`${field.uuid}.country`}
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Country
              </label>
              <div className="mt-2">
                <Field
                  data-field-type="address-country"
                  id={`${field.uuid}.country`}
                  name={`${name ? name : field.uuid}.country`}
                  type="text"
                  autoComplete="country"
                  className={`${getBorderColour(
                    "country"
                  )} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
                />
              </div>
              {error.country && (
                <div className="mt-2 text-xs text-red-500">{error.country}</div>
              )}
            </div>
          </div>
        </>
      )}
      {!inFormBuilder && (
        <>
          {error && type != "address" && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </>
      )}
    </div>
  );
};
