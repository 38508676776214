import { FORM_FIELDS } from "@/constants/form";
import { Organisation } from "@/models/Dashboard";
import { Form, FormResponse } from "@/models/form";

export const toFrontendFormat = (form: FormResponse): Form => {
  return {
    ...form,
    fields: form.fields.map((field: any) => {
      let category = null;
      let fieldData = null;

      for (const catKey in FORM_FIELDS) {
        const cat = FORM_FIELDS[catKey];
        const foundField = cat.fields.find((f) => f.type === field.fieldType);
        if (foundField) {
          category = cat;
          fieldData = foundField;
          break;
        }
      }
      return {
        id: field.id,
        uuid: field.uuid,
        name: field.name,
        description: field.description,
        helpText: field.helpText,
        required: field.required,
        category,
        field: fieldData,
        options: field.options
          ? field.options.map((option: any) => option.name)
          : [],
        content: field.content,
        // options: field.options.map((option) => option.name),
      };
    }),
  } as Form;
};

export const getFormUrl = (uuid: string, organisation: Organisation) => {
  if (import.meta.env.VITE_ENVIRONMENT === "local") {
    return `http://localhost:5173/forms/${uuid}`;
  }
  return `https://${organisation.domain}/forms/${uuid}`;
};
