import { Dropdown } from "@/crud/form/Dropdown";
import { BASE_URL } from "@/dashboardQueries";
import { EmailTemplateOption } from "@/models/accreditation";
import { DropdownOption } from "@/models/Dashboard";
import axios from "axios";
import { toCamelCase } from "js-convert-case";
import { useQuery } from "react-query";
import { Email } from "./Email";
import { Link } from "react-router-dom";

const emails = [
  {
    name: "Group Contact Email",
    description: "Email to send when a group contact is assigned/invited",
  },
  {
    name: "Ticket Email",
    description:
      "Email to send when a ticket is issued for an individual in a group.",
  },
  {
    name: "Ticket Deleted Email",
    description:
      "Email to send when a ticket is removed from an individual in a group.",
  },
];

const fetchEmailTemplates = async () => {
  const { data } = await axios.get(
    `${BASE_URL}/dashboard/email-templates/list`
  );
  return data;
};

export const GroupEmails = () => {
  const { data, error, isLoading, isError } = useQuery<
    Array<EmailTemplateOption>
  >("emailTemplates", fetchEmailTemplates);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const options: Array<DropdownOption> =
    data?.map(
      (template: EmailTemplateOption) =>
        ({
          id: template.id,
          value: `${template.name}${template.default ? " (Default)" : ""}`,
        } as DropdownOption)
    ) || [];

  return (
    <div>
      <dl className="space-y-6 divide-y divide-gray-100 dark:divide-white/10 text-sm/6">
        {emails.map((email, index) => (
          <Email index={index} email={email} options={options} />
        ))}
      </dl>

      <div className="mt-10">
        <h4 className="text-sm dark:text-gray-400">
          <Link to="/dashboard/accreditation/emails" className="underline">
            Click here to modify and create email templates
          </Link>
        </h4>
      </div>
    </div>
  );
};
