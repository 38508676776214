import { create } from "zustand";

interface PortalStore {
  manageAccreditationGroupCellOpen: string;
  setManageAccreditationGroupCellOpen: (open: string) => void;
}

export const usePortalStore = create<PortalStore>((set) => ({
  manageAccreditationGroupCellOpen: "",
  setManageAccreditationGroupCellOpen: (open: string) =>
    set({ manageAccreditationGroupCellOpen: open }),
}));
