import { Dropdown } from "@/crud/form/Dropdown";
import { Switch } from "@/crud/form/Switch";
import { BASE_URL } from "@/dashboardQueries";
import { AccreditationGroup, TicketAllocation } from "@/models/accreditation";
import { useAccreditationStore } from "@/store/accreditation";
import { getBaseEventUrl } from "@/utils/url";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { Formik, FormikValues } from "formik";
import { Copy, FilesIcon, MailIcon, TicketsIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { classNames } from "@/utils/styles";
import { GroupEmails } from "./GroupEmails";
import { Documents } from "./Documents";

interface Props {
  group: AccreditationGroup;
}

const navigation = [
  { name: "Allocation", href: "#", icon: TicketsIcon, current: true },
  { name: "Emails", href: "#", icon: MailIcon, current: false },
  { name: "Documents", href: "", icon: FilesIcon, current: false },
];

export const GroupSettings = ({ group }: Props) => {
  const { groupSettingsOpen, setGroupSettingsOpen } = useAccreditationStore();
  const [copyButtonText, setCopyButtonText] = useState<string | null>(null);
  const isSubmitting = useRef(false);
  const [activeTab, setActiveTab] = useState("Allocation");

  const updateGroupSettings = async (payload: any) => {
    try {
      await axios.patch(
        `${BASE_URL}/accreditation/groups/${group.id}`,
        payload
      );
      //   // Invalidate and refetch the groups query
      //   queryClient.invalidateQueries(["groups"]);
      //   queryClient.invalidateQueries(["group", groupId]);
    } catch (error) {}
  };

  const autoAssignLink = `${getBaseEventUrl()}/${group.uuid}/tickets/claim`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(autoAssignLink).then(() => {
      setCopyButtonText("Copied! 🎉");
      setTimeout(() => {
        setCopyButtonText(null);
      }, 2000);
    });
  };

  return (
    <>
      <Transition.Root show={groupSettingsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          // initialFocus={cancelButtonRef}
          onClose={setGroupSettingsOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-black/80 backdrop-blur-lg bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="h-full min-h-96 bg-purple-500 relative transform rounded-lg bg-white dark:bg-zinc-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                  <main className="min-h-full">
                    <div className="flex min-h-full">
                      <div className="w-1/4 dark:bg-zinc-800 p-6 rounded-l-lg min-h-96">
                        <div className="">
                          <h4 className="dark:text-gray-400 font-semibold text-sm">
                            Settings
                          </h4>
                        </div>
                        <nav
                          aria-label="Sidebar"
                          className="flex flex-1 flex-col mt-3 min-h-full"
                        >
                          <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                          >
                            <li>
                              <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                  <li key={item.name}>
                                    <button
                                      type="button"
                                      onClick={() => setActiveTab(item.name)}
                                      className={classNames(
                                        activeTab == item.name
                                          ? "bg-gray-50 text-indigo-600 dark:bg-dark-secondary dark:text-gray-400"
                                          : "text-gray-700 dark:text-gray-400 hover:bg-gray-50 hover:text-indigo-600 dark:hover:bg-dark-secondary dark:hover:text-gray-400 ",
                                        "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold w-full"
                                      )}
                                    >
                                      <item.icon
                                        aria-hidden="true"
                                        className={classNames(
                                          activeTab == item.name
                                            ? "text-indigo-600 dark:text-gray-400"
                                            : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-gray-400",
                                          "size-6 shrink-0"
                                        )}
                                      />
                                      {item.name}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </li>
                            {/* <li>
                              <div className="text-xs/6 font-semibold text-gray-400">
                                Projects
                              </div>
                              <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {secondaryNavigation.map((item) => (
                                  <li key={item.name}>
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        item.current
                                          ? "bg-gray-50 text-indigo-600"
                                          : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                        "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          item.current
                                            ? "border-indigo-600 text-indigo-600"
                                            : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                          "flex size-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                                        )}
                                      >
                                        {item.initial}
                                      </span>
                                      <span className="truncate">
                                        {item.name}
                                      </span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </li> */}
                          </ul>
                        </nav>
                      </div>
                      <div className="w-3/4 pl-10 mt- p-6">
                        <Formik
                          initialValues={{
                            individualsCanAutoAllocate:
                              !!group.autoAssignTicket,
                            autoAssignTicket: group.autoAssignTicket,
                            requireGroupContactsToCompleteForm:
                              !!group.requireFormCompletion,
                            requireFormCompletion: group.requireFormCompletion,
                            formRequiresManualApproval:
                              group.formRequiresManualApproval,
                            chargeIndividualForTicket:
                              group.chargeIndividualForTicket,
                            isRefundableBond: group.isRefundableBond,
                            groupContactEmail: group.groupContactEmail,
                            ticketEmail: group.ticketEmail,
                            ticketDeletedEmail: group.ticketDeletedEmail,
                            canUploadDocuments: group.canUploadDocuments,
                          }}
                          onSubmit={(values: FormikValues) => {
                            isSubmitting.current = false; // Reset the submitting flag
                            updateGroupSettings({
                              autoAssignTicket:
                                values.individualsCanAutoAllocate
                                  ? values.autoAssignTicket.id
                                  : null,
                              requireFormCompletion:
                                values.requireGroupContactsToCompleteForm
                                  ? values.requireFormCompletion.id
                                  : null,
                              formRequiresManualApproval:
                                values.formRequiresManualApproval,
                              chargeIndividualForTicket:
                                values.chargeIndividualForTicket,
                              isRefundableBond: values.isRefundableBond,
                              groupContactEmail: values.groupContactEmail
                                ? values.groupContactEmail.id
                                : null,
                              ticketEmail: values.ticketEmail
                                ? values.ticketEmail.id
                                : null,
                              ticketDeletedEmail: values.ticketDeletedEmail
                                ? values.ticketDeletedEmail.id
                                : null,
                              canUploadDocuments: values.canUploadDocuments,
                            });
                          }}
                        >
                          {({ values, handleSubmit }) => {
                            // When the values change, submit
                            useEffect(() => {
                              if (!isSubmitting.current) {
                                isSubmitting.current = true;
                                handleSubmit();
                              }
                            }, [values]);

                            return (
                              <form>
                                {/* Settings forms */}
                                <div className="divide-y divide-white/5">
                                  <div className="">
                                    <div className="">
                                      {activeTab == "Allocation" && (
                                        <dl className="space-y-6 divide-y divide-gray-100 dark:divide-white/10 text-sm/6">
                                          <div className="pt-1">
                                            <div className="flex">
                                              <dt className="flex-1 pr-6 font-medium text-gray-900 sm:w-64 dark:text-white">
                                                Allow individuals to
                                                auto-allocate themselves
                                                <div className="text-xs font-normal text-gray-400 ">
                                                  By default, only managers can
                                                  allocate tickets to their
                                                  group.
                                                </div>
                                              </dt>
                                              <dd className="flex items-center justify-end">
                                                <Switch name="individualsCanAutoAllocate" />
                                              </dd>
                                            </div>
                                            {values.individualsCanAutoAllocate && (
                                              <div className="mt-5 pl-4">
                                                <h3 className="dark:text-white font-medium mb-2 text-sm">
                                                  Which ticket would you like to
                                                  auto-assign?
                                                </h3>

                                                {group.groupAllocatedTickets
                                                  .length > 0 ? (
                                                  <div>
                                                    <div className="w-72">
                                                      <Dropdown
                                                        name="autoAssignTicket"
                                                        options={group.groupAllocatedTickets.map(
                                                          (
                                                            ticketAllocation: TicketAllocation
                                                          ) => {
                                                            return {
                                                              id: ticketAllocation
                                                                .ticket.id,
                                                              value: `x${ticketAllocation.quantity} ${ticketAllocation.ticket.name}`,
                                                            };
                                                          }
                                                        )}
                                                      />
                                                    </div>
                                                    <div className="mt-6">
                                                      <div>
                                                        <h3 className="dark:text-white font-medium mb-2 text-sm">
                                                          Individuals can
                                                          auto-allocate using
                                                          the link below
                                                        </h3>
                                                        <div className="flex items-center flex-1">
                                                          <div className="flex rounded-md shadow-sm flex-1">
                                                            <input
                                                              id="autoAssignLink"
                                                              name="autoAssignLink"
                                                              type="text"
                                                              value={
                                                                autoAssignLink
                                                              }
                                                              disabled
                                                              placeholder="www.example.com"
                                                              className="block w-full min-w-0 flex-1 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-200 dark:ring-white/5 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-dark-secondary bg-gray-50 dark:text-gray-400 text-gray-900"
                                                            />
                                                          </div>
                                                          <div className="ml-3">
                                                            <button
                                                              type="button"
                                                              onClick={
                                                                handleCopyLink
                                                              }
                                                              className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 flex items-center"
                                                            >
                                                              {copyButtonText ? (
                                                                copyButtonText
                                                              ) : (
                                                                <Copy className="h-5 w-5" />
                                                              )}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="flex mt-6">
                                                      <dt className="flex-1 font-medium text-gray-900 sm:w-64 dark:text-white">
                                                        Charge individual for
                                                        ticket
                                                        <div className="text-xs font-normal text-gray-400 ">
                                                          By default,
                                                          individuals can claim
                                                          tickets without
                                                          charge.
                                                        </div>
                                                      </dt>
                                                      <dd className="flex items-center justify-end">
                                                        <Switch name="chargeIndividualForTicket" />
                                                      </dd>
                                                    </div>
                                                    {values.chargeIndividualForTicket && (
                                                      <div className="mt-6 flex pl-4 w-full">
                                                        <div className="flex w-full">
                                                          <dt className="flex-1 font-medium text-gray-900 sm:w-64 dark:text-white">
                                                            Refundable bond
                                                            <div className="text-xs font-normal text-gray-400 ">
                                                              If enabled, we
                                                              will communicate
                                                              the payment as a
                                                              refundable bond
                                                              and allow you to
                                                              approve/decline
                                                              the refund after
                                                              the bond has been
                                                              paid
                                                            </div>
                                                          </dt>
                                                          <dd className="flex items-center justify-end ml-4">
                                                            <Switch name="isRefundableBond" />
                                                          </dd>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <span className="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20">
                                                    <ExclamationTriangleIcon
                                                      aria-hidden="true"
                                                      className="size-4 text-yellow-400 mr-1.5"
                                                    />
                                                    This group has no tickets
                                                    allocated to it yet.
                                                    Allocated some first to
                                                    allow users to auto-assign
                                                    tickets.
                                                  </span>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          {/* <div className="pt-4">
                                            <div className="flex">
                                              <dt className="flex-1 pr-6 font-medium text-gray-900 sm:w-64 dark:text-white">
                                                Require group contacts to
                                                complete form
                                                <div className="text-xs font-normal text-gray-400 ">
                                                  By default group contacts can
                                                  allocate tickets to their
                                                  group without completing a
                                                  form.
                                                </div>
                                              </dt>
                                              <dd className="flex items-center justify-end">
                                                <Switch name="requireGroupContactsToCompleteForm" />
                                              </dd>
                                            </div>

                                            {values.requireGroupContactsToCompleteForm && (
                                              <div className="mt-5 pl-4">
                                                <h3 className="dark:text-white font-medium mb-2 text-sm">
                                                  Which form would you like
                                                  group contacts to complete?
                                                </h3>
                                                <div className="w-72">
                                                  <Dropdown
                                                    name="requireFormCompletion"
                                                    options={[
                                                      {
                                                        id: 1,
                                                        value: "Form 1",
                                                      },
                                                    ]}
                                                  />
                                                </div>
                                                <div className="text-xs font-normal text-gray-400 mt-2">
                                                  When invited, group contacts
                                                  will be required to complete
                                                  the above form. Once they've
                                                  completed it they will be able
                                                  to allocate tickets to their
                                                  group.
                                                </div>

                                                <div className="flex mt-6">
                                                  <dt className="flex-1 pr-6 font-medium text-gray-900 sm:w-64 dark:text-white">
                                                    Require manual approval of
                                                    form submission
                                                    <div className="text-xs font-normal text-gray-400 ">
                                                      If you require manual
                                                      approval, the group
                                                      contact will not be able
                                                      to allocate tickets until
                                                      the form has been
                                                      approved.
                                                    </div>
                                                  </dt>
                                                  <dd className="flex items-center justify-end">
                                                    <Switch name="formRequiresManualApproval" />
                                                  </dd>
                                                </div>
                                              </div>
                                            )}
                                          </div> */}
                                        </dl>
                                      )}

                                      {activeTab == "Emails" && <GroupEmails />}

                                      {activeTab == "Documents" && (
                                        <Documents />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </main>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
