import { BASE_URL } from "@/dashboardQueries";
import { PaginatedResponse } from "@/models/Dashboard";
import { Form } from "@/models/form";
import { useFlowStore } from "@/store/flow";
import { getActionBasedOnType } from "@/utils/flows";
import axios from "axios";
import { useMemo } from "react";
import { useQuery } from "react-query";

export const ActionSettings = () => {
  const { activeNode } = useFlowStore();

  const action = useMemo(
    () => getActionBasedOnType(activeNode?.data.type),
    [activeNode?.id]
  );

  return (
    <div className="px-2 py-3">
      {activeNode && action ? (
        action.component
      ) : (
        <>Nothing selected, flow details</>
      )}
    </div>
  );
};
