import {
  Form,
  SelectedContentField,
  SelectedField,
  SelectedFormField,
} from "../models/form";
import { create } from "zustand";

interface FormStore {
  selectedFields: Array<SelectedField>;
  setSelectedFields: (fields: Array<SelectedField>) => void;
  selectFieldModalOpen: boolean;
  setSelectFieldModalOpen: (open: boolean) => void;
  activeField: SelectedFormField | SelectedContentField | null;
  setActiveField: (
    field: SelectedFormField | SelectedContentField | null
  ) => void;
  formSettingsOpen: boolean;
  setFormSettingsOpen: (open: boolean) => void;
  formPublishedModalOpen: boolean;
  setFormPublishedModalOpen: (open: boolean) => void;
  form: Form | null;
  setForm: (form: Form | null) => void;
}

export const useFormStore = create<FormStore>((set) => ({
  selectedFields: [],
  setSelectedFields: (fields) => set({ selectedFields: fields }),
  selectFieldModalOpen: false,
  setSelectFieldModalOpen: (open) => set({ selectFieldModalOpen: open }),
  activeField: null,
  setActiveField: (field) => set({ activeField: field }),
  formSettingsOpen: false,
  setFormSettingsOpen: (open) => set({ formSettingsOpen: open }),
  formPublishedModalOpen: false,
  setFormPublishedModalOpen: (open) => set({ formPublishedModalOpen: open }),
  form: null,
  setForm: (form) => set({ form: form }),
}));
