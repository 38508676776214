import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getIn, useFormikContext } from "formik";

interface Props {
  name: string;
}

export const Quill = ({ name }: Props) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const fieldError = getIn(errors, name);

  const borderColour = fieldError
    ? "border-red-500"
    : "border-gray-300 dark:border-white/5";

  return (
    <>
      <ReactQuill
        className={`w-full bg-white dark:bg-dark-secondary dark:text-gray-400 border rounded-md ${borderColour}`}
        theme="snow"
        value={getIn(values, name)}
        onChange={(value: string) => setFieldValue(name, value)}
      />
      {fieldError && <p className="text-red-500 text-xs mt-2">{fieldError}</p>}
    </>
  );
};
