import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { BASE_URL } from "@/dashboardQueries";
import { AccreditationIndividual as AccreditationIndividualModel } from "@/models/accreditation";
import { ChevronRightIcon, QrCode, Send, Shield } from "lucide-react";
import { CreateIndividualDialog } from "@/components/dashboard/accreditation/create/CreateIndividualDialog";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  PencilIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useAccreditationStore } from "@/store/accreditation";
import { resendInvitation } from "./groups";
import toast from "react-hot-toast";
import { invalidateAccreditationGroups } from "@/utils/accreditation";
import { useState } from "react";
import { Modal } from "@/components/dashboard/Modal";
import QRCode from "react-qr-code";
import { useDarkMode } from "usehooks-ts";

const fetchIndividual = async (
  id: string
): Promise<AccreditationIndividualModel> => {
  const { data } = await axios.get(
    `${BASE_URL}/accreditation/individuals/${id}`
  );
  return data;
};

export const AccreditationIndividual = () => {
  const navigate = useNavigate();
  const isDarkMode = useDarkMode();
  const queryClient = useQueryClient();
  const { individualId } = useParams<{ individualId: string }>();
  const { setIndividual, setCreateIndividualOpen } = useAccreditationStore();
  const [ticket, setTicket] = useState<any | null>(null);
  const [qrCodeModalOpen, setQRCodeModalOpen] = useState(false);

  // Deleting individual
  const handleIndividualDelete = async (
    individual: AccreditationIndividualModel
  ) => {
    try {
      await axios.delete(
        `${BASE_URL}/accreditation/individuals/${individual.id}`
      );
      console.log("Individual deleted successfully");
      navigate(`/dashboard/accreditation/groups/${individual.group.id}`);
      invalidateAccreditationGroups(
        queryClient,
        individual.group.id ? parseInt(individual.group.id) : 0
      );
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  const { data, error, isLoading } = useQuery(
    ["individual", individualId],
    () => fetchIndividual(individualId),
    {
      enabled: !!individualId,
    }
  );

  if (isLoading) {
    return (
      <DashboardLayout requiredPermissions={[]} pageTitle="Loading...">
        Loading...
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout requiredPermissions={[]} pageTitle="Error">
        Error loading individual
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      requiredPermissions={[]}
      pageTitle="Accreditation Individual"
    >
      {/* Create/Edit Individual Dialog */}
      {data && <CreateIndividualDialog group={data.group} />}

      <Modal isOpen={qrCodeModalOpen} setIsOpen={setQRCodeModalOpen}>
        {ticket && (
          <>
            <div className="flex items-center justify-center">
              <QRCode
                value={ticket.uuid}
                bgColor={isDarkMode ? "#111827" : "white"}
                fgColor={isDarkMode ? "white" : "black"}
                className="w-36 h-36"
              />
            </div>
            <div className="text-white mt-4 text-center font-semibold">
              {ticket.allocation.ticket.name}
            </div>
            <div className="text-gray-400 text-sm mt-1">#{ticket.uuid}</div>
          </>
        )}
      </Modal>

      <div className="px-5 py-5 border-b dark:border-white/5">
        <div className="ml-2">
          <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div className="flex items-center">
                  <Link
                    to={`/dashboard/accreditation/groups`}
                    className="text-sm font-medium dark:text-gray-300 hover:text-white text-gray-500"
                  >
                    Groups
                  </Link>
                </div>
              </li>
              {data?.group?.hierarchy.map((item, index) => (
                <li key={index}>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      aria-hidden="true"
                      className="size-5 shrink-0 text-gray-500"
                    />
                    <Link
                      to={`/dashboard/accreditation/groups/${item.id}`}
                      className="ml-4 text-sm font-medium dark:text-gray-300 text-gray-500"
                    >
                      {item.name}
                    </Link>
                  </div>
                </li>
              ))}
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="size-5 shrink-0 text-gray-500"
                  />
                  <Link
                    to={`/dashboard/accreditation/individuals/${data.id}`}
                    className="ml-4 text-sm font-medium text-gray-800 dark:text-white"
                  >
                    {data.firstName} {data.lastName}{" "}
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex">
          <div>
            <div className="relative flex items-center mt-4">
              <div
                className="flex items-center text-2xl/7 font-bold dark:text-white sm:truncate sm:text-3xl sm:tracking-tight cursor-pointer"
                //   onClick={handleNameClick}
              >
                <h2 className="ml-1.5">
                  {data.firstName} {data.lastName}
                </h2>
                <h4 className="ml-3 mt-1 text-xs inline-flex items-center rounded-md bg-blue-400/10 px-1.5 py-0.5 font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30 mr-1.5">
                  <Shield className="h-3 w-3 mr-1" />
                  Group contact
                </h4>
              </div>
            </div>
            <div className="ml-1.5 mt-1">
              <p className="underline decoration-dotted dark:text-white/80 text-gray-500 text-sm">
                {data.email}
              </p>
            </div>
          </div>
          {data && (
            <div className="flex-1 justify-end items-center flex">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="dark:text-white dark:ring-white/10 inline-flex w-full justify-center gap-x-1.5 rounded-md dark:bg-zinc-800 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:hover:bg-zinc-800 hover:bg-gray-100">
                    Options
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="-mr-1 size-5 text-gray-400"
                    />
                  </Menu.Button>
                </div>

                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-white/10 dark:bg-zinc-800 dark:ring-white/10 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  <div className="py-1">
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => {
                          setIndividual(data);
                          setCreateIndividualOpen(true);
                        }}
                        className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-500 hover:text-zinc-800"
                      >
                        <PencilSquareIcon
                          aria-hidden="true"
                          className="mr-3 size-5 group-data-[focus]:text-gray-500"
                        />
                        Edit individual
                      </button>
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => handleIndividualDelete(data)}
                        className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-500 hover:text-zinc-800"
                      >
                        <TrashIcon
                          aria-hidden="true"
                          className="mr-3 size-5 group-data-[focus]:text-gray-500"
                        />
                        Delete individual
                      </button>
                    </Menu.Item>
                  </div>
                  {data.isGroupContact && (
                    <div className="py-1">
                      <Menu.Item>
                        <button
                          type="button"
                          onClick={() => {
                            resendInvitation(data.id);
                          }}
                          className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-500 hover:text-zinc-800"
                        >
                          <Send
                            aria-hidden="true"
                            className="mr-3 size-5 group-data-[focus]:text-gray-500"
                          />
                          Resend invitation
                        </button>
                      </Menu.Item>
                    </div>
                  )}
                </Menu.Items>
              </Menu>
            </div>
          )}
        </div>
      </div>
      <div>
        {data && (
          <div className="flex h-full">
            <div className="w-1/4 border-l dark:border-white/5 p-5 order-1">
              <div>
                <div
                  data-sidebar="group-label"
                  className="-mt-1 dark:text-gray-500 flex h-8 shrink-0 items-center rounded-md text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                >
                  Tickets
                </div>
                {data.tickets.map((ticket, index) => (
                  <div key={index} className="mb-2">
                    <div className="flex">
                      <span className="mr-1.5 mb-1 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                        {ticket.allocation.ticket.name}
                      </span>
                      <div className="flex flex-1 justify-end">
                        <button
                          type="button"
                          onClick={() => {
                            setTicket(ticket);
                            setQRCodeModalOpen(true);
                          }}
                          className="flex text-white/50 text-xs items-center underline mr-3"
                        >
                          <QrCode className="h-3 w-3 mr-1" />
                          View
                        </button>
                        {/* <button
                          type="button"
                          className="flex text-white/50 text-xs items-center underline"
                        >
                          <Send className="h-3 w-3 mr-1" />
                          Email
                        </button> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-3/4">
              <div className="border-white/10 px-7 mt-1 min-h-screen">
                <dl className="divide-y divide-white/10">
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm/6 font-medium dark:text-white">
                      Name
                    </dt>
                    <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">
                      {data.firstName} {data.lastName}
                    </dd>
                  </div>
                  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm/6 font-medium dark:text-white">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">
                      {data.email}
                    </dd>
                  </div>
                  {data.phoneNumber && (
                    <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm/6 font-medium dark:text-white">
                        Phone number
                      </dt>
                      <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">
                        {data.phoneNumber}
                      </dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};
