import { ACTIONS, STARTER_ACTIONS } from "@/constants/flows";
import { Action } from "@/models/flows";
import { useFlowStore } from "@/store/flow";
import { Send } from "lucide-react";

interface ActionSelectorProps {
  category: string;
  type: "starter" | "regular";
  action: Action;
}

const ActionSelector = ({ category, type, action }: ActionSelectorProps) => {
  const { nodes, setNodes, setActiveNode } = useFlowStore();

  const addNode = (label: string) => {
    const node = {
      id: (nodes.length + 1).toString(),
      position: { x: Math.random() * 250, y: Math.random() * 250 },
      data: { label, type: action.type, category },
      type,
    };
    setActiveNode(node);
    setNodes([...nodes, node]);
  };

  return (
    <div
      onClick={() => addNode(action.label)}
      className={`px-3 group-hover:bg-${action.colour}-400/20 border-white/5 border-b flex py-2 cursor-pointer group hover:border-${action.colour}-600`}
    >
      <div className={`p-1.5 bg-${action.colour}-400 rounded-lg`}>
        <action.icon className="w-5 h-5 text-white" />
      </div>
      <div className="ml-2 flex justify-center">
        <div className="flex items-center">
          <div className="">
            <h3 className={`uppercase text-[0.5rem] text-${action.colour}-400`}>
              {category}
            </h3>
            <h1 className={`text-[0.8rem] text-white/70 leading-tight`}>
              {action.label}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActionPicker = () => {
  const { nodes } = useFlowStore();

  return (
    <div className="">
      <div>
        <div className="-mt-6">
          {nodes.length > 0 ? (
            Object.keys(ACTIONS).map((category, index) => (
              <div className={index > 0 ? "mt-2" : "mt-6"}>
                <h4
                  data-sidebar="group-label"
                  className="px-3.5 pb-2 border-b border-white/5 text-xs text-stone-400 flex h-8 shrink-0 items-center text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                >
                  {category}
                </h4>
                {ACTIONS[category].map((action: Action) => (
                  <ActionSelector
                    category={category}
                    type="regular"
                    action={action}
                  />
                ))}
              </div>
            ))
          ) : (
            <div className="mt-6">
              <h4
                data-sidebar="group-label"
                className="px-3.5 pb-2 border-b border-white/5 text-xs text-stone-400 flex h-8 shrink-0 items-center text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
              >
                Choose a starter action
              </h4>
              {STARTER_ACTIONS.map((action: Action) => (
                <ActionSelector
                  category="General"
                  type="starter"
                  action={action}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
