import { PortalLayout } from "@/components/portal/layouts/PortalLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios, { AxiosResponse } from "axios";
import { FormFieldRequest, FormResponse } from "@/models/form";
import { getForm } from "@/queries";
import { FormRenderer } from "@/components/dashboard/forms/FormRenderer";
import { Formik, FormikValues } from "formik";
import yup from "../crud/yup-extended";
import { toFrontendFormat } from "@/utils/forms";
import { BASE_URL } from "@/dashboardQueries";
import { useEffect, useState, useRef } from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { Loading } from "@/components/portal/Loading";
import { useDarkMode } from "@/hooks";
import Lottie from "lottie-react";
import successAnimation from "../assets/successAnimation.json";

/*
todo: 
- make sure form validation works
- handle form submissions
- show form responses on dashboard
- clone a form field
- delete a form field
- hide field based on input of other field

*/

export const createFormYupSchema = (fields: Array<FormFieldRequest>) => {
  const shape = {};
  fields.forEach((field) => {
    console.log(field);
    var schema = undefined;
    const numberFields = ["moneyField", "decimal", "number"];
    if (numberFields.includes(field.fieldType)) {
      schema = yup.number().typeError("This field must be a valid number"); // Custom error message for invalid numbers
    } else if (field.fieldType === "address") {
      schema = yup.object().shape({
        street: field.required
          ? yup.string().required("Street is required")
          : yup.string(),
        city: field.required
          ? yup.string().required("City is required")
          : yup.string(),
        postcode: field.required
          ? yup.string().required("Postcode is required")
          : yup.string(),
        country: field.required
          ? yup.string().required("Country is required")
          : yup.string(),
      });
    } else if (field.fieldType === "time") {
      schema = yup
        .string()
        .matches(
          /^([01]\d|2[0-3]):([0-5]\d)$/,
          "This field must be a valid 24-hour time in the format HH:MM"
        );
    } else if (field.fieldType === "multipleChoice") {
      schema = yup.array().of(
        yup.string().oneOf(
          field.options.map((option) => option.name),
          "Invalid choice"
        )
      );
    } else if (field.fieldType === "dropdown") {
      schema = yup.string().oneOf(
        field.options.map((option) => option.name),
        "Invalid choice"
      );
    } else if (field.fieldType === "website") {
      schema = yup.string().url("Invalid URL");
    } else if (field.fieldType === "legal") {
      schema = yup.string().oneOf(["i_accept"], "You must agree");
    } else {
      schema = yup.string();
    }

    // if it's required and not a fileUpload
    if (field.required && field.fieldType !== "fileUpload") {
      schema = schema.required("This field is required");
    }

    // if it is required and is a fileUpload
    if (field.fieldType === "fileUpload") {
      if (field.required) {
        schema = yup
          .array()
          .min(1, "You must upload at least one file")
          .required("This field is required");
      } else {
        schema = yup.array();
      }
    }

    if (field.fieldType === "email") {
      schema = schema.email("Invalid email address");
    }

    // number fields
    // address field
    shape[field.uuid] = schema;
  });
  return yup.object().shape(shape);
};

export const Form = () => {
  const navigate = useNavigate();
  const { formId } = useParams<{ formId: string }>();
  const queryClient = useQueryClient();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState<Record<
    string,
    Array<string>
  > | null>(null);
  const isDarkMode = useDarkMode();
  const topRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);

  const { data, error, isLoading } = useQuery<
    AxiosResponse<FormResponse>,
    Error
  >(
    ["form", formId],
    () => {
      if (formId) {
        return getForm(formId);
      }
      return Promise.reject(new Error("formId is undefined"));
    },
    {
      enabled: !!formId, // Only run the query if formId is available
    }
  );

  const mutation = useMutation(
    (formData: FormData | Record<string, any>) =>
      axios.post(`${BASE_URL}/forms/${formId}`, formData, {
        headers:
          formData instanceof FormData
            ? { "Content-Type": "multipart/form-data" }
            : { "Content-Type": "application/json" },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formResponses", formId]);
        setSubmissionSuccess(true);
        setSubmissionError(null);
      },
      onError: (error: any) => {
        setSubmissionError(error.response.data);
        if (topRef.current) {
          topRef.current.scrollIntoView({ behavior: "smooth" });
          topRef.current.focus();
        }
      },
      onSettled: () => {
        if (topRef.current) {
          topRef.current.scrollIntoView({ behavior: "smooth" });
          topRef.current.focus();
        }
      },
    }
  );

  if (!formId) {
    navigate("/");
    return null;
  }

  if (isLoading) {
    return (
      <PortalLayout>
        <div className="min-h-screen w-full text-white items-center justify-center flex">
          <div className="-mt-64">
            <Loading colour="white" />
          </div>
        </div>
      </PortalLayout>
    );
  }

  if (error) {
    return (
      <PortalLayout>
        <div className="min-h-screen text-white">Error: {error.message}</div>
      </PortalLayout>
    );
  }

  const toClientFormat = (form: FormResponse) => {
    return {
      id: form.id,
      name: form.name,
      fields: form.fields.map((field) => {
        return {
          [form.uuid]: "",
        };
      }),
    };
  };

  const handleSubmit = (values: FormikValues) => {
    debugger;
    // Function to filter and extract UUID keys with their values
    const isUUID = (key: string) => /^[0-9a-fA-F\-]{36}$/.test(key);

    const result = Object.entries(values)
      .filter(([key]) => isUUID(key)) // Include UUID keys and `id`
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, any>);

    const hasFileUpload = data?.data.fields.some(
      (field) => field.fieldType === "fileUpload"
    );

    console.log("submit it");
    console.log(result);

    if (hasFileUpload) {
      const formData = new FormData();
      Object.entries(result).forEach(([key, value]) => {
        if (Array.isArray(value) || typeof value === "object") {
          // It's an array, check if the array is longer than 0 and the first element is a file
          if (value.length > 0 && value[0] instanceof File) {
            // add each file
            Array.from(value).forEach((file) => {
              if (file instanceof File) {
                formData.append(key, file);
              }
            });
          } else {
            // Serialize complex types
            formData.append(key, JSON.stringify(value));
          }
        } else {
          formData.append(key, value);
        }
      });

      console.log("is form data");
      for (var pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      mutation.mutate(formData);
    } else {
      mutation.mutate(result);
    }
  };

  /*

  So on the backend the initial values and validator is for actually creating the form, for
  here it's different, the values need to just be [form.uuid]: "". The actual fields need
  to come from somewhere else
  */

  return (
    <PortalLayout>
      <div className="min-h-screen flex text-white font-sans" ref={topRef}>
        {data?.data.branding && (
          <div className="w-1/2 hidden lg:block h-screen">
            <img
              src={
                isDarkMode
                  ? data?.data.branding.backgroundImageDark
                  : data?.data.branding.backgroundImageLight
              }
              className="object-cover w-full h-full"
            />
          </div>
        )}
        <div
          className={`w-full overflow-y-scroll h-screen ${
            data?.data.branding ? "lg:w-1/2" : ""
          }`}
        >
          <div className="sm:mx-auto sm:w-full sm:max-w-xl p-4">
            {data?.data.branding && (
              <div className="mt-6 lg:mt-24 flex items-center">
                <img
                  src={
                    isDarkMode
                      ? data?.data.branding.logoDark
                      : data?.data.branding.logoLight
                  }
                  className="h-14"
                />
                {/* <div className="border-l dark:border-white/10 border-gray-300 h-8 lg:ml-9 lg:mr-6 ml-6 mr-4"></div>
              <img
                src={
                  isDarkMode
                    ? "https://ticketr.lon1.cdn.digitaloceanspaces.com/staging/forms/assets/Clan_Logo%20Lockup_White@2x.png"
                    : "https://ticketr.lon1.cdn.digitaloceanspaces.com/staging/forms/assets/Clan_Logo%20Lockup_Black@2x.png"
                }
                className="h-14"
              /> */}
              </div>
            )}
            {!submissionSuccess && (
              <div className="mt-10 mb-2">
                <h1 className="text-2xl dark:text-white text-gray-800 font-medium">
                  {data?.data.name}
                </h1>
              </div>
            )}
            <div className="mt-2">
              {submissionError &&
                Object.keys(submissionError).map((key) => (
                  <div
                    ref={errorRef}
                    tabIndex={-1}
                    className="mt-3 rounded-md bg-red-400/10 text-red-400 ring-1 ring-inset ring-red-400/20 p-4"
                  >
                    <div className="flex">
                      <div className="shrink-0">
                        <ExclamationCircleIcon
                          aria-hidden="true"
                          className="size-5 text-red-400"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-normal text-red-800">
                          There were {submissionError[key].length} errors with
                          the <span className="font-bold">{key}</span> field
                        </h3>
                        <div className="mt-2 text-sm text-red-700">
                          <ul role="list" className="list-disc space-y-1 pl-5">
                            {submissionError[key].map((error) => (
                              <li>{error}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {submissionSuccess ? (
                <div className="flex items-center justify-center text-green-500 mt-40 lg:mt-64">
                  <div>
                    <div className="w-full flex justify-center">
                      <div className="w-36 h-36">
                        <Lottie animationData={successAnimation} loop={false} />
                      </div>
                    </div>
                    <div className="text-center">
                      <h3 className="mt-4 font-semibold text-xl">
                        {data?.data.name} form Submitted
                      </h3>
                      <p className="text-gray-400 text-sm mt-1.5">
                        Thank you for submitting the form. We will be in touch!
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="-m-4">
                  <Formik
                    initialValues={toClientFormat(data.data)}
                    validationSchema={createFormYupSchema(
                      data?.data.fields as Array<FormFieldRequest>
                    )}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({ handleSubmit, errors, values }) => {
                      // If the fomr values change, then clear the submission error message if it exists
                      useEffect(() => {
                        if (submissionError) {
                          setSubmissionError(null);
                        }
                      }, [values]);

                      return (
                        <form>
                          <FormRenderer
                            formMetaInformation={toFrontendFormat(data.data)}
                          />
                          <button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="rounded-md dark:bg-white/10 bg-white ml-4 px-3 py-2 text-sm font-semibold dark:text-white ring-1 ring-inset dark:ring-transparent ring-gray-300 text-gray-900 shadow-sm hover:bg-white/20 mb-8"
                          >
                            Submit
                          </button>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
};
