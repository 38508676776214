import { BellIcon } from "@heroicons/react/20/solid";
import { DashboardLayout } from "../../../components/dashboard/layouts/DashboardLayout";
import {
  AtSymbolIcon,
  BoltIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PhoneIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { FORM_FIELDS, FormFieldType } from "../../../constants/form";
import {
  Form,
  FormField,
  FormFieldCategory,
  FormRequest,
  FormResponse,
} from "../../../models/form";
import { Fragment, useEffect, useState } from "react";
import { FieldPicker } from "../../../components/dashboard/forms/FieldPicker";
import { useFormStore } from "../../../store/form";
import { FormFieldList } from "../../../components/dashboard/forms/FormFieldList";
import { Dialog, Switch, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HomeIcon,
  InboxIcon,
  Paintbrush,
  SettingsIcon,
  Sparkle,
  SparklesIcon,
  SquareArrowOutUpRight,
  UsersIcon,
  XIcon,
} from "lucide-react";
import { FieldSettings } from "../../../components/dashboard/forms/FieldSettings";
import { FormRenderer } from "../../../components/dashboard/forms/FormRenderer";
import { Formik, FormikValues } from "formik";
import yup from "../../../crud/yup-extended";
import { createForm, getForm, updateForm } from "@/dashboardQueries";
import { FormPublishedModal } from "@/components/dashboard/forms/FormPublishedModal";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { getFormUrl, toFrontendFormat } from "@/utils/forms";
import { CreateWithAI } from "@/components/dashboard/forms/CreateWithAI";
import { FormBreadcrumbs } from "@/components/dashboard/forms/FormBreadcrumbs";
import { fi } from "date-fns/locale";
import { classNames } from "@/utils/styles";
import { FormSettings } from "@/components/dashboard/forms/FormSettings";
import { ORGANISATION_KEY } from "@/pages/protected-route";

const uniqueOptions = (options: string[] | undefined) => {
  if (!Array.isArray(options)) {
    return false;
  }
  const uniqueOptionsSet = new Set(options);
  return uniqueOptionsSet.size === options.length;
};

const validationSchema = yup.object({
  fields: yup.array().of(
    yup.object({
      id: yup.number(),
      name: yup.string().required("Required"),
      content: yup.string().nullable(),
      description: yup.string().nullable(),
      helpText: yup.string().nullable(),
      required: yup.boolean(),
      options: yup
        .array()
        .of(yup.string())
        .when("field.type", {
          is: (value: string) => ["multipleChoice", "dropdown"].includes(value),
          then: (schema) =>
            schema
              .of(yup.string().required("Choice is required"))
              .min(1, "At least one choice is required")
              .test("unique", "Choices must be unique", uniqueOptions),
          otherwise: (schema) => schema.notRequired(),
        }),
      rules: yup
        .array()
        .of(
          yup.object({
            when: yup.object({
              id: yup.number(),
              name: yup.string(),
              uuid: yup.string().nullable(),
              category: yup.object({ name: yup.string() }),
            }),
            value: yup
              .string()
              .required("Value is required")
              .when("when.field.type", (type, schema) => {
                if (
                  type === "number" ||
                  type === "decimal" ||
                  type === "moneyField"
                ) {
                  return schema.test(
                    "is-number",
                    "Must be a valid number",
                    (val) =>
                      val !== undefined && val !== null && !isNaN(Number(val))
                  );
                }
                return schema;
              }),
            condition: yup
              .object({
                id: yup.string().required(),
                value: yup.string().required(),
              })
              .when("when.category.name", {
                is: (value: string) => ["Contact", "Text"].includes(value),
                then: (schema) =>
                  schema.test(
                    "valid-condition",
                    "Must be a valid condition",
                    (val) =>
                      !!val &&
                      [
                        "equals",
                        "notEquals",
                        "beginsWith",
                        "endsWith",
                        "contains",
                        "doesNotContain",
                      ].includes(val.id)
                  ),
                otherwise: (schema) =>
                  schema.test(
                    "valid-condition-basic",
                    "Must be a valid basic condition",
                    (val) => !!val && ["equals", "notEquals"].includes(val.id)
                  ),
              }),
            then: yup
              .object({
                id: yup.string().required(),
                value: yup.string().required(),
              })
              .test(
                "valid-then",
                "Invalid then",
                (val) =>
                  !!val &&
                  ["show", "hide", "makeRequired", "makeOptional"].includes(
                    val.id
                  )
              ),
          })
        )
        .nullable(),
    })
  ),
});

export const CreateForm = () => {
  const { formId } = useParams<{ formId: string }>();
  const {
    selectedFields,
    setSelectedFields,
    selectFieldModalOpen,
    setSelectFieldModalOpen,
    formSettingsOpen,
    setFormSettingsOpen,
    formPublishedModalOpen,
    setFormPublishedModalOpen,
    form,
    setForm,
    setActiveField,
  } = useFormStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [draftMode, setDraftMode] = useState<"ai" | "manual" | null>(
    // formId ? "manual" : null
    "manual"
  );

  const transformValues = (values: FormikValues) => {
    console.log("le transform");
    console.log(values);
    // filter out big form part of rule, should just be "when", "condition", "value", "then"
    return {
      name: values.name,
      fields: values.fields.map((field: any) => ({
        id: field?.id,
        name: field.name,
        content: field.content,
        fieldType: field.field.type,
        required: field.required,
        description: field.description,
        helpText: field.helpText,
        options: field.options
          ? field.options.map((option: any) => ({ name: option }))
          : [],
        // rules: field?.rules?.map((rule: any) => ({
        //   when: {
        //     id: rule.when.id,
        //     uuid: rule.when.uuid,
        //   },
        //   value: rule.value,
        //   condition: {
        //     id: rule.condition.id,
        //     value: rule.condition.value,
        //   },
        //   then: {
        //     id: rule.then.id,
        //     value: rule.then.value,
        //   },
        // })),
      })),
    } as FormRequest;
  };

  // If there is a formId, then we need to set loading to be true, get the form and wait
  useEffect(() => {
    const fetchForm = async () => {
      if (formId) {
        try {
          const formResponse: AxiosResponse<FormResponse> = await getForm(
            parseInt(formId)
          );
          const frontendForm = toFrontendFormat(formResponse.data);
          setForm(toFrontendFormat(formResponse.data));
          setActiveField(frontendForm.fields[0]);
          setIsLoading(false);

          // Handle the form data as needed
        } catch (error) {
          console.error("Failed to fetch form:", error);
        }
      } else {
        setIsLoading(false);
      }
    };

    setForm(null);
    fetchForm();
  }, [formId]);

  const features = [
    {
      name: "Build from scratch",
      description:
        "Start with a blank form and add fields to create your form from scratch",
      href: "#",
      icon: PencilSquareIcon,
      colour: "yellow",
      onClick: () => setDraftMode("manual"),
    },
    {
      name: "Create with AI (Coming Soon)",
      description:
        "Describe what you need and let our AI create a form for you based on your needs",
      href: "#",
      icon: SparklesIcon,
      colour: "green",
      onClick: () => setDraftMode("ai"),
    },
  ];

  const organisation = JSON.parse(
    localStorage.getItem(ORGANISATION_KEY) || "{}"
  );

  return (
    <DashboardLayout requiredPermissions={[]}>
      <div className="flex min-h-full flex-col">
        {!draftMode ? (
          <div className="max-w-4xl mx-auto w-full flex xl:items-center h-screen px-10">
            <div className="xl:-mt-64 mt-24">
              <div className="mb-6">
                <h1 className="text-xl font-semibold dark:text-white text-gray-800">
                  How do you want to build your form?
                </h1>
              </div>
              <div className="mt-10">
                <div className="mx-auto max-w-2xl lg:max-w-none">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 lg:max-w-none lg:grid-cols-2">
                    {features.map((feature) => (
                      <div
                        key={feature.name}
                        className="flex flex-col dark:bg-white/5 bg-white/75 p-6 rounded-lg"
                      >
                        <dt className="text-base/7 font-semibold dark:text-white">
                          <div
                            className={`mb-6 flex size-12 items-center justify-center rounded-lg bg-${feature.colour}-500/10 text-${feature.colour}-400 ring-1 ring-inset ring-${feature.colour}-500/20`}
                          >
                            <feature.icon
                              aria-hidden="true"
                              className="size-8"
                            />
                          </div>
                          {feature.name}
                        </dt>
                        <dd className="mt-1 flex flex-auto flex-col text-base/7">
                          <p className="flex-auto dark:text-white/50 text-gray-500">
                            {feature.description}
                          </p>
                          <p className="mt-6">
                            <button
                              type="button"
                              onClick={feature.onClick}
                              className="text-sm/6 font-semibold dark:text-white"
                            >
                              Get started <span aria-hidden="true">→</span>
                            </button>
                          </p>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ) : draftMode == "ai" ? (
          <div className="max-w-4xl mx-auto w-full flex items-center h-screen">
            <CreateWithAI />
          </div>
        ) : (
          <>
            {isLoading ? (
              <div className="w-full h-screen flex items-center justify-center -mt-32">
                <svg
                  className="animate-spin mr-2 h-12 w-12 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <Formik
                initialValues={
                  form
                    ? form
                    : {
                        name: "My New Form",
                        fields: [],
                      }
                }
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  setIsSubmitting(true);

                  const transformedValues = transformValues(values);

                  if (form && form.id) {
                    const response: AxiosResponse<FormResponse> =
                      await updateForm(form.id, transformedValues);
                    setForm(response.data);
                  } else {
                    const response: AxiosResponse<FormResponse> =
                      await createForm(transformedValues);
                    setForm(response.data);
                  }
                  setIsSubmitting(false);
                  setFormPublishedModalOpen(true);
                  setIsPublished(true);
                }}
              >
                {({ values, errors, handleSubmit }) => {
                  // When the form values change, reset the published state
                  useEffect(() => {
                    setIsPublished(false);
                  }, [values]);

                  console.log(errors);
                  console.log(values);

                  return (
                    <>
                      {values.fields.length > 0 ? (
                        <>
                          <div className="px-2 pt-5 -mb-3.5">
                            <FormBreadcrumbs />
                          </div>
                          {/* Field picker modal */}
                          <Transition.Root
                            show={selectFieldModalOpen}
                            as={Fragment}
                          >
                            <Dialog
                              as="div"
                              className="relative z-40"
                              onClose={setSelectFieldModalOpen}
                            >
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
                              </Transition.Child>

                              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                  >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl p-10">
                                      <div className="absolute right-0 mr-6">
                                        <XIcon
                                          className="h-6 w-6 cursor-pointer text-gray-500 -mt-4"
                                          onClick={() =>
                                            setSelectFieldModalOpen(false)
                                          }
                                        />
                                      </div>
                                      <FieldPicker
                                        onPickCallback={() =>
                                          setSelectFieldModalOpen(false)
                                        }
                                      />
                                    </Dialog.Panel>
                                  </Transition.Child>
                                </div>
                              </div>
                            </Dialog>
                          </Transition.Root>

                          {/* Form settings */}
                          <FormSettings
                            open={formSettingsOpen}
                            setOpen={setFormSettingsOpen}
                          />

                          {/* Published Form modal */}
                          <Transition.Root
                            show={formPublishedModalOpen}
                            as={Fragment}
                          >
                            <Dialog
                              as="div"
                              className="relative z-40"
                              open={formPublishedModalOpen}
                              onClose={setSelectFieldModalOpen}
                            >
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
                              </Transition.Child>

                              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                  >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl p-6">
                                      <FormPublishedModal uuid={form?.uuid} />
                                      <div className="w-full flex items-center justify-center mt-5">
                                        <button
                                          id="close-form-published-modal"
                                          type="button"
                                          onClick={() =>
                                            setFormPublishedModalOpen(false)
                                          }
                                          className="w-40 justify-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                        >
                                          Close
                                        </button>
                                      </div>
                                    </Dialog.Panel>
                                  </Transition.Child>
                                </div>
                              </div>
                            </Dialog>
                          </Transition.Root>

                          <div className="w-full dark:bg-dark-secondary bg-gray-50 h-4 mx-4 mt-4 sticky top-0 relative z-10"></div>
                          <div className="mx-auto flex w-full items-start gap-x-4 px-4 -mt-4">
                            <aside className="hidden shrink-0 lg:block w-[15rem] sticky top-0 min-h-screen pt-4 z-20">
                              {/* Left column area */}
                              <div className="relative h-full rounded-lg border dark:border-white/5 p-2">
                                <FormFieldList />
                              </div>
                            </aside>

                            <div className="flex-1 flex gap-x-4">
                              <main className="w-3/5">
                                <div className="sticky top-0 pt-4 relative z-10">
                                  <div className="flex bg-white dark:bg-dark-secondary p-2 rounded-lg border dark:border-white/5">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setSelectFieldModalOpen(true)
                                      }
                                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                    >
                                      <PlusIcon className="h-4 w-4 mr-1" />
                                      Add Content
                                    </button>

                                    <div className="flex flex-1 justify-end">
                                      <button
                                        id="open-form"
                                        type="button"
                                        onClick={() =>
                                          window.open(
                                            getFormUrl(
                                              form?.uuid,
                                              organisation
                                            ),
                                            "_blank"
                                          )
                                        }
                                        className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                      >
                                        <SquareArrowOutUpRight className="h-4 w-4" />
                                      </button>

                                      {form?.uuid && (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            setFormSettingsOpen(true)
                                          }
                                          className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                        >
                                          Settings
                                          <SettingsIcon className="ml-1 w-4 h-4" />
                                        </button>
                                      )}

                                      {!isPublished && (
                                        <button
                                          type="button"
                                          onClick={() => handleSubmit()}
                                          className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                                        >
                                          {isSubmitting ? (
                                            <>
                                              <svg
                                                className="animate-spin mr-1.5 h-5 w-5 dark:text-gray-900"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                              >
                                                <circle
                                                  className="opacity-25"
                                                  cx="12"
                                                  cy="12"
                                                  r="10"
                                                  stroke="currentColor"
                                                  stroke-width="4"
                                                ></circle>
                                                <path
                                                  className="opacity-75"
                                                  fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                              </svg>
                                              Publishing...
                                            </>
                                          ) : (
                                            <>Publish</>
                                          )}
                                          <PaperAirplaneIcon className="ml-1 w-4 h-4" />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="w-full rounded-lf border dark:border-white/5 p-2 rounded-lg flex mt-4">
                                  <FormRenderer
                                    fields={selectedFields}
                                    inFormBuilder
                                  />
                                </div>
                              </main>

                              <aside className="flex-1 sticky top-0 h-screen pt-4">
                                <div className="relative h-full overflow-hidden rounded-xl border dark:border-white/5 opacity-75 p-4">
                                  <FieldSettings />
                                </div>
                              </aside>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="flex items-center justify-center pt-10 px-10">
                          <div>
                            <div className="mb-6">
                              <button
                                onClick={() => setDraftMode(null)}
                                className="-ml-1 px-1 text-gray-500 text-sm flex items-center"
                              >
                                <ChevronLeftIcon className="h-5 w-5 inline-block mr-1" />
                                Change draft mode
                              </button>
                            </div>
                            <h3 className="px-1 dark:text-white/90 font-semibold text-xl text-gray-800">
                              Choose your forms first field
                            </h3>
                            <div className="mt-8">
                              <FieldPicker />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }}
              </Formik>
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
};
