import { Dropdown } from "@/crud/form/Dropdown";
import { BASE_URL } from "@/dashboardQueries";
import { PaginatedResponse } from "@/models/Dashboard";
import { Ticket } from "@/models/Tickets";
import axios from "axios";
import { useQuery } from "react-query";

export const IssueTicket = () => {
  const fetchTickets = async (): Promise<PaginatedResponse<Ticket>> => {
    const { data } = await axios.get(`${BASE_URL}/dashboard/tickets`);
    return data;
  };

  const {
    data: tickets,
    error: ticketsError,
    isLoading: ticketsLoading,
  } = useQuery("tickets", fetchTickets);

  const ticketOptions =
    tickets?.results.map((ticket) => ({
      id: ticket.id,
      value: ticket.name,
    })) || [];
  return (
    <div>
      <h4
        data-sidebar="group-label"
        className="px-3 border- border-white/5 text-xs text-stone-400 flex h-8 shrink-0 items-center text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
      >
        Issue a ticket
      </h4>
      <div className="px-2.5">
        <div className="my-1">
          <Dropdown name="tickets" options={ticketOptions} />
        </div>
      </div>
    </div>
  );
};
