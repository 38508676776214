import { FORM_FIELDS } from "@/constants/form";
import { Form, FormResponses } from "@/models/form";
import {
  FIELDS_NOT_TO_DISPLAY,
  getFormCategoryForFieldType,
} from "@/pages/dashboard/forms/responses";
import { formatDateWithSmallMonth } from "@/utils/date";
import { Dialog, Transition } from "@headlessui/react";
import { PaperClipIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { toSentenceCase } from "js-convert-case";
import { Fragment } from "react/jsx-runtime";
import { FieldResponseRenderer } from "./FieldResponseRenderer";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  form: Form;
  formResponse: FormResponses | null;
}

export const ViewResponseDialog = ({
  open,
  setOpen,
  form,
  formResponse,
}: Props) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="h-full border-l border-white/10 bg-dark-primary flex flex-col">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6 dark:bg-dark-secondary flex-shrink-0">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold text-gray-900 dark:text-white">
                            View Response
                          </Dialog.Title>
                          {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                            Fill in the details below to{" "}
                          </p> */}
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="relative text-gray-400 hover:text-gray-500"
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="size-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 overflow-y-auto px-10 py-6">
                      <div>
                        <div className="px-4 sm:px-0">
                          <h3 className="text-base/7 font-semibold text-gray-900 dark:text-white">
                            Form Name
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm/6 text-gray-500 dark:text-gray-400">
                            {form.name}
                          </p>
                        </div>
                        <div className="mt-6">
                          <dl className="grid grid-cols-1 sm:grid-cols-2">
                            {form.fields
                              .filter(
                                (field) =>
                                  !FIELDS_NOT_TO_DISPLAY.includes(
                                    field.fieldType
                                  )
                              )
                              .map((formField) => {
                                const fieldCategory =
                                  getFormCategoryForFieldType(
                                    formField.fieldType
                                  );
                                if (fieldCategory) {
                                  const fieldInfo = fieldCategory.fields.find(
                                    (f) => f.type === formField.fieldType
                                  );
                                  const response = formResponse?.responses.find(
                                    (field) =>
                                      field.field.uuid === formField.uuid
                                  );
                                  if (fieldInfo) {
                                    const Icon = fieldInfo.icon;
                                    return (
                                      <div
                                        data-field-type={formField.fieldType}
                                        className={`border-t border-gray-100 dark:border-white/10 px-4 py-6 sm:px-0 sm:col-span-2`}
                                      >
                                        <dt className="text-sm/6 font-medium text-gray-900 dark:text-white">
                                          <div className="flex items-center space-x-2">
                                            <div
                                              className={`${fieldCategory.colour} p-1 rounded-md shadow-lg`}
                                            >
                                              <Icon className="h-4 w-4" />
                                            </div>
                                            <span>
                                              {toSentenceCase(formField.name)}
                                            </span>
                                          </div>
                                        </dt>
                                        <dd className="mt-1 text-sm/6 text-gray-700 dark:text-gray-400 sm:mt-4">
                                          <FieldResponseRenderer
                                            uuid={formField.uuid}
                                            formData={form}
                                            formResponses={formResponse}
                                            detail={true}
                                          />
                                        </dd>
                                      </div>
                                    );
                                  }
                                }
                              })}
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
