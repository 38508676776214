import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Field, getIn, useFormikContext } from "formik";
import { toCamelCase } from "js-convert-case";
import { useDarkMode } from "usehooks-ts";

// Might replace Input in this file. Need to figure out what to do with the Input component.
interface Props {
  name: string;
  type: string;
  label: string;
  helpText?: string;
}
export const CommonInput = ({ name, type, label, helpText }: Props) => {
  const darkMode = useDarkMode();
  const { errors } = useFormikContext();
  const fieldError = getIn(errors, name);

  const id = toCamelCase(name);

  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 dark:text-white"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm relative">
        <div className="relative">
          <Field
            type={type}
            id={id}
            name={name}
            autoComplete="given-name"
            className={`block w-full rounded-md border-0 py-2 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset dark:focus:ring-indigo-600 focus:ring-primary sm:text-sm sm:leading-6 ${
              darkMode.isDarkMode
                ? "bg-zinc-800 border-zinc-700 shadow-md text-white"
                : "bg-white border-gray-300 shadow-sm text-black"
            } ${
              fieldError
                ? "text-red-900 ring-1 ring-inset ring-red-300 dark:ring-red-500"
                : ""
            }`}
          />
          {fieldError && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {fieldError && (
          <p id={`${id}-error`} className="mt-2 text-xs text-red-500">
            {fieldError}
          </p>
        )}
        {helpText && !fieldError && (
          <p id={`${id}-helpText`} className="mt-2 text-xs text-gray-500">
            {helpText}
          </p>
        )}
      </div>
    </div>
  );
};
