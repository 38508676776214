import {
  AccreditationGroup,
  AccreditationIndividual,
} from "@/models/accreditation";
import { useAccreditationStore } from "@/store/accreditation";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, FormikValues, useFormikContext } from "formik";
import { Fragment } from "react/jsx-runtime";
import yup from "@/crud/yup-extended";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Input } from "@/crud/form/Input";
import axios from "axios";
import { BASE_URL } from "@/dashboardQueries";
import { useQueryClient } from "react-query";
import { invalidateAccreditationGroups } from "@/utils/accreditation";
import { TicketAllocationDropdown } from "@/components/portal/accreditation/TicketAllocationDropdown";
import { Switch } from "@/crud/form/Switch";
import { Checkbox } from "@/crud/form/Checkbox";

interface Props {
  group: AccreditationGroup;
}

export const CreateIndividualDialog = ({ group }: Props) => {
  const queryClient = useQueryClient();
  const { individual, createIndividualOpen, setCreateIndividualOpen } =
    useAccreditationStore();

  // Posting data
  const handleSubmit = async (values: FormikValues) => {
    try {
      values["groupId"] = group.id;
      if (individual && individual.id) {
        // Update existing individual
        const response = await axios.put(
          `${BASE_URL}/accreditation/individuals/${individual.id}`,
          values
        );
      } else {
        // Create new individual
        const response = await axios.post(
          `${BASE_URL}/accreditation/individuals`,
          values
        );
      }
      setCreateIndividualOpen(false);
      queryClient.invalidateQueries("individuals");
    } catch (error) {}
  };

  return (
    <Transition.Root show={createIndividualOpen} as={Fragment}>
      <Dialog
        open={createIndividualOpen}
        onClose={setCreateIndividualOpen}
        className="relative z-10"
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="h-full border-l border-white/10 ">
                    <Formik
                      initialValues={
                        Object.keys(individual).length > 0
                          ? {
                              ...individual,
                              tickets: individual.tickets.map((ticket) => ({
                                id: ticket.allocation.ticket.id,
                                value: ticket.allocation.ticket.name,
                              })),
                            }
                          : {
                              firstName: "",
                              lastName: "",
                              email: "",
                              phoneNumber: "",
                            }
                      }
                      validationSchema={yup.object({
                        firstName: yup.string().required("Required"),
                        lastName: yup.string().required("Required"),
                        email: yup
                          .string()
                          .email("Must be a valid email")
                          .required("Required"),
                        phoneNumber: yup.string(),
                        isGroupContact: yup.boolean(),
                        sendInvitationEmail: yup.boolean(),
                        tickets: yup.array().of(
                          yup.object().shape({
                            id: yup.number(),
                            value: yup.string().required("Required"),
                          })
                        ),
                      })}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({ handleSubmit, values }) => {
                        const ticketCount = group.groupAllocatedTickets.reduce(
                          (acc, ticket) => {
                            acc[ticket.ticket.id] = ticket.claimed;
                            return acc;
                          },
                          {}
                        );

                        return (
                          <form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-dark-primary shadow-xl">
                            <div className="flex-1">
                              {/* Header */}
                              <div className="bg-gray-50 px-4 py-6 sm:px-6 dark:bg-dark-secondary">
                                <div className="flex items-start justify-between space-x-3">
                                  <div className="space-y-1">
                                    <Dialog.Title className="text-base font-semibold text-gray-900 dark:text-white">
                                      {individual && individual.id
                                        ? "Edit"
                                        : "Create"}{" "}
                                      Individual
                                    </Dialog.Title>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                      Fill in the details below to{" "}
                                      {individual && individual.id
                                        ? "edit"
                                        : "create"}{" "}
                                      an individual
                                    </p>
                                  </div>
                                  <div className="flex h-7 items-center">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        setCreateIndividualOpen(false)
                                      }
                                      className="relative text-gray-400 hover:text-gray-500"
                                    >
                                      <span className="absolute -inset-2.5" />
                                      <span className="sr-only">
                                        Close panel
                                      </span>
                                      <XMarkIcon
                                        aria-hidden="true"
                                        className="size-6"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* Divider container */}
                              <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-white/10 sm:py-0">
                                {/* Name */}
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5">
                                      Name
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2 flex">
                                    <div className="w-1/2 mr-2">
                                      <Input
                                        name="firstName"
                                        type="text"
                                        value={values.firstName}
                                        placeholder="First name"
                                        required
                                      />
                                    </div>
                                    <div className="w-1/2 ml-2">
                                      <Input
                                        name="lastName"
                                        type="text"
                                        value={values.lastName}
                                        placeholder="Last name"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Email */}
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="project-description"
                                      className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5"
                                    >
                                      Email
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <Input
                                      name="email"
                                      type="email"
                                      value={values.email}
                                      required
                                    />
                                  </div>
                                </div>

                                {/* Phone number */}
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="project-description"
                                      className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5"
                                    >
                                      Phone number
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <Input
                                      name="phoneNumber"
                                      type="text"
                                      value={values.phoneNumber}
                                      required
                                    />
                                  </div>
                                </div>

                                {/* Is Group Contact */}
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5">
                                      Group Contact
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2 text-left">
                                    <Switch name="isGroupContact" />
                                    <p className="text-xs text-gray-400 mt-0.5">
                                      Group contacts can login and manage the
                                      group
                                    </p>
                                  </div>
                                </div>

                                {/* if we want to invite */}
                                {values.isGroupContact && (
                                  <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5">
                                        Send invitation email
                                      </label>
                                    </div>
                                    <div className="sm:col-span-2 text-left">
                                      <Checkbox name="sendInvitationEmail" />
                                      <p className="text-xs text-gray-400 mt-2">
                                        Send an invitation email to the group
                                        contact to let them know about their new
                                        role
                                      </p>
                                    </div>
                                  </div>
                                )}

                                {/* Tickets */}
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5">
                                      Tickets
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <TicketAllocationDropdown
                                      name="tickets"
                                      tickets={group.groupAllocatedTickets}
                                      ticketCount={ticketCount}
                                    />
                                    <p className="text-xs text-gray-400 mt-2">
                                      Make sure you allocate enough tickets to
                                      be able to assign them to this individual
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Action buttons */}
                            <div className="shrink-0 border-t border-gray-200 dark:border-white/10 px-4 py-5 sm:px-6 dark:bg-dark-secondary">
                              <div className="flex justify-end space-x-3">
                                <button
                                  type="button"
                                  onClick={() => setCreateIndividualOpen(false)}
                                  className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  {individual && individual.id
                                    ? "Edit"
                                    : "Create"}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
