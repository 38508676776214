import { getIn, useFormikContext } from "formik";
import { FileIcon, TrashIcon, UploadIcon } from "lucide-react";
import { useDropzone } from "react-dropzone";

interface Props {
  name: string;
  singleFile?: boolean;
}

export const FileUploadField = ({ name, singleFile = false }: Props) => {
  const { setFieldValue, errors, values } = useFormikContext<any>();

  const onRemove = (index: number) => {
    const newFiles = files.filter((file: File, i: number) => i !== index);
    setFieldValue(name, newFiles);
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (singleFile) {
      setFieldValue(name, acceptedFiles[0]);
    } else {
      setFieldValue(name, acceptedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: !singleFile,
  });

  const files = getIn(values, name);

  const getBorderColour = () => {
    if (getIn(errors, name)) {
      return "border-red-500";
    }
    return "border-gray-300 dark:border-zinc-700";
  };

  return (
    <>
      {files && (singleFile ? files.name : files.length) ? (
        <div className="mt-3">
          <ul
            role="list"
            id="file-upload-list"
            className="divide-y divide-gray-200 dark:divide-white/10 rounded-md border border-gray-200 dark:border-white/10"
          >
            {singleFile ? (
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <FileIcon
                    aria-hidden="true"
                    className="size-5 shrink-0 text-gray-400"
                  />
                  <span className="ml-2 w-0 flex-1 truncate text-gray-500">
                    {files.name}
                  </span>
                </div>
                <div className="ml-4 shrink-0">
                  <span
                    onClick={() => setFieldValue(name, null)}
                    className="cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </span>
                </div>
              </li>
            ) : (
              files.map((file: File, index: number) => (
                <li
                  key={index}
                  className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                >
                  <div className="flex w-0 flex-1 items-center">
                    <FileIcon
                      aria-hidden="true"
                      className="size-5 shrink-0 text-gray-400"
                    />
                    <span className="ml-2 w-0 flex-1 truncate text-gray-500">
                      {file.name}
                    </span>
                  </div>
                  <div className="ml-4 shrink-0">
                    <span
                      onClick={() => onRemove(index)}
                      className="cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </span>
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`mt-2 h-32 flex items-center justify-center border border-dashed ${getBorderColour()} rounded-lg p-4 text-center`}
        >
          <div className="text-center">
            <input {...getInputProps()} />
            <div className="flex items-center justify-center">
              <UploadIcon className="h-8 w-8 mb-2 dark:text-white" />
            </div>
            <p className="text-sm dark:text-white/40">
              Drag & drop some files here, or click to select files
            </p>
          </div>
        </div>
      )}
    </>
  );
};
