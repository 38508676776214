import data, { Emoji, Skin } from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { getIn, useFormikContext } from "formik";
import { Laugh } from "lucide-react";
import { useState, useEffect, useRef } from "react";

interface Props {
  name: string;
  onSelect: (emoji: string) => void;
  open?: boolean;
}

export const EmojiPicker = ({ name, onSelect, open }: Props) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const [isOpen, setIsOpen] = useState(open);
  const pickerRef = useRef<HTMLDivElement>(null);

  const value = getIn(values, name);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <div
      // onClick={() => setIsOpen(!isOpen)}
      className="relative cursor-pointer w-9 h-9 text-md flex justify-center items-center block"
    >
      <div
        id="emoji-picker"
        className="w-9 h-9 cursor-pointer flex justify-center items-center dark:bg-dark-secondary dark:ring-white/5 dark:text-gray-400 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        onClick={() => setIsOpen(!isOpen)}
      >
        {value ? value : <Laugh className="w-5 h-5 text-zinc-600" />}
      </div>
      {isOpen && (
        <div ref={pickerRef} className="absolute top-0 left-0">
          <Picker
            className=""
            data={data}
            onEmojiSelect={(emoji: Skin) => {
              console.log("emoji++ ", emoji.native);
              setFieldValue(name, emoji.native);
              onSelect(emoji.native);
              setIsOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
