import { Textarea } from "@/crud/form/Textarea";
import yup from "@/crud/yup-extended";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Field, Formik } from "formik";
import { CheckCircleIcon } from "lucide-react";
import { useState } from "react";

export const CreateWithAI = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const Describe = () => {
    return (
      <div className="w-full flex">
        <div className="w-full pr-4">
          <label
            htmlFor="description"
            className="flex items-center block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-2"
          >
            Describe the purpose of your form
            <div className="flex flex-1 justify-end text-white/50 font-normal text-xs">
              Required
            </div>
          </label>
          <Textarea name="description" required />
          <div className="mt-4 items-center rounded-md bg-blue-400/10 px-2 py-2 text-sm font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
            <h4 className="flex text-md">
              <InformationCircleIcon className="size-5 mr-1.5" /> For the best
              results
            </h4>
            <ul className="list-disc list-inside font-normal text-sm p-2">
              <li>Describe the purpose of your form</li>
              <li>Include the type of information you want to collect</li>
              <li>Include the type of questions you want to ask</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const SpecificQuestions = () => {
    return (
      <div className="w-full flex">
        <div className="w-full pr-4">
          <label
            htmlFor="specificQuestions"
            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-2"
          >
            Specific questions
          </label>
          <Textarea name="specificQuestions" as="textarea" required={false} />
          <div className="mt-4 items-center rounded-md bg-blue-400/10 px-2 py-2 text-sm font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
            <h4 className="flex">
              <InformationCircleIcon className="size-5 mr-1.5" /> If there are
              specific questions that you want to ask describe them in detail
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const IndustryKnowledge = () => {
    return (
      <div className="w-full flex">
        <div className="w-full pr-4">
          <label
            htmlFor="industryKnowledge"
            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-2"
          >
            Industry knowledge
          </label>
          <Textarea name="industryKnowledge" as="textarea" required={false} />
          <div className="mt-4 items-center rounded-md bg-blue-400/10 px-2 py-2 text-sm font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
            <h4 className="flex">
              <InformationCircleIcon className="size-5 mr-1.5" /> Describe any
              industry-specific knowledge that might be relevant
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const steps = [
    { name: "Describe your form", component: Describe },
    { name: "Specific questions", component: SpecificQuestions },
    { name: "Industry knowledge", component: IndustryKnowledge },
  ];

  return (
    <div className="w-full -mt-64">
      <div className="">
        <h3 className="px-1 dark:text-white/90 font-semibold text-xl">
          Create with AI
        </h3>
      </div>
      <div className="flex w-full mt-4 rounded-lg bg-white/5 border-white/10 border">
        <div className="pr-10  p-4 rounded-lg">
          <nav aria-label="Progress" className="flex justify-center">
            <ol role="list" className="space-y-6">
              {steps.map((step, index) => (
                <li key={step.name}>
                  {index < currentStep ? (
                    <a href={step.href} className="group">
                      <span className="flex items-start">
                        <span className="relative flex size-5 shrink-0 items-center justify-center">
                          <CheckCircleIcon
                            aria-hidden="true"
                            className="size-full text-indigo-600 group-hover:text-indigo-800"
                          />
                        </span>
                        <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </a>
                  ) : index == currentStep ? (
                    <a
                      href={step.href}
                      aria-current="step"
                      className="flex items-start"
                    >
                      <span
                        aria-hidden="true"
                        className="relative flex size-5 shrink-0 items-center justify-center"
                      >
                        <span className="absolute size-4 rounded-full bg-indigo-200" />
                        <span className="relative block size-2 rounded-full bg-indigo-600" />
                      </span>
                      <span className="ml-3 text-sm font-medium text-white">
                        {step.name}
                      </span>
                    </a>
                  ) : (
                    <a href={step.href} className="group">
                      <div className="flex items-start">
                        <div
                          aria-hidden="true"
                          className="relative flex size-5 shrink-0 items-center justify-center"
                        >
                          <div className="size-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                        </div>
                        <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </p>
                      </div>
                    </a>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="flex flex-1 p-4 w-full pl-4">
          <Formik
            initialValues={{
              description: "",
              specificQuestions: "",
              industryKnowledge: "",
            }}
            validationSchema={yup.object().shape({
              description: yup.string().required("Required"),
              specificQuestions: yup.string(),
              industryKnowledge: yup.string(),
            })}
            onSubmit={() => {}}
          >
            {({ values, handleChange, handleSubmit, errors }) => {
              const handleNext = () => {
                if (currentStep < steps.length - 1) {
                  setCurrentStep(currentStep + 1);
                }
              };
              return (
                <form onSubmit={handleSubmit} className="w-full -mt-2">
                  <div className="w-full">{steps[currentStep].component()}</div>
                  {values.description && (
                    <button
                      type="button"
                      onClick={handleNext}
                      className="mt-4 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Next
                    </button>
                  )}
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
