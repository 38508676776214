import { Dropdown } from "@/crud/form/Dropdown";
import { BASE_URL } from "@/dashboardQueries";
import { AccreditationGroup } from "@/models/accreditation";
import { PaginatedResponse } from "@/models/Dashboard";
import axios from "axios";
import { useQuery } from "react-query";

interface Props {
  type: "Add" | "Remove";
}

export const AddRemoveFromGroup = ({ type }: Props) => {
  const fetchGroups = async (): Promise<
    PaginatedResponse<AccreditationGroup>
  > => {
    const { data } = await axios.get(`${BASE_URL}/accreditation/groups`);
    return data;
  };

  const {
    data: groups,
    error: groupsError,
    isLoading: groupsLoading,
  } = useQuery("groups", fetchGroups);

  const groupOptions =
    groups?.results.map((group) => ({
      id: group.id,
      value: `${group.icon} ${group.name}`,
    })) || [];

  return (
    <div>
      <h4
        data-sidebar="group-label"
        className="px-3 border- border-white/5 text-xs text-stone-400 flex h-8 shrink-0 items-center text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
      >
        {type === "Add"
          ? "Add an individual to a group"
          : "Remove an individual from a group"}
      </h4>
      <div className="px-2.5">
        <div className="my-1">
          <Dropdown name="group" options={groupOptions} />
        </div>
      </div>
    </div>
  );
};
