import axios from "axios";
import { ManageOrderModal } from "../order/ManageOrderModal";
import { useEffect } from "react";
import { getEvent } from "../../../queries";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../../store/cart";
import { Event } from "../../../models/Cart";
import { PoweredBy } from "../../PoweredBy";
import { useLocation, useParams } from "react-router-dom";
import { State } from "../../../store/store";
import { DynamicMetaTags } from "@/components/DynamicMetaTags";
import { useDarkMode } from "usehooks-ts";

interface Props {
  children: any;
}

export const PortalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { eventSlug } = useParams();
  const organisation = useSelector((state: State) => state.cart.organisation);
  const event = useSelector((state: State) => state.cart.event);
  const darkMode = useDarkMode();

  // Set the axios request interceptor
  axios.interceptors.request.use((config) => {
    // Add context for what Event it is
    config.headers.OrganisationDomain = window.location.host;
    config.headers.EventSlug = eventSlug;
    return config;
  });

  // Get the Event object
  useEffect(() => {
    if (eventSlug) {
      (async function () {
        try {
          let response = await getEvent(window.location.host, eventSlug);
          dispatch(cartActions.SetEvent({ event: response.data as Event }));
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [window.location.host]);

  const iconFill = darkMode.isDarkMode ? "fill-white" : "fill-black";

  return (
    <div className="relative dark:bg-dark-primary px-4 lg:px-0">
      {event && event.organisation && (
        <DynamicMetaTags
          url={event.organisation.domain}
          title={`Ticketr - ${event.name}`}
          description={`Buy tickets to ${event.name}`}
        />
      )}
      {/* <ManageOrderModal /> */}
      {/* <div className="bg-black w-full z-50 relative flex justify-end p-2 border-t-4 border-indigo-500 shadow-md">
    <>
      {event?.organisation && event?.organisation.showBranding && (
        <div
          className={`font-['Finlandica'] hover:text-indigo-500 text-center bg-white flex items-center justify-center py-2 text-sm text-gray-400 dark:bg-dark-secondary border-t dark:border-white/10 w-full fixed bottom-0 z-50 cursor-pointer ${iconFill} hover:fill-indigo-500 transition-all duration-300`}
          onClick={() => {
            window.open("https://ticketr.events", "_blank");
          }}
        >
          Powered by{" "}
          <svg
            id="Livello_2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 648.3 150.34"
            className="ml-1"
            style={{ marginTop: "-2.05px" }}
            height="13"
          >
            <g id="Livello_1-2">
              <rect x="111.99" y="38.89" width="33.07" height="109.44" />
              <path d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z" />
              <polygon points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89" />
              <path d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z" />
              <path d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z" />
              <path d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z" />
              <polygon points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91" />
            </g>
          </svg>
        </div>
      )}
      <div className="relative dark:bg-dark-primary font-['Finlandica'] px-4 lg:px-0 pb-8">
        {console.log(event)}
        {event && (
          <DynamicMetaTags
            url={event.organisation.domain}
            title={`Ticketr - ${event.name}`}
            description={`Buy tickets to ${event.name}`}
          />
        )}
        {/* <ManageOrderModal /> */}
        {/* <div className="bg-black w-full z-50 relative flex justify-end p-2 border-t-4 border-indigo-500 shadow-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Livello_2"
          viewBox="0 0 150.55 209.09"
          className="h-6 fill-white"
        >
          <g id="Livello_1-2">
            <path d="m110.26,44.92V0h-49.61v47.45H0l19.53,24.22L0,95.89h60.64v48.56c0,21.42,5.62,37.73,16.7,48.49,11.05,10.72,27.99,16.16,50.35,16.16h22.85v-44.8h-22.85c-12.06,0-17.44-5.38-17.44-17.44v-50.97h40.29v-48.44h-40.29v-2.53Z" />
          </g>
        </svg>
      </div> */}
      {children}
    </div>
  );
};
