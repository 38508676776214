import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";
import React, { useCallback, useMemo } from "react";
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
  Node,
  Edge,
} from "@xyflow/react";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "@/dashboardQueries";
import "@xyflow/react/dist/style.css";
import "./reactFlowCustom.css"; // Import the custom CSS file
import { Input } from "@/crud/form/Input";
import { Formik } from "formik";
import { PaginatedResponse } from "@/models/Dashboard";
import { Form } from "@/models/form";
import { Dropdown } from "@/crud/form/Dropdown";
import {
  Box,
  CheckCircle,
  CheckCircle2,
  InfoIcon,
  Send,
  TicketIcon,
  XIcon,
} from "lucide-react";
import { AccreditationGroup } from "@/models/accreditation";
import { Ticket } from "@/models/Tickets";
import { EmailTemplate } from "@/models/accreditation";
import { ActionPicker } from "@/components/dashboard/flows/ActionPicker";
import { flowSelector, useFlowStore } from "@/store/flow";
import { useShallow } from "zustand/react/shallow";
import { FlowRenderer } from "@/components/dashboard/flows/FlowRenderer";
import { ActionSettings } from "@/components/dashboard/flows/ActionSettings";

const initialNodes: Array<Node> = [
  // { id: "1", position: { x: 0, y: 0 }, data: { label: "1" } },
  // { id: "2", position: { x: 0, y: 100 }, data: { label: "2" } },
];
const initialEdges: Array<Edge> = [];

const fetchForms = async (): Promise<PaginatedResponse<Form>> => {
  const { data } = await axios.get(`${BASE_URL}/dashboard/forms`);
  return data;
};

const fetchGroups = async (): Promise<
  PaginatedResponse<AccreditationGroup>
> => {
  const { data } = await axios.get(`${BASE_URL}/accreditation/groups`);
  return data;
};

const fetchTickets = async (): Promise<PaginatedResponse<Ticket>> => {
  const { data } = await axios.get(`${BASE_URL}/dashboard/tickets`);
  return data;
};

const fetchEmails = async (): Promise<PaginatedResponse<EmailTemplate>> => {
  const { data } = await axios.get(`${BASE_URL}/dashboard/email-templates`);
  return data;
};

export const StarterNode = ({ data }) => {
  return (
    <>
      <div style={{ padding: "10px 20px" }} className="text-white">
        {data.label}
      </div>

      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

export const ApprovalNode = ({ data }) => {
  return (
    <div className="bg-dark-secondary border-white/10 border text-white rounded-md shadow-md">
      <div className="flex items-center justify-center px-4 py-2">
        <div>
          <CheckCircle className="h-5 w-5 text-green-500 inline-block mr-3" />
        </div>
        <div className="flex justify-center items-center">
          Requires approval
        </div>
        <div>
          <XIcon className="h-5 w-5 text-red-500 inline-block ml-3" />
        </div>
      </div>

      <Handle type="target" position={Position.Top} />
      <Handle type="source" id="yes" position={Position.Left} />
      <Handle type="source" id="no" position={Position.Right} />
    </div>
  );
};

const Flows = () => {
  const { nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange } =
    useFlowStore(useShallow(flowSelector));

  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const {
    data: forms,
    error: formsError,
    isLoading: formsLoading,
  } = useQuery("forms", fetchForms);

  const {
    data: groups,
    error: groupsError,
    isLoading: groupsLoading,
  } = useQuery("groups", fetchGroups);

  const {
    data: tickets,
    error: ticketsError,
    isLoading: ticketsLoading,
  } = useQuery("tickets", fetchTickets);

  const {
    data: emails,
    error: emailsError,
    isLoading: emailsLoading,
  } = useQuery("emails", fetchEmails);

  const StandardNode = ({ data }) => {
    return (
      <div className="bg-dark-secondary border-white/10 border text-white rounded-md shadow-md">
        <div className="flex items-center justify-center px-4 py-2">
          {data.label}
        </div>

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </div>
    );
  };

  const AddToGroupNode = ({ data }) => {
    const groupOptions =
      groups?.results.map((group) => ({
        id: group.id,
        value: `${group.icon} ${group.name}`,
      })) || [];

    return (
      <div className="bg-dark-secondary border-white/10 border text-white rounded-md shadow-md">
        <div className="flex items-center justify-center px-4 py-2 text-sm">
          <Box className="h-4 w-4 inline-block mr-2" />
          {data.label}
        </div>

        <div className="mb-3 px-4 min-w-64">
          <Dropdown name="form" options={groupOptions} />
        </div>

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </div>
    );
  };

  const IssueTicketNode = ({ data }) => {
    const ticketOptions =
      tickets?.results.map((ticket) => ({
        id: ticket.id,
        value: ticket.name,
      })) || [];
    return (
      <div className="bg-dark-secondary border-white/10 border text-white rounded-md shadow-md">
        <div className="flex items-center justify-center px-4 py-2 text-sm">
          <TicketIcon className="h-4 w-4 inline-block mr-2" />
          {data.label}
        </div>

        <div className="mb-3 px-4">
          <Dropdown name="form" options={ticketOptions} />
        </div>

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </div>
    );
  };

  const SendEmailNode = ({ data }) => {
    const emailOptions =
      emails?.results.map((email) => ({
        id: email.id,
        value: email.name,
      })) || [];
    return (
      <div className="bg-dark-secondary border-white/10 border text-white rounded-md shadow-md">
        <div className="flex items-center justify-center px-4 py-2 text-sm">
          <Send className="h-4 w-4 inline-block mr-2" />
          {data.label}
        </div>

        <div className="mb-3 px-4">
          <Dropdown name="form" options={emailOptions} />
        </div>

        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
      </div>
    );
  };

  // Custom Nodes
  const OnFormSubmitNode = ({ data }) => {
    const formOptions =
      forms?.results.map((form) => ({
        id: form.id,
        value: form.name,
      })) || [];

    return (
      <>
        <div style={{ padding: "10px 20px" }} className="text-white">
          <p>{data.label}</p>
          <div className="my-2">
            <Dropdown name="form" options={formOptions} />
          </div>
        </div>

        <Handle type="source" position={Position.Bottom} />
      </>
    );
  };

  // Defines node types
  const nodeTypes = useMemo(
    () => ({
      standardNode: StandardNode,
      approvalNode: ApprovalNode,
      formSubmitNode: OnFormSubmitNode,
      addToGroupNode: AddToGroupNode,
      issueTicketNode: IssueTicketNode,
      sendEmailNode: SendEmailNode,
    }),
    [forms, groups, tickets, emails]
  );

  const onConnect = useCallback(
    (params) => setEdges(addEdge(params, edges)),
    [setEdges]
  );

  const StarterNodes = () => {
    return (
      <>
        <button
          onClick={() =>
            addNode("completes_form", "When individual completes form")
          }
          type="button"
          className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
        >
          When an individual completes a form
        </button>
      </>
    );
  };

  const AfterStartNodes = () => {
    return (
      <div className="flex flex-wrap">
        <button
          className="flex items-center mr-4 mb-4 rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
          onClick={() => addNode("approval", "Require approval")}
        >
          <CheckCircle2 className="h-4 w-4 inline-block mr-1" />
          Require approval
        </button>
        <br />
        <button
          className="flex items-center mr-4 mb-4 rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
          onClick={() => addNode("add_to_group", "Add to group")}
        >
          <Box className="h-4 w-4 inline-block mr-1" />
          Add to Group
        </button>
        <br />
        <button
          className="flex items-center mr-4 mb-4 rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
          onClick={() => addNode("issue_ticket", "Issue ticket")}
        >
          <TicketIcon className="h-4 w-4 inline-block mr-1" />
          Issue ticket
        </button>
        <br />
        <button
          className="flex items-center mr-4 mb-4 rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
          onClick={() => addNode("send_email", "Send email")}
        >
          <Send className="h-4 w-4 inline-block mr-1" />
          Send email
        </button>
      </div>
    );
  };

  /*
    The only nodes we really need are:
    - 1 in 1 out, with icon, colour, name and description (the description is powered by whats set in the action panel I guess)
    - 1 in 2 out (yes/no), with icon, colour, name and description
  */

  return (
    <DashboardLayout pageTitle="Create Flow" requiredPermissions={[]}>
      <div className="flex h-screen">
        <div className="w-[18rem] py-2 border-r border-white/5">
          <ActionPicker />
        </div>
        <div className="flex flex-1">
          <FlowRenderer />
        </div>
        <div className="w-1/4 border-l border-white/5">
          <ActionSettings />
        </div>
      </div>
      <div className="flex min-h-screen">
        <div className="w-1/3 border-r border-white/10 p-4">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="mb-2 block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              Name
            </label>
            <Input name="name" type="text" required />
          </div>
          {nodes.length > 0 ? (
            <AfterStartNodes />
          ) : (
            <div className="">
              <p className="text-sm text-white">No nodes add a starter node</p>

              <div className="mt-4">
                <StarterNodes />
              </div>
            </div>
          )}

          <div className="mt-4">
            <div className="text-sm px-4 py-3 bg-blue-400/10 text-blue-400 ring-1 ring-inset ring-blue-400/30 rounded-md">
              <InfoIcon className="h-4 w-4 inline-block mr-1" />
              This is a demo version, more flow options are coming soon
            </div>
          </div>

          <button
            type="button"
            className="mt-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Save flow
          </button>
        </div>
        <div className="w-2/3">
          <div style={{ height: "100vh" }}>
            {/* <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              defaultEdgeOptions={{ animated: true }}
            >
              <Controls />
              <MiniMap />
              <Background variant="dots" gap={12} size={1} />
            </ReactFlow> */}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export const CreateFlow = () => {
  return (
    <Formik initialValues={{}} onSubmit={(values) => {}}>
      {({ handleSubmit }) => <Flows />}
    </Formik>
  );
};
