import { Form } from "@/models/form";
import { Copy, SquareArrowOutUpRight } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  formUrl: string;
  form: Form;
}

export const FormRowButtons = ({ formUrl, form }: Props) => {
  const [copyButtonText, setCopyButtonText] = useState<string | null>(null);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(formUrl).then(() => {
      setCopyButtonText("Copied! 🎉");
      setTimeout(() => {
        setCopyButtonText(null);
      }, 2000);
    });
  };

  return (
    <>
      <Link
        id="form-responses"
        to={`/dashboard/forms/${form.id}/responses`}
        className="flex ml-2 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
      >
        <div className="dark:bg-zinc-900 bg-gray-300 px-1 mr-1 dark:text-gray-300 text-gray-400 rounded-md">
          {form.responses}
        </div>
        <div className="">Responses</div>
      </Link>
      <button
        type="button"
        onClick={handleCopyLink}
        className="inline-flex ml-2 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
      >
        {copyButtonText ? copyButtonText : <Copy className="h-4 w-4" />}
      </button>
      <button
        id="open-form"
        type="button"
        onClick={() => window.open(formUrl, "_blank")}
        className="mr-2 inline-flex ml-2 items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
      >
        <SquareArrowOutUpRight className="h-4 w-4" />
      </button>
    </>
  );
};
