import { Action, NodeData } from "@/models/flows";
import { useFlowStore } from "@/store/flow";
import { getActionBasedOnType } from "@/utils/flows";
import {
  Background,
  BackgroundVariant,
  Controls,
  Handle,
  MiniMap,
  Node,
  Position,
  ReactFlow,
} from "@xyflow/react";
import { Copy, Trash } from "lucide-react";
import { useMemo, MouseEvent } from "react";
import clsx from "clsx";

export const FlowRenderer = () => {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    activeNode,
    setActiveNode,
  } = useFlowStore();

  const colorMap: Record<string, string> = {
    green: "bg-green-400/10 ring-green-400/60 ring-offset-green-500",
    blue: "bg-blue-400/10 ring-blue-400/60 ring-offset-blue-500",
    yellow: "bg-yellow-400/10 ring-yellow-400/60 ring-offset-yellow-500",
    purple: "bg-purple-400/10 ring-purple-400/60 ring-offset-purple-500",
  };

  const StarterNode = ({ id, data }: { id: string; data: NodeData }) => {
    const { activeNode } = useFlowStore();
    const action: Action | undefined = useMemo(
      () => getActionBasedOnType(data.type),
      [data.type, activeNode?.id]
    );

    return (
      <div
        className={clsx(
          "ring-1 ring-inset rounded-lg",
          colorMap[action?.colour || "red"],
          "p-2 flex relative",
          activeNode && activeNode.id === id && "ring-offset-2"
        )}
      >
        {action && (
          <div className={`p-1.5 bg-${action.colour}-400 rounded-lg`}>
            <action.icon className="w-5 h-5 text-white" />
          </div>
        )}
        <div className="ml-2 flex items-center">
          <div className="">
            <h3
              className={`uppercase text-[0.5rem] text-${action?.colour}-400`}
            >
              {data?.category}
            </h3>
            <h1 className={`text-[0.8rem] text-white/80 leading-tight`}>
              {action?.label}
            </h1>
          </div>
        </div>
        <Handle id="source" type="source" position={Position.Bottom} />
        {activeNode && activeNode.id === id && (
          <div className="w-[10rem] px-2 py-2 bg-zinc-800 shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1 mt-9  rounded-lg border border-white/5">
            <div className="flex">
              <div className="mr-4">
                <Trash className="w-4 h-4 text-white cursor-pointer" />
              </div>
              <div>
                <Copy className="w-4 h-4 text-white cursor-pointer" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const RegularNode = ({ id, data }: { id: string; data: NodeData }) => {
    const { activeNode } = useFlowStore();
    const action: Action | undefined = useMemo(
      () => getActionBasedOnType(data.type),
      [data.type, activeNode?.id]
    );

    return (
      <div
        className={clsx(
          "ring-1 ring-inset rounded-lg",
          colorMap[action?.colour || "red"],
          "p-2 flex relative",
          activeNode && activeNode.id === id && "ring-offset-2"
        )}
      >
        {action && (
          <div className={`p-1.5 bg-${action.colour}-400 rounded-lg`}>
            <action.icon className="w-5 h-5 text-white" />
          </div>
        )}
        <div className="ml-2 flex items-center">
          <div className="">
            <h3
              className={`uppercase text-[0.5rem] text-${action?.colour}-400`}
            >
              {data?.category}
            </h3>
            <h1 className={`text-[0.8rem] text-white/80 leading-tight`}>
              {action?.label}
            </h1>
          </div>
        </div>
        <Handle id="target" type="target" position={Position.Top} />
        <Handle id="source" type="source" position={Position.Bottom} />

        {activeNode && activeNode.id === id && (
          <div className="px-2 py-2 bg-zinc-800 shadow-lg absolute bottom-0 left-0 right-0 -mb-10 rounded-lg border border-white/5">
            <div className="flex">
              <div className="mr-4">
                <Trash className="w-4 h-4 text-white cursor-pointer" />
              </div>
              <div>
                <Copy className="w-4 h-4 text-white cursor-pointer" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const nodeTypes = useMemo(
    () => ({
      starter: StarterNode,
      regular: RegularNode,
    }),
    // re-init when anything in the node changes
    []
  );

  const onNodeClick = (event: MouseEvent, node: Node) => {
    setActiveNode(node);
  };

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      onNodeClick={onNodeClick}
      defaultEdgeOptions={{ animated: true }}
    >
      <Controls />
      <MiniMap />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
  );
};
