import { createContext, useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import {
  Route,
  Routes,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { CartContextType } from "./models/Cart";
import { Complete } from "./pages/complete";
import { Home as DashboardHome } from "./pages/dashboard/home";
import { PaymentPlan } from "./pages/payment-plan";
import { State, initStore } from "./store/store";
import { ViewOrder } from "./pages/dashboard/orders/view-order";
import { SignIn } from "./pages/sign-in";
import { CRUDRenderer } from "./crud/CRUDRenderer";
import { CRUD_RESOURCES } from "./globals";
import { ProtectedRoute } from "./pages/protected-route";
import { NotFound } from "./pages/dashboard/not-found";
import { Order } from "./pages/order";
import { Merchandise } from "./pages/merchandise";
import { OrganisationSettings } from "./pages/dashboard/settings/organisation-settings";
import { Organisation } from "./pages/organisation";
import { Event } from "./pages/event";
import { CreateOrder } from "./pages/dashboard/orders/create";
import { AccountSettings } from "./pages/dashboard/settings/account-settings";
import { CreateForm } from "./pages/dashboard/forms/create";
import { ErrorBoundary } from "react-error-boundary";
import { Maintenance } from "./components/Maintenance";
import { PermissionDeniedPage } from "./pages/dashboard/permission-denied";
import { Invite } from "./pages/invite";
import { DashboardErrorFallback } from "./error-boundaries/DashboardErrorFallback";
import { Reporting } from "./pages/dashboard/reporting/reporting";
import { VelocityReport } from "./pages/dashboard/reporting/velocity-report";
import { Form } from "./pages/form";
import { Accreditation } from "./pages/dashboard/accreditation/home";
import { AccreditationGroups } from "./pages/dashboard/accreditation/groups";
import { ClaimTicket } from "./pages/accreditation/claim-ticket";
import { Emails } from "./pages/dashboard/accreditation/emails";
import { ManageGroup } from "./pages/accreditation/manage-group";
import { AccreditationIndividual } from "./pages/dashboard/accreditation/individual";
import { FormResponses } from "./pages/dashboard/forms/responses";
import { Flows } from "./pages/dashboard/flows/flows";
import { CreateFlow } from "./pages/dashboard/flows/create";

/*
Colours:
Dark Mode: 
- Primary: bg-gray-900
- Secondary: bg-gray-800

Text:
- Primary: text-white
- Secondary: text-gray-400

Border:
- Primary: border-white/5

*/
export const CartContext = createContext<CartContextType | null>(null);

function App() {
  // const location = useLocation();
  const order = useSelector((state: State) => state.cart.order);

  useEffect(() => {
    // You can customize this logic to vary the title based on the current route
    // For example, use location.pathname to determine the specific title
    if (import.meta.env.VITE_EVENT_ORGANISER_NAME) {
      document.title = `Ticketr - Tickets`;
    }
  }, [location]);

  useEffect(() => {
    console.log(
      "Console Inspector, huh? That's awesome! 😉 This event/festival is powered by Ticketr."
    );
    console.log(
      "While you're here, why not check out our main site? 👉 https://ticketr.events"
    );
  }, []);

  return (
    // <ErrorBoundary fallback={<Maintenance />}>
    <div
      className={`w-full min-h-screen h-full ${
        !order ? "" : ""
      } dark:bg-dark-primary bg-gray-50`}
    >
      <Routes>
        {/* Customer Facing Routes */}
        {/* Organisation View */}
        <Route path="/" element={<Organisation />} />

        {/* Event View */}
        <Route path="/:eventSlug" element={<Event />} />
        <Route path="/:eventSlug/complete" element={<Complete />} />
        <Route path="/:eventSlug/order" element={<Order />} />
        <Route path="/:eventSlug/payment-plan" element={<PaymentPlan />} />
        {/* <Route path="/merchandise" element={<Merchandise />} /> */}

        {/* Other customer facing routes */}
        <Route path="/forms/:formId" element={<Form />} />

        {/* Accreditation routes */}
        <Route path="/:groupId/tickets/claim" element={<ClaimTicket />} />
        <Route path="/:groupId/manage" element={<ManageGroup />} />

        {/* Dashboard Facing Routes */}
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/invite" element={<Invite />} />

        <Route path="/dashboard">
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <DashboardHome />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/orders/:orderId"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <ViewOrder />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/orders/create"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <CreateOrder />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/settings/organisation"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <OrganisationSettings />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/settings/user"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <AccountSettings />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/permission-denied"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <PermissionDeniedPage />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Reporting */}
          <Route
            path="/dashboard/reporting"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <Reporting />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/reporting/velocity"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <VelocityReport />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Accreditation */}
          <Route
            path="/dashboard/accreditation"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <Accreditation />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Parent most page */}
          <Route
            path="/dashboard/accreditation/groups"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <AccreditationGroups />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Individual Group, same page */}
          <Route
            path="/dashboard/accreditation/groups/:groupId"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <AccreditationGroups />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Individual in a group */}
          <Route
            path="/dashboard/accreditation/individuals/:individualId"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <AccreditationIndividual />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/accreditation/emails"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <Emails />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Everything else, send to CRUD Router */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
                  <CRUDRenderer resources={CRUD_RESOURCES} />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/forms/:formId"
            element={
              <ProtectedRoute>
                <CreateForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/forms/:formId/responses"
            element={
              <ProtectedRoute>
                <FormResponses />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/forms/create"
            element={
              <ProtectedRoute>
                <CreateForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/flows"
            element={
              <ProtectedRoute>
                <Flows />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/flows/create"
            element={
              <ProtectedRoute>
                <CreateFlow />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </div>
    // </ErrorBoundary>
  );
}

export default App;
