import { ButtonSpinner } from "@/components/ButtonSpinner";
import { TextField } from "@/components/dashboard/forms/fields/TextField";
import { CommonInput } from "@/components/portal/form/CommonInput";
import { Loading } from "@/components/portal/Loading";
import yup from "@/crud/yup-extended";
import { BASE_URL } from "@/dashboardQueries";
import { Event } from "@/models/Cart";
import { SelectedField } from "@/models/form";
import { Ticket } from "@/models/Tickets";
import { dateToPortalDisplay } from "@/utils/date";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import axios, { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { Field, Formik } from "formik";
import Lottie from "lottie-react";
import {
  ArrowRightIcon,
  CircleCheckBig,
  DownloadIcon,
  MailIcon,
  ShareIcon,
  SquareArrowOutUpRight,
  TicketIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useDarkMode } from "usehooks-ts";
import successAnimation from "../../assets/successAnimation.json";
import QRCode from "react-qr-code";
import { PaymentDetails } from "@/components/PaymentDetails";
import { AutoAllocatePaymentSteps } from "@/components/portal/accreditation/AutoAllocatePaymentSteps";
import { formatNumberToMoney } from "@/utils/cart";
import { PaymentIntent } from "@stripe/stripe-js";

interface AllocateTicketResponse {
  event: Event;
  ticket: Ticket;
  message?: string;
  isRefundableBond: boolean;
  chargeIndividualForTicket?: boolean;
}

interface AllocateTicketClientSecretResponse {
  clientSecret?: string;
}

interface ClaimTicketRequest {
  firstName: string;
  lastName: string;
  email: string;
  paymentIntentId?: string;
}

export const fetchGroupAllocation = async (
  groupId: string
): Promise<AllocateTicketResponse> => {
  const url = `${BASE_URL}/accreditation/groups/${groupId}/auto-allocate-ticket`;
  const { data } = await axios.get(url);
  return data;
};

// Page for auto-allocating/claiming tickets
export const ClaimTicket = () => {
  const navigate = useNavigate();
  const isDarkMode = useDarkMode();
  const { groupId } = useParams<{ groupId: string }>();
  const [claimedTicket, setClaimedTicket] = useState<Ticket | null>(null);
  const [showLottie, setShowLottie] = useState(true);
  const [showQRCode, setShowQRCode] = useState(false);
  const [isPaymentCallbackLoading, setIsPaymentCallbackLoading] =
    useState(false);
  const [clientSecret, setClientSecret] = useState("");

  // for when we need to store the values for the next step
  const [formValues, setFormValues] = useState<ClaimTicketRequest>();

  // For fading in the QR Code
  useEffect(() => {
    console.log("show lottie", showLottie);
    console.log("claimed ticket", claimedTicket);
    if (showLottie && claimedTicket) {
      const timer = setTimeout(() => {
        setShowLottie(false);
        setShowQRCode(true);
      }, 1750); // Duration of the Lottie animation in milliseconds
      return () => clearTimeout(timer);
    }
  }, [showLottie, claimedTicket]);

  // Fetching claim details
  const { data, error, isLoading, isError } = useQuery<AllocateTicketResponse>(
    ["group", groupId],
    () => fetchGroupAllocation(groupId),
    {}
  );

  // For posting the ticket claim
  const claimTicket = useMutation((claimTicket: ClaimTicketRequest) => {
    return axios.post(
      `${BASE_URL}/accreditation/groups/${groupId}/auto-allocate-ticket`,
      claimTicket
    );
  });

  // For getting the client secret if payment is required after they submit the form
  const getClientSecret = (): Promise<
    AxiosResponse<AllocateTicketClientSecretResponse>
  > => {
    return axios.post(
      `${BASE_URL}/accreditation/groups/${groupId}/auto-allocate-ticket/client-secret`
    );
  };

  const onPaymentSuccess = (paymentIntent: PaymentIntent) => {
    setIsPaymentCallbackLoading(true);
    if (paymentIntent.status == "succeeded") {
      // call the backend
      claimTicket.mutate(
        {
          ...formValues,
          paymentIntentId: paymentIntent.id,
        } as ClaimTicketRequest,
        {
          onSuccess: (data: AxiosResponse<AllocateTicketResponse>) => {
            // Handle success (e.g., show a success message, redirect, etc.)
            setClaimedTicket(data.data.ticket);
          },
          onError: (error) => {
            // Handle error (e.g., show an error message)
            console.log("error claiming and paying");
            console.error(error);
          },
        }
      );
    } else {
      // todo: handle error
    }
    setIsPaymentCallbackLoading(false);
  };

  const handlePaymentRequiredFormSubmit = async (
    values: ClaimTicketRequest
  ) => {
    // Set the form values
    setFormValues(values);

    // Get the client Secret
    const clientSecretResponse: AxiosResponse<AllocateTicketClientSecretResponse> =
      await getClientSecret();
    if (clientSecretResponse.data.clientSecret) {
      setClientSecret(clientSecretResponse.data.clientSecret);
    } else {
      console.error("No client secret returned for payment");
    }
  };

  if (error) {
    navigate("/not-found");
  }

  return (
    <div className="dark:bg-dark-primary text-gray-200 font-sans">
      <div className="min-h-screen flex flex-col justify-center items-center px-4">
        {data ? (
          <div>
            <div className="dark:bg-dark-secondary border dark:border-white/5 shadow-sm rounded-lg shadow-lg p-6 max-w-md w-full">
              <div className="text-center mb-6">
                <img
                  src={data.event.logo}
                  alt={`${data.event.name} logo`}
                  className="w-24 h-24 mx-auto rounded-full mb-4"
                />
                <h1 className="text-xl font-semibold">{data.event.name}</h1>
                <p className="text-muted-foreground text-orange-500 text-sm">
                  {dateToPortalDisplay(parseISO(data.event.startDate))}
                </p>
                <p className="text-xs text-gray-400">{data.event.location}</p>
              </div>

              {data.chargeIndividualForTicket && !claimedTicket && (
                <div className="mb-5">
                  <AutoAllocatePaymentSteps isPaymentStep={!!formValues} />
                </div>
              )}

              {data.message ? (
                <div className="text-center">
                  <h1 className="text-2xl font-semibold mb-4">
                    No Tickets Left to Claim
                  </h1>
                  <p className="text-gray-400 mb-6">
                    It looks like all the tickets for this event have been
                    claimed. If you believe this is an error, please contact
                    support.
                  </p>
                  <button
                    type="button"
                    className="relative w-full flex items-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                  >
                    <MailIcon className="w-4 h-4 mr-2 absolute" />
                    <h3 className="flex-1">Contact support</h3>
                  </button>
                  <button
                    type="button"
                    className="mt-3 rounded-md w-full bg-white/10 px-4 py-2 text-sm font-medium text-gray-900 text-white shadow-sm hover:bg-white/20"
                  >
                    <TicketIcon className="w-4 h-4 mr-2 absolute" />
                    <h3 className="flex-1">Return to event</h3>
                  </button>
                </div>
              ) : (
                <>
                  {claimedTicket ? (
                    <div className="flex items-center justify-center">
                      {showLottie && (
                        <div className="text-center">
                          <div className="w-full flex justify-center">
                            <div className="w-36 h-36">
                              <Lottie
                                animationData={successAnimation}
                                loop={false}
                              />
                            </div>
                          </div>
                          <h3 className="mt-4 font-semibold text-xl">
                            Ticket claimed
                          </h3>
                          <p className="text-gray-400 text-sm mt-1.5">
                            You have successfully claimed a{" "}
                            <span className="font-semibold dark:text-white">
                              {claimedTicket.name}
                            </span>{" "}
                            ticket
                          </p>
                        </div>
                      )}
                      {showQRCode && (
                        <div className="fade-in w-full flex items-center justify-center">
                          <div className="w-full">
                            <div className="mt-4 flex items-center justify-center text-center">
                              <div>
                                <div className="flex items-center justify-center">
                                  <QRCode
                                    value={
                                      claimedTicket ? claimedTicket.uuid : ""
                                    }
                                    bgColor={isDarkMode ? "#111827" : "white"}
                                    fgColor={isDarkMode ? "white" : "black"}
                                    className="w-36 h-36"
                                  />
                                </div>
                                <h3 className="mt-4 font-semibold text-xl">
                                  x1 {claimedTicket.name}
                                </h3>
                                <p className="mt-1 text-xs text-gray-500">
                                  <CircleCheckBig className="w-4 h-4 mr-2 text-green-500 inline-block" />
                                  We also sent you an email with your ticket
                                </p>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="mt-6 relative w-full flex items-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                            >
                              <DownloadIcon className="w-4 h-4 mr-2 absolute" />
                              <h3 className="flex-1">Download ticket</h3>
                            </button>
                            <button
                              type="button"
                              className="mt-2 relative w-full flex items-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                            >
                              <ShareIcon className="w-4 h-4 mr-2 absolute" />
                              <h3 className="flex-1">Share ticket</h3>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {formValues && data.chargeIndividualForTicket ? (
                        <div>
                          <div>
                            {data.isRefundableBond && (
                              <div className="rounded-md bg-light-primary dark:bg-dark-primary bg-gray-50 p-4 mb-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <InformationCircleIcon
                                      className="h-5 w-5 text-gray-900 dark:text-white"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-gray-900 dark:text-white">
                                      This is a volunteer bond that will be
                                      refunded depending on your attendance at
                                      the event
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            <p className="text-gray-400 text-sm">
                              You will be charged{" "}
                              {formatNumberToMoney(
                                parseFloat(data.ticket.price)
                              )}{" "}
                              for this ticket
                            </p>
                          </div>
                          {data.event.organisation && (
                            <div className="mt-3">
                              {/* need to override something here to redirect to the complete page once done/pay? */}
                              {isPaymentCallbackLoading ? (
                                <div className="flex items-center justify-center py-10">
                                  <svg
                                    className="h-14 w-14 animate-spin text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      stroke-width="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              ) : (
                                <PaymentDetails
                                  total={parseFloat(data.ticket.price)}
                                  stripeAccountId={
                                    data.event.organisation.accountId
                                  }
                                  clientSecret={clientSecret}
                                  buttonText="Pay and claim ticket"
                                  onPaymentSuccess={onPaymentSuccess}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <p className="text-center mb-6 px-4">
                            You have been invited to claim your
                            <br />{" "}
                            <span className="font-bold">
                              {data.ticket.name}
                            </span>{" "}
                            ticket.
                          </p>
                          <Formik
                            initialValues={{
                              firstName: "",
                              lastName: "",
                              email: "",
                            }}
                            validationSchema={yup.object().shape({
                              firstName: yup.string().required("Required"),
                              lastName: yup.string().required("Required"),
                              email: yup
                                .string()
                                .email("Must be a valid email")
                                .required("Required"),
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                              // If we have a client secret, it requires payment, set values for next step
                              if (data.chargeIndividualForTicket) {
                                handlePaymentRequiredFormSubmit(values);
                                setSubmitting(false);
                              } else {
                                claimTicket.mutate(values, {
                                  onSuccess: () => {
                                    // Handle success (e.g., show a success message, redirect, etc.)
                                    setSubmitting(false);
                                    setClaimedTicket(data.ticket);
                                  },
                                  onError: (error) => {
                                    // Handle error (e.g., show an error message)
                                    console.error(error);
                                    setSubmitting(false);
                                  },
                                });
                              }
                            }}
                          >
                            {({ errors, handleSubmit, isSubmitting }) => (
                              <form className="space-y-4">
                                <div className="flex">
                                  <div className="w-1/2 pr-2">
                                    <CommonInput
                                      name="firstName"
                                      type="text"
                                      label="First Name"
                                    />
                                  </div>
                                  <div className="w-1/2 pl-2">
                                    <CommonInput
                                      name="lastName"
                                      type="text"
                                      label="Last Name"
                                    />
                                  </div>
                                </div>
                                <CommonInput
                                  name="email"
                                  type="Email"
                                  label="Email"
                                  helpText="This is where we will send your ticket"
                                />
                                {/* todo: maybe get the total from the server as it could include fees */}
                                {/* create ticketr account coming soon */}
                                {/* <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="createAccount"
                      className="text-yellow-400 focus:ring-yellow-400 focus:ring-2"
                    />
                    <label
                      for="createAccount"
                      className="ml-2 text-sm text-gray-400"
                    >
                      Create a Ticketr account
                    </label>
                  </div> */}
                                <button
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  disabled={isSubmitting}
                                  className="-mb-1 w-full flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                                >
                                  {isSubmitting ? (
                                    <>
                                      <ButtonSpinner colour="black" /> Claiming
                                      ticket
                                    </>
                                  ) : clientSecret ? (
                                    <>
                                      Next Step{" "}
                                      <ArrowRightIcon className="h-4 w-4 ml-1" />
                                    </>
                                  ) : (
                                    "Claim Ticket"
                                  )}
                                </button>
                              </form>
                            )}
                          </Formik>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            <div className="max-w-md pl-3 pt-4">
              <div className="flex">
                <div className="mr-2 flex items-center justify-center">
                  <svg
                    id="Livello_2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 150.55 209.09"
                    className="w-7 fill-white"
                  >
                    <path d="m110.26,44.92V0h-49.61v47.45H0l19.53,24.22L0,95.89h60.64v48.56c0,21.42,5.62,37.73,16.7,48.49,11.05,10.72,27.99,16.16,50.35,16.16h22.85v-44.8h-22.85c-12.06,0-17.44-5.38-17.44-17.44v-50.97h40.29v-48.44h-40.29v-2.53Z" />
                  </svg>
                </div>
                <div className="ml-3 flex items-center justify-center">
                  <h3 className="text-sm">
                    Powered by{" "}
                    <span
                      onClick={() =>
                        window.open("https://ticketr.events", "_blank")
                      }
                      className="cursor-pointer underline font-semibold text-gray-300"
                    >
                      Ticketr
                      <SquareArrowOutUpRight className="w-3 h-3 inline-block ml-1 -mt-0.5" />
                    </span>
                    . By claiming your ticket, you agree to our{" "}
                    <a href="#" className="underline">
                      Terms
                    </a>{" "}
                    and{" "}
                    <a href="#" className="underline">
                      Privacy Policy
                    </a>
                    .
                  </h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Loading colour="white" />
          </>
        )}
      </div>
    </div>
  );
};
