import { addSearchParam } from "@/utils/url";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";
import { XCircleIcon } from "lucide-react";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  onSearch: (search: string) => void;
}

export const Search = ({ onSearch }: Props) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(() => searchParams.get("search") || "");

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      // handle search stuff
      if (onSearch) {
        onSearch(value);

        // add the search param to the URL
        addSearchParam(searchParams, setSearchParams, "search", value);

        // stop the loading icon once we've finished
        setIsSearching(false);
      }
    }, 750),
    [onSearch]
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSearching(true);
    setSearch(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
    addSearchParam(searchParams, setSearchParams, "search", "");
    if (onSearch) {
      onSearch("");
    }
  };

  // if the page loads with a search param, run the search
  useEffect(() => {
    // get the param
    const search = searchParams.get("search");
    // if we have a search param and a search function, run the search
    if (search && onSearch) {
      setSearch(search);
      onSearch(search);
    }
  }, [searchParams, onSearch]);

  return (
    <div className="border-b dark:border-white/5">
      <div className="my-5 px-8 w-1/2">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative mt-1 rounded-md shadow-sm flex items-center">
          <div
            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            aria-hidden="true"
          >
            {isSearching ? (
              <div
                className="pointer-events-none absolute inset-y-0  flex items-center"
                aria-hidden="true"
              >
                <svg
                  className="animate-spin h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <MagnifyingGlassIcon
                className="h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            )}
          </div>
          <input
            onChange={handleSearchChange}
            type="text"
            name="search"
            id="search"
            value={search}
            className="dark:bg-dark-secondary block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 dark:ring-white/5 placeholder:text-gray-400 dark:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Search"
          />
          {search.length > 0 && (
            <XCircleIcon
              aria-hidden="true"
              onClick={() => handleClearSearch()}
              className="cursor-pointer -ml-7 col-start-1 row-start-1 mr-3 size-5 self-center justify-self-end text-gray-400 sm:size-5"
            />
          )}
        </div>
      </div>
    </div>
  );
};
