import { Dropdown } from "@/crud/form/Dropdown";
import { TicketAllocation } from "@/models/accreditation";
import { getIn, useFormikContext } from "formik";

interface TicketDropdownProps {
  name: string;
  // the tickets allocated to the group
  tickets: Array<TicketAllocation>;
  // the count and IDs of the ones already selected
  ticketCount: Record<number, number>;
}

export const TicketAllocationDropdown = ({
  name,
  tickets,
  ticketCount,
}: TicketDropdownProps) => {
  const { values } = useFormikContext();

  // Get the currently selected tickets for the individual
  const selectedTickets = getIn(values, name) || [];

  console.log("selected tickets are");
  console.log(selectedTickets);

  // Calculate available tickets
  const availableTickets = tickets
    .map((ticket: TicketAllocation) => {
      const pickedCount = ticketCount[ticket.ticket.id] || 0;
      const availableQuantity = ticket.quantity - pickedCount;
      return {
        id: ticket.ticket.id,
        value: ticket.ticket.name,
        availableQuantity,
      };
    })
    .filter(
      (ticket) =>
        ticket.availableQuantity > 0 ||
        selectedTickets.some((selected) => selected.id === ticket.id)
    );

  // Include the currently selected tickets in the available options
  const options = availableTickets.map((ticket) => ({
    id: ticket.id,
    value: ticket.value,
  }));

  return <Dropdown name={name} options={options} multiple isUpdating />;
};
