import { Menu } from "@headlessui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { EMAIL_KEY, signOut } from "../../../../pages/protected-route";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { AccountMenuContent } from "./AccountMenuContent";
import { ChevronsUpDown } from "lucide-react";

export const AccountMenu = () => {
  return (
    <div className="w-full pl-2">
      <Menu>
        <Menu.Items className="w-full dark:bg-dark-secondary bg-light-secondary !outline-none border dark:border-white/5 rounded-md shadow-lg">
          <AccountMenuContent />
        </Menu.Items>
        <Menu.Button className="!outline-none w-full ">
          <div
            // onClick={() => signOut()}
            className="flex items-center gap-x-4 px-3 py-3 text-sm font-semibold leading-6 text-gray-600 dark:text-white dark:hover:text-gray-400"
          >
            <div className="max-w-md rounded-lg shadow-md dark:bg-dark-secondary bg-white ring-1 ring-black/5 p-1.5 -mr-1">
              <UserCircleIcon className="h-6 w-6 dark:text-white" />
            </div>
            <div className="-mt-0.5 flex items-center">
              <div>
                <p aria-hidden="true" className="truncate max-w-xs text-xs">
                  {localStorage.getItem(EMAIL_KEY)}
                </p>
                <div className="flex items-center text-xs font-light">
                  <p aria-hidden="true">View Settings</p>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-end">
              <ChevronsUpDown className="h-5 w-5 dark:text-white" />
            </div>
          </div>
        </Menu.Button>
      </Menu>
    </div>
  );
};
