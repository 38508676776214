import { Error } from "@/components/Error";
import { Input } from "@/crud/form/Input";
import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FieldArray, useFormikContext } from "formik";
import { PlusIcon } from "lucide-react";

interface Props {
  fieldIndex: number;
}

export const AddChoiceOptions = ({ fieldIndex }: Props) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<any>();

  return (
    <div className="mt-4">
      <p className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mb-3">
        Choices
      </p>

      {errors.fields && (
        <>
          {errors.fields[fieldIndex] && errors.fields[fieldIndex].options && (
            <div className="mt-1 mb-3 text-red-500 text-sm flex items-center border border-red-500 rounded-md p-1.5">
              <ExclamationCircleIcon className="h-5 w-5 mr-1" />
              <p>{errors.fields[fieldIndex].options}</p>
            </div>
          )}
        </>
      )}

      <FieldArray name={`fields.${fieldIndex}.options`}>
        {({ insert, remove, push }) => (
          <div className="mt-2">
            {values.fields[fieldIndex]?.options?.length > 0 ? (
              <>
                {values.fields[fieldIndex].options.map(
                  (option: string, index: number) => (
                    <div className="pb-2" key={index}>
                      <div className="flex">
                        <div className="dark:bg-white w-10 rounded-md border mr-2 flex items-center justify-center">
                          <h3 className="dark:text-dark-primary -mt-0.5">
                            {index + 1}
                          </h3>
                        </div>
                        <div className="flex-1">
                          <Input
                            required
                            type="text"
                            name={`fields.${fieldIndex}.options.${index}`}
                            value={option}
                            surpressError={true}
                            onChange={handleChange}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="dark:bg-white w-10 rounded-md border ml-2 flex items-center justify-center"
                        >
                          <TrashIcon className="h-5 w-5 dark:text-dark-primary" />
                        </button>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <div className="-mt-0.5">
                <p className="dark:text-gray-400 text-xs">No choices yet</p>
              </div>
            )}

            <button
              type="button"
              onClick={() => {
                console.log("Add Option");
                console.log(values);
                push("");
              }}
              className="mt-2 dark:text-white border border-white/10 hover:bg-white/5 pr-3 pl-1.5 py-1 rounded-md text-xs flex"
            >
              <PlusIcon className="h-4 w-4 mr-1" />
              Add choice
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};
