import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { FORM_FIELDS } from "../../../constants/form";
import { SelectedField } from "../../../models/form";
import { useFormStore } from "../../../store/form";
import { FormField } from "./FormField";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import { useEffect, useState } from "react";
import {
  useFormikContext,
  FieldArray,
  getIn,
  FieldArrayRenderProps,
} from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import {
  Ellipsis,
  EllipsisVertical,
  EllipsisVerticalIcon,
  TrashIcon,
} from "lucide-react";
import { Menu } from "@headlessui/react";
import { set } from "lodash";

export const FormFieldList = () => {
  const { setActiveField } = useFormStore();
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const [fieldBeingDragged, setFieldBeingDragged] =
    useState<SelectedField | null>();
  const [feildSettingsMenuClicked, setFeildSettingsMenuClicked] = useState<
    number | null
  >(null);

  const SortableFormField = ({
    selectedField,
    remove,
  }: {
    selectedField: SelectedField;
    remove: () => void;
  }) => {
    const {
      listeners,
      attributes,
      setNodeRef,
      transition,
      transform,
      active,
      isSorting,
      index,
      over,
    } = useSortable({ id: selectedField.id });

    const fieldError = getIn(errors, `fields.${index}`);

    return (
      <div className="relative group">
        <div
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          style={{
            transition,
            transform: transform
              ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
              : undefined,
          }}
          className="relative z-0"
        >
          <FormField
            id={selectedField.id}
            name={selectedField.name}
            field={selectedField.field}
            category={selectedField.category}
          />
          {fieldError && (
            <ExclamationCircleIcon
              className={`absolute top-1/2 right-0 transform -translate-y-1/2 h-5 w-5 text-red-500 ${
                values.fields.length > 1 ? "mr-8" : "mr-2"
              }`}
              aria-hidden="true"
            />
          )}
        </div>

        {/* only show the ellipsis and menu bar if there are more than one field */}
        {values.fields.length > 1 && (
          <Menu
            as="div"
            className="absolute right-0 -translate-y-[150%] mr-7 transform translate-x-[100%] inline-block text-left z-20 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <div>
              <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
              </Menu.Button>
            </div>

            <Menu.Items className="translate-x-[100%] top-0 absolute right-0 z-40 -mr-4 -mt-3 w-48 origin-top-left bg-white dark:bg-zinc-800 rounded-md py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
              <Menu.Item>
                <button
                  onClick={() => remove()}
                  type="button"
                  className="flex items-center block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none dark:text-white"
                >
                  <TrashIcon className="h-4 w-4 mr-2" />
                  Delete
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        )}
      </div>
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over) {
      const oldIndex = values.fields.findIndex(
        (field: SelectedField) => field.id === active.id
      );
      const newIndex = values.fields.findIndex(
        (field: SelectedField) => field.id === over.id
      );

      if (oldIndex !== newIndex) {
        const updatedFields = [...values.fields];
        const [movedField] = updatedFields.splice(oldIndex, 1);
        updatedFields.splice(newIndex, 0, movedField);

        setFieldValue("fields", updatedFields);
      }
    }
    setFieldBeingDragged(null);
  };

  return (
    <>
      <DndContext
        onDragStart={(event: DragStartEvent) => {
          console.log("is here mang");
          const id = event.active.id;
          const selectedField = values.fields.find(
            (field: SelectedField) => field.id === id
          );
          if (selectedField) {
            setActiveField(selectedField);
            setFieldBeingDragged(selectedField);
          }
        }}
        onDragEnd={handleDragEnd}
      >
        <FieldArray name="fields">
          {({ remove }: FieldArrayRenderProps) => (
            <SortableContext items={values.fields}>
              {values.fields.map(
                (selectedField: SelectedField, index: number) => (
                  <SortableFormField
                    remove={() => {
                      // remove from the array list
                      remove(index);
                      // if there are still fields, set the active field to be the one previous to this index
                      if (values.fields.length > 1) {
                        setActiveField(values.fields[index - 1]);
                      }
                    }}
                    key={selectedField.id}
                    selectedField={selectedField}
                  />
                )
              )}
            </SortableContext>
          )}
        </FieldArray>

        {fieldBeingDragged && (
          <DragOverlay>
            <div>
              <FormField
                field={fieldBeingDragged.field}
                category={fieldBeingDragged.category}
                onClick={() => alert("hey")}
              />
            </div>
          </DragOverlay>
        )}
      </DndContext>
    </>
  );
};
