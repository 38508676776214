import { classNames } from "@/utils/styles";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FilesIcon, TicketIcon } from "lucide-react";
import { useSearchParams } from "react-router-dom";

interface Props {
  canUploadDocuments: boolean;
}

export const ManageGroupNav = ({ canUploadDocuments }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onTabChange = (tab: string) => {
    setSearchParams(tab ? { tab } : {});
  };

  const tabs = [
    {
      name: "Ticket Allocation",
      href: "",
      current: true,
      icon: TicketIcon,
    },
  ];

  if (canUploadDocuments) {
    tabs.push({
      name: "Documents",
      href: "documents",
      current: false,
      icon: FilesIcon,
    });
  }

  const currentTab = searchParams.get("tab");

  return (
    <div className="mt-4">
      <div>
        <div className="grid grid-cols-1 sm:hidden">
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            defaultValue={tabs.find((tab) => tab.current).name}
            aria-label="Select a tab"
            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
          <ChevronDownIcon
            aria-hidden="true"
            className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
          />
        </div>
        <div
          className="hidden sm:block
	"
        >
          <nav aria-label="Tabs" className="flex space-x-4">
            {tabs.map((tab) => (
              <button
                type="button"
                key={tab.name}
                onClick={() => onTabChange(tab.href)}
                aria-current={tab.current ? "page" : undefined}
                className={classNames(
                  (!currentTab ? tab.href === "" : currentTab === tab.href)
                    ? "bg-gray-100 text-gray-700"
                    : "text-gray-500 hover:text-gray-700",
                  "flex items-center rounded-md px-2 py-1 text-sm font-medium"
                )}
              >
                <tab.icon className="w-5 h-5 mr-2" />
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {/* <button
                            type="button"
                            className="flex items-center rounded-md bg-white/10 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                          >
                            <MailIcon className="h-5 w-5 mr-2" />
                            Send emails to all
                          </button> */}
    </div>
  );
};
