"use client";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function ManageGroupHelpDrawer() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex justify-end mb-2">
        <p
          className="text-sm text-gray-400 text-sm flex items-center cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <QuestionMarkCircleIcon className="h-4 w-4 inline mr-1" /> Need help?
        </p>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog open={open} onClose={setOpen} className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-300 sm:duration-500"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300 sm:duration-500"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Backdrop className="fixed inset-0 bg-gray-500/75 dark:bg-black/80 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300 sm:duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-dark-secondary py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold text-gray-900 dark:text-white">
                            Help with
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              onClick={() => setOpen(false)}
                              className="relative rounded-md bg-white dark:bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                aria-hidden="true"
                                className="size-6"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 text-gray-400">
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Mus lobortis non molestie semper aliquam habitasse
                          himenaeos. Imperdiet habitasse interdum imperdiet
                          fames nec sem aliquet tincidunt quam. Dictum litora
                          est, aenean lobortis adipiscing habitant. Molestie
                          sociosqu gravida proin lacinia metus interdum. Et
                          laoreet augue ut sagittis id est. Integer morbi
                          interdum natoque porta senectus malesuada risus.
                          Ornare morbi metus cubilia iaculis amet cubilia
                          consectetur bibendum. Quisque venenatis congue ligula;
                          nunc aliquam faucibus.{" "}
                        </p>
                        <br />
                        <p>
                          Nisl mauris at vehicula bibendum id diam dictumst sem.
                          Dictum erat eros eget potenti mattis mus tincidunt
                          litora. Et mauris in ultrices orci dapibus, iaculis
                          ante adipiscing? Cursus nunc bibendum suspendisse
                          ridiculus mus fringilla magna ex. Cursus ultricies
                          montes hendrerit curabitur pellentesque. Sit molestie
                          aliquet taciti fringilla nibh eu praesent. Suspendisse
                          dictum quisque quisque aptent facilisi class sed eu
                          in.
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
