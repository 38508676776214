import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { FileIcon, UploadIcon } from "lucide-react";
import { BASE_URL } from "@/dashboardQueries";

interface Props {
  groupId: string;
  readOnly?: boolean;
}

export const Documents = ({ groupId, readOnly }: Props) => {
  const url = `${BASE_URL}/accreditation/groups/${groupId}/documents`;

  const fetchDocuments = async () => {
    const { data } = await axios.get(url);
    return data;
  };

  const uploadDocuments = async (formData: FormData) => {
    const { data } = await axios.post(url, formData);
    return data;
  };

  const deleteDocument = async (name: number) => {
    const { data } = await axios.delete(url, { data: { name } });
    return data;
  };

  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery("documents", fetchDocuments);
  const [files, setFiles] = useState<File[]>([]);

  const uploadMutation = useMutation(uploadDocuments, {
    onSuccess: () => {
      queryClient.invalidateQueries("documents");
    },
  });

  const deleteMutation = useMutation(deleteDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries("documents");
    },
  });

  const onDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("documents", file);
    });
    uploadMutation.mutate(formData);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleViewDocument = async (name: string) => {
    const { data } = await axios.post(`${url}/presigned-url`, { name });
    window.open(data.url, "_blank");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading documents</div>;
  }

  return (
    <div>
      {data.length === 0 ? (
        <>
          {readOnly ? (
            <div className="mt-3 h-40 border dark:border-white/10 border-dashed rounded-md flex items-center justify-center px-4">
              <p className="dark:text-gray-400 text-sm text-center">
                There are no documents associated with this group
              </p>
            </div>
          ) : (
            <div
              {...getRootProps()}
              className="h-32 flex items-center justify-center border border-dashed border-white/10 rounded-lg p-4 text-center"
            >
              <div className="text-center">
                <input {...getInputProps()} />
                <div className="flex items-center justify-center">
                  <UploadIcon className="h-8 w-8 mb-2" />
                </div>
                <p className="text-sm text-white/40">
                  Drag & drop some files here, or click to select files
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>
          {!readOnly && (
            <div className="absolute right-0 -mt-14">
              <button
                type="button"
                onClick={() => document.getElementById("fileInput").click()}
                className="flex rounded-md bg-white/10 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
              >
                <UploadIcon className="h-5 w-5 mr-2" />
                Upload Documents
              </button>
            </div>
          )}

          <input
            id="fileInput"
            type="file"
            multiple
            onChange={(e) => onDrop(Array.from(e.target.files))}
            className="hidden"
          />
          <table className="min-w-full divide-y divide-gray-300 dark:divide-white/10">
            <thead>
              <tr className="divide-x border-t dark:border-white/10 divide-gray-200 dark:divide-white/10 text-gray-900 dark:text-gray-500">
                <th
                  scope="col"
                  className="py-2 pr-4 text-left text-sm font-medium w-28"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="py-2 pl-4 pr-4 text-left text-sm sm:pr-0"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:divide-white/10 dark:bg-transparent">
              {data.map((document) => (
                <tr
                  key={document.id}
                  className="divide-x divide-gray-200 dark:divide-white/10 dark:text-gray-200 text-gray-500"
                >
                  <td className="py-2 dark:text-gray-200 text-sm text-gray-500 hover:dark:bg-zinc-800/40 w-5/6">
                    <div className="flex items-center">
                      <FileIcon className="h-5 w-5 mr-1" />
                      <p>{document.name}</p>
                    </div>
                  </td>
                  <td className="py-2 dark:text-gray-200 text-gray-500 hover:dark:bg-zinc-800/40 pl-3">
                    <div>
                      <span
                        onClick={() => deleteMutation.mutate(document.name)}
                        className="mr-1.5 cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </span>
                      <span
                        onClick={() => handleViewDocument(document.name)}
                        className="cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
                      >
                        <EyeIcon className="h-4 w-4" />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
