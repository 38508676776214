import { AddRemoveFromGroup } from "@/components/dashboard/flows/actions/AddRemoveFromGroup";
import { CompletesAForm } from "@/components/dashboard/flows/actions/CompletesAForm";
import { IssueTicket } from "@/components/dashboard/flows/actions/IssueTicket";
import { Action } from "@/models/flows";
import {
  Box,
  CircleCheck,
  DollarSign,
  PackageMinus,
  PackagePlus,
  Send,
  Split,
  Ticket,
} from "lucide-react";

// Actions that start the flow
export const STARTER_ACTIONS: Array<Action> = [
  {
    label: "When individual completes form",
    type: "completesForm",
    icon: Send,
    colour: "yellow",
    component: <CompletesAForm />,
  },
];

// Regular actions. Grouped by Category
export const ACTIONS: Record<string, Array<Action>> = {
  Accreditation: [
    {
      label: "Add to group",
      type: "addToGroup",
      icon: PackagePlus,
      colour: "blue",
      component: <AddRemoveFromGroup type="Add" />,
    },
    {
      label: "Remove from group",
      type: "removeFromGroup",
      icon: PackageMinus,
      colour: "blue",
      component: <AddRemoveFromGroup type="Remove" />,
    },
    {
      label: "Create group",
      type: "createGroup",
      icon: Box,
      colour: "blue",
      component: <div>create group</div>,
    },
    {
      label: "Issue ticket",
      type: "issueTicket",
      icon: Ticket,
      colour: "blue",
      component: <IssueTicket />,
    },
  ],
  Payment: [
    {
      label: "Generate invoice",
      type: "generateInvoice",
      icon: DollarSign,
      colour: "green",
      component: <div>generate invoice</div>,
    },
  ],
  General: [
    {
      label: "Send email",
      type: "sendEmail",
      icon: Send,
      colour: "purple",
      component: <div>send email</div>,
    },
    // manaul approve/reject
    {
      label: "Require approval",
      type: "requireApproval",
      icon: CircleCheck,
      colour: "purple",
      component: <div>require approval</div>,
    },
    // something to power an automatic decision
    {
      label: "Decision",
      type: "decision",
      icon: Split,
      colour: "purple",
      component: <div>decision</div>,
    },
  ],
};
