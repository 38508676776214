import { AxiosResponse } from "axios";
import { AvailableFilter, CRUDResource } from "./crud/models";
import {
  getAllGroups,
  getAllTickets,
  getPermissions,
} from "./dashboardQueries";
import {
  DashboardTicket,
  DropdownOption,
  Group,
  Permission,
  User,
} from "./models/Dashboard";
import yup from "./crud/yup-extended";
import { format, parseISO } from "date-fns";
import {
  ArrowPathIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  EnvelopeIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import {
  ArrowRightEndOnRectangleIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ClockIcon,
  EnvelopeOpenIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Field, useFormik, useFormikContext } from "formik";
import { toHeaderCase, toPascalCase } from "js-convert-case";
import { TruncatedBadges } from "./components/dashboard/TruncatedBadges";
import { useEffect, useRef, useState } from "react";
import { UserStatus } from "./constants/auth";
import { EMAIL_KEY, ORGANISATION_KEY } from "./pages/protected-route";
import { store } from ".";
import { history } from "./index";
import { Copy, SquareArrowOutUpRight } from "lucide-react";
import { getFormUrl } from "./utils/forms";
import { TicketPriceField } from "./crud/form/TicketPriceField";
import { DateTimeRangeField } from "./crud/form/DateTimeRangeField";
import { NumberOfMonthsField } from "./crud/form/NumberOfMonthsField";
import { TicketQuantityAvailableField } from "./crud/form/TicketQuantityAvailableField";
import { OrganisationAdmin } from "./crud/form/OrganisationAdmin";
import { FormRowButtons } from "./components/dashboard/forms/FormRowButtons";

async function getTicketsValidationSchema() {
  const tickets: AxiosResponse<Array<DashboardTicket>> = await getAllTickets();
  const ticketOptions = tickets.data
    ? tickets.data.map((ticket: DashboardTicket) => {
        return { id: ticket.id, value: ticket.name } as const;
      })
    : [];

  return yup.object({
    name: yup.string().max(64).required("Required"),
    description: yup.string().nullable(),
    ticketsAvailableWhen: yup.mixed().oneOfByValue([
      { id: "DATE_RANGE", value: "Date & time" },
      { id: "WHEN_PREVIOUS_TICKET_SOLD_OUT", value: "When sales end for..." },
    ]),
    availableDate: createCustomComponentSchema({
      type: DateTimeRangeField,
      props: {},
    }).meta({
      displayIf: {
        field: "ticketsAvailableWhen",
        value: { id: "DATE_RANGE", value: "Date & time" },
      },
    }),
    // Move to one row
    // onSaleFrom: yup
    //   .date()
    //   .required("Required")
    //   .meta({
    //     displayIf: {
    //       field: "ticketsAvailableWhen",
    //       value: { id: "DATE_RANGE", value: "Date & time" },
    //     },
    //   }),
    // onSaleTo: yup
    //   .date()
    //   .required("Required")
    //   .meta({
    //     displayIf: {
    //       field: "ticketsAvailableWhen",
    //       value: { id: "DATE_RANGE", value: "Date & time" },
    //     },
    //   }),
    previousTicketSoldOut: yup
      .mixed()
      .oneOfByValue(ticketOptions)
      .when("ticketsAvailableWhen", {
        is: {
          id: "WHEN_PREVIOUS_TICKET_SOLD_OUT",
          value: "When sales end for...",
        },
        then: (schema) => yup.mixed().oneOfByValue(ticketOptions).required(),
        otherwise: (schema) =>
          yup.mixed().oneOfByValue(ticketOptions).nullable(),
      })
      .meta({
        displayIf: {
          field: "ticketsAvailableWhen",
          value: {
            id: "WHEN_PREVIOUS_TICKET_SOLD_OUT",
            value: "When sales end for...",
          },
        },
      }),
    price: createCustomComponentSchema({
      type: TicketPriceField,
      props: {
        /* initial component props */
        name: "price",
      },
    }),
    quantityAvailable: createCustomComponentSchema({
      type: TicketQuantityAvailableField,
      props: {
        name: "quantityAvailable",
      },
    }),
    ticketType: yup.mixed().oneOfByValue([
      { id: "ONCE_OFF", value: "Once Off" },
      { id: "PAYMENT_PLAN", value: "Payment Plan" },
    ]),
    numberOfInstalments: createCustomComponentSchema({
      type: NumberOfMonthsField,
      props: {
        name: "numberOfMonths",
      },
    }).meta({
      displayIf: {
        field: "ticketType",
        value: { id: "PAYMENT_PLAN", value: "Payment Plan" },
      },
    }),
    // numberOfMonths: yup
    //   .number()
    //   .when("ticketType", {
    //     is: { id: "PAYMENT_PLAN", value: "Payment Plan" },
    //     then: (schema) => yup.number().min(0).required(),
    //     otherwise: (schema) => yup.number().nullable(),
    //   })
    //   .meta({
    //     displayIf: {
    //       field: "ticketType",
    //       value: { id: "PAYMENT_PLAN", value: "Payment Plan" },
    //     },
    //   }),
    active: yup.boolean(),
    hidden: yup.boolean(),
    isFree: yup.boolean(),
  });
}

async function getPromoCodesValidationSchema() {
  const tickets: AxiosResponse<Array<DashboardTicket>> = await getAllTickets();
  const ticketOptions = tickets.data.map((ticket: DashboardTicket) => {
    return { id: ticket.id, value: ticket.name } as const;
  });
  const codeDisplayIf = {
    displayIf: {
      field: "codeType",
      value: { id: "DISCOUNT", value: "Discount" },
    },
  };
  const ifDiscountCodeNumberSchema = (required = false) =>
    yup
      .number()
      .when("codeType", {
        is: { id: "DISCOUNT", value: "Discount" },
        then: (schema) => yup.number().min(0).required("Required"),
        otherwise: (schema) => yup.number(),
      })
      .meta(codeDisplayIf);

  return yup.object({
    name: yup.string().max(64).required("Required"),
    codeType: yup.mixed().oneOfByValue([
      { id: "DISCOUNT", value: "Discount" },
      { id: "REVEAL", value: "Reveal" },
    ]),
    discountType: yup
      .mixed()
      // discountType is one of
      .oneOfByValue([
        { id: "AMOUNT", value: "Amount" },
        { id: "PERCENTAGE", value: "Percentage" },
      ])
      // regular display, if promo code is a discount
      .meta(codeDisplayIf),
    amount: yup
      .number()
      .money()
      .when("discountType", {
        is: { id: "AMOUNT", value: "Amount" },
        then: (schema) => yup.number().min(0).required("Required"),
        otherwise: (schema) => yup.number().nullable(),
      })
      // display it if the discounType is set to amount
      .meta({
        displayIf: [
          {
            field: "codeType",
            value: { id: "DISCOUNT", value: "Discount" },
          },
          {
            field: "discountType",
            value: { id: "AMOUNT", value: "Amount" },
          },
        ],
      }),
    percentage: yup
      .number()
      .when("discountType", {
        is: { id: "PERCENTAGE", value: "Percentage" },
        then: (schema) => yup.number().min(0).required("Required"),
        otherwise: (schema) => yup.number().nullable(),
      })
      // display it if the discounType is set to percentage
      .meta({
        displayIf: [
          {
            field: "codeType",
            value: { id: "DISCOUNT", value: "Discount" },
          },
          {
            field: "discountType",
            value: { id: "PERCENTAGE", value: "Percentage" },
          },
        ],
      }),
    ticket: yup.mixed().oneOfByValue(ticketOptions).required("Required"),
    validFrom: yup.date().required("Required"),
    validUntil: yup.date().required("Required"),
    minimumCartValue: yup
      .number()
      .money()
      .when("codeType", {
        is: { id: "DISCOUNT", value: "Discount" },
        then: (schema) => yup.number().min(0).required("Required"),
        otherwise: (schema) => yup.number(),
      })
      .meta(codeDisplayIf),
    applyToEachItemInCart: yup.boolean(),
    maxNumberOfUses: yup.number().min(0).nullable().meta({
      helpText: "The number of times this promo code can be used",
      trailingAddon: "times",
    }),
  });
}

async function getEmailValidationSchema() {
  return yup.object({
    subject: yup.string().required("Required"),
    sentTo: yup
      .mixed()
      .oneOfByValue([{ id: "ALL_ATTENDEES", value: "All Attendees" }])
      .required("Required"),
    body: yup.string().required("Required"),
  });
}

async function getOrderValidationSchema() {
  return yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    emailAddress: yup.string().required("Required"),
    sendConfirmationToAttendee: yup.boolean(),
  });
}

async function getAttendeeValidationSchema() {
  return yup.object({
    status: yup.boolean(),
    checkedIn: yup.boolean(),
    checkedInAt: yup.date(),
  });
}

async function getOrderFilters(): Promise<Array<AvailableFilter>> {
  const tickets: AxiosResponse<Array<DashboardTicket>> = await getAllTickets();
  const ticketOptions = tickets.data.map((ticket: DashboardTicket) => {
    return { id: ticket.id, value: ticket.name } as const;
  });

  return [
    {
      name: "Ticket",
      type: "select",
      options: ticketOptions,
    },
  ];
}

interface ReactComponentMetadata {
  type: React.ElementType<any>;
  props?: Record<string, any>;
}

// This could be a function that returns a yup schema extended with new functionality
function createCustomComponentSchema(metadata: ReactComponentMetadata) {
  const schema = yup
    .mixed()
    .test(
      "custom-component",
      "Custom component validation message",
      (value) => {
        // Custom validation logic can go here
        return true; // Example: always valid
      }
    );

  // Attach the React component metadata to the schema
  (schema as any).reactComponentMetadata = metadata;

  schema.meta = function (meta: Record<string, any>) {
    (schema as any)._meta = meta;
    return schema;
  };

  return schema;
}

interface MyCustomComponentProps {
  hideOnUpdate?: boolean;
}

export const GeneratePasswordComponent = ({ hideOnUpdate }: any) => {
  const formik = useFormikContext();

  const generatePassword = (length: number = 12): string => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const passwordRef = useRef<string>(generatePassword());

  useEffect(() => {
    formik.setFieldValue("password", passwordRef.current);
  }, []);

  return (
    <div className="rounded-md bg-yellow-400/10 p-4 ring-yellow-400/20">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-5 w-5 text-yellow-500"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-500">
            Generated password below
          </h3>
          <div className="mt-2 text-sm text-yellow-500">
            <p>
              We have generated a temporary password for this user below. Give
              it to them when they are signing in for the first time. They will
              be able to change their password after they login.
            </p>

            <p className="font-bold mt-2">
              Once you save this user, you will not be able to see their
              password again.
            </p>

            <Field
              value={passwordRef.current}
              type="text"
              name="password"
              id="password"
              disabled={true}
              required={true}
              className="mt-2 dark:ring-white/5 ring-gray-300 block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 -ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

async function getAddonValidationSchema() {
  const tickets: AxiosResponse<Array<DashboardTicket>> = await getAllTickets();
  const ticketOptions = tickets.data
    ? tickets.data.map((ticket: DashboardTicket) => {
        return { id: ticket.id, value: ticket.name } as const;
      })
    : [];

  const min = 1;
  return yup.object({
    name: yup.string().required("Required"),
    price: yup.number().money().required("Required"),
    description: yup.string().nullable(),
    quantityAvailable: yup.number().min(1).required("Required"),
    onSaleFrom: yup.date().required("Required"),
    onSaleTo: yup.date().required("Required"),
    tickets: yup
      .mixed()
      .manyOfByValue(ticketOptions, min, `Select at least ${min} ticket`),
    // myCustomField: createCustomComponentSchema({
    //   type: MyCustomComponent,
    //   props: {
    //     /* initial component props */
    //   },
    // }),
  });
}

async function getMerchandiseCategoryValidationSchema() {
  return yup.object({
    name: yup.string().required("Required"),
    active: yup.boolean(),
  });
}

// Function to get permissions in a formatted way
async function getAvailablePermissions() {
  const permissions: AxiosResponse<Array<Permission>> = await getPermissions();
  return permissions.data
    ? permissions.data.map((permission: Permission) => {
        const valueWithoutCan = permission.name.replace(/^Can\s+/i, "");
        return { id: permission.id, value: valueWithoutCan } as const;
      })
    : [];
}

async function getGroups() {
  const groups: AxiosResponse<Array<Group>> = await getAllGroups();
  return groups.data
    ? groups.data.map((group: Group) => {
        return { id: group.id, value: group.name } as const;
      })
    : [];
}

async function getUserValidationSchema() {
  const permissions = await getAvailablePermissions();
  const groups = await getGroups();
  return yup.object({
    // firstName: yup.string().required("Required"),
    // lastName: yup.string().required("Required"),
    email: yup.string().email().required("Required"),
    organisationAdmin: createCustomComponentSchema({
      type: OrganisationAdmin,
      props: {},
    }),
    // isSuperuser: yup.boolean(),
    permissions: yup.mixed().manyOfByValue(permissions),
    groups: yup.mixed().manyOfByValue(groups),
    // password: createCustomComponentSchema({
    //   type: GeneratePasswordComponent,
    //   props: {
    //     /* initial component props */
    //     hideOnUpdate: true,
    //   },
    // }),
  });
}

async function getGroupValidationSchema() {
  const permissions = await getAvailablePermissions();
  return yup.object({
    name: yup.string().required("Required"),
    permissions: yup.mixed().manyOfByValue(permissions),
  });
}

export const CRUD_RESOURCES: Array<CRUDResource<any, any>> = [
  {
    name: "Tickets",
    permissionPrefix: "ticket",
    validationSchema: getTicketsValidationSchema,
    headers: ["Name", "Price", "Quantity"],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
            <div className="items-center lg:pl-2">
              <div className="truncate dark:text-white">
                <span>{record.name}</span>
              </div>
              <div className="flex items-center mt-0.5">
                {record.isTicketOnSale ? (
                  <>
                    <div className="mr-2 relative">
                      <div className="h-3 w-3 text-green-400 bg-green-500/10 rounded-full animate-ping"></div>
                      <div className="absolute h-2 w-2 rounded-full bg-green-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                    </div>
                    <span className="font-normal text-gray-500">
                      On sale until{" "}
                      <>{format(new Date(record.onSaleTo), "dd LLL yyyy")}</>
                    </span>
                  </>
                ) : (
                  <>
                    <div className="mr-2 relative">
                      <div className="h-3 w-3 text-green-400 bg-red-500/10 rounded-full"></div>
                      <div className="absolute h-2 w-2 rounded-full bg-red-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                    </div>
                    <span className="font-normal text-gray-500">
                      Not on sale
                    </span>
                  </>
                )}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                &euro;{record.price}
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500 md:table-cell">
            {record.quantitySold}/{record.quantityAvailable}
          </td>
        </>
      );
    },
  },
  {
    name: "Addons",
    permissionPrefix: "addon",
    validationSchema: getAddonValidationSchema,
    headers: ["Name", "Price", "Quantity"],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
            <div className="items-center lg:pl-2">
              <div className="truncate dark:text-white">
                <span>{record.name}</span>
              </div>
              <div className="flex items-center mt-0.5">
                {!record.isSoldOut ? (
                  <>
                    <div className="mr-2 relative">
                      <div className="h-3 w-3 text-green-400 bg-green-500/10 rounded-full animate-ping"></div>
                      <div className="absolute h-2 w-2 rounded-full bg-green-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                    </div>
                    <span className="font-normal text-gray-500">
                      On sale until{" "}
                      <>{format(new Date(record.onSaleTo), "dd LLL yyyy")}</>
                    </span>
                  </>
                ) : (
                  <>
                    <div className="mr-2 relative">
                      <div className="h-3 w-3 text-green-400 bg-red-500/10 rounded-full"></div>
                      <div className="absolute h-2 w-2 rounded-full bg-red-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                    </div>
                    <span className="font-normal text-gray-500">Sold out</span>
                  </>
                )}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                &euro;{record.price}
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500 md:table-cell">
            {record.quantitySold}/{record.quantityAvailable}
          </td>
        </>
      );
    },
  },
  {
    name: "MerchandiseCategories",
    permissionPrefix: "notImplemented",
    validationSchema: getMerchandiseCategoryValidationSchema,
    headers: ["Name", "", ""],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
            <div className="items-center lg:pl-2 flex">
              <div className="truncate dark:text-white">
                <span>{record.name}</span>
              </div>
              <button
                // onClick={() => setRecordToDelete(record)}
                type="button"
                className="ml-4 inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/20"
              >
                View Products
              </button>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                <div className="flex items-center mt-0.5 mr-10">
                  {record.active ? (
                    <>
                      <div className="mr-2 relative">
                        <div className="h-3 w-3 text-green-400 bg-green-500/10 rounded-full animate-ping"></div>
                        <div className="absolute h-2 w-2 rounded-full bg-green-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                      </div>
                      <span className="font-normal text-gray-500">Active</span>
                    </>
                  ) : (
                    <>
                      <div className="mr-2 relative">
                        <div className="h-3 w-3 text-green-400 bg-red-500/10 rounded-full"></div>
                        <div className="absolute h-2 w-2 rounded-full bg-red-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                      </div>
                      <span className="font-normal text-gray-500">
                        Inactive
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </td>
        </>
      );
    },
  },
  {
    name: "Orders",
    permissionPrefix: "order",
    validationSchema: getOrderValidationSchema,
    headers: ["Buyer", "Total", "Order"],
    createPage: "/dashboard/orders/create",
    getFilters: getOrderFilters,
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="py-5 pr-6 sm:table-cell pl-8">
            <div className="text-sm leading-6 text-gray-900 dark:text-white">
              {record.firstName} {record.lastName}
            </div>
            <div className="mt-1 text-xs leading-5 text-gray-500 flex items-center dark:text-gray-400">
              {record.monthlyTotal ? (
                <ArrowPathIcon className="w-3.5 h-3.5 mr-1.5" />
              ) : (
                <CheckIcon className="w-3.5 h-3.5 mr-1.5" />
              )}
              {record.monthlyTotal ? "Payment Plan" : "Once Off"}
            </div>
          </td>
          <td className="relative py-5 pl-6">
            <div className="flex gap-x-6">
              <div className="flex-auto">
                <div className="flex items-start gap-x-3">
                  <div className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                    &euro;{record.total}
                  </div>
                </div>
                <div className="mt-1 text-xs leading-5 text-gray-500 dark:text-gray-400">
                  {format(new Date(record.createdAt), "dd LLL yyyy")}
                </div>
              </div>
            </div>
          </td>
          <td className="py-5 text-left pl-6">
            <div className="flex justify-start">
              <Link
                to={`/dashboard/orders/${record.id}`}
                className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
              >
                View order
              </Link>
            </div>
            <div className="mt-1 text-xs leading-5 text-gray-500 dark:text-gray-400 hidden lg:block">
              Order{" "}
              <span className="text-gray-900 dark:text-gray-400">
                #{record.uuid}
              </span>
            </div>
          </td>
        </>
      );
    },
  },
  {
    name: "Attendees",
    permissionPrefix: "attendee",
    validationSchema: getAttendeeValidationSchema,
    headers: ["Buyer", "Ticket", "Order", "Checked In"],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="px-8 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.order.firstName} {record.order.lastName}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.ticket.name}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <Link
              to={`/dashboard/orders/${record.order.id}`}
              className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
            >
              View
            </Link>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.checkedIn ? (
                  <CheckCircleIcon className="h-5 w-5 text-green-500" />
                ) : (
                  <XCircleIcon className="h-5 w-5 text-red-500" />
                )}
              </div>
            </div>
          </td>
        </>
      );
    },
  },
  {
    name: "PromoCodes",
    permissionPrefix: "promocode",
    validationSchema: getPromoCodesValidationSchema,
    headers: ["Name", "Code Type", "Effect", "Valid From/To", "Number of Uses"],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="px-8 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">{record.name}</div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {toPascalCase(record.codeType)}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.codeType === "DISCOUNT" && (
                  <>
                    {record.amount ? (
                      <span>&euro;{record.amount} off</span>
                    ) : (
                      <span>{record.percentage}% discount</span>
                    )}
                  </>
                )}

                {record.codeType === "REVEAL" && (
                  <>
                    <span>Reveals {record.ticket.name}</span>
                  </>
                )}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {format(new Date(record.validFrom), "dd LLL yyyy HH:mm")} until{" "}
                {format(new Date(record.validUntil), "dd LLL yyyy HH:mm")}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.numberOfUses == 0 ? (
                  <>Not used yet</>
                ) : (
                  record.numberOfUses
                )}
              </div>
            </div>
          </td>
        </>
      );
    },
  },
  {
    name: "Emails",
    permissionPrefix: "email",
    validationSchema: getEmailValidationSchema,
    headers: ["Subject", "Sent To", "Recipients"],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="px-8 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.subject}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.sentTo}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.recipients}
              </div>
            </div>
          </td>
        </>
      );
    },
  },
  {
    name: "Users",
    permissionPrefix: "user",
    validationSchema: getUserValidationSchema,
    headers: ["Name", "Email", "Status", "Permissions", "Groups"],
    rowRenderer: (record: any) => {
      // Email of the logged in user
      const userEmail = localStorage.getItem(EMAIL_KEY);
      return (
        <>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0">
                <div className="text-md">
                  {record.firstName} {record.lastName}
                </div>
                {record.isSuperuser && (
                  <div className="mr-2 ml-1.5 inline-flex items-center rounded-md bg-gray-400/10 px-1 text-[0.6rem] font-medium text-gray-500 dark:text-gray-400 ring-1 ring-inset ring-gray-400/20">
                    <ShieldCheckIcon className="w-3 h-3 mr-0.5" /> Organisation
                    Admin
                  </div>
                )}
                {record.email === userEmail && (
                  <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
                    You 🫵
                  </span>
                )}
              </div>
            </div>
            {/* <div className="items-center mt-0.5 text-xs hidden sm:flex">
              <>
                <div className="relative -ml-0.5 mr-0.5">
                  <ArrowRightEndOnRectangleIcon className="h-3 w-3" />
                </div>
                <span className="font-normal text-gray-500">
                  {record.lastLogin ? (
                    <>
                      Last login{" "}
                      {format(
                        parseISO(record.lastLogin),
                        "EEEE, d LLLL yyyy 'at' h:mm aaaa"
                      )}
                    </>
                  ) : (
                    <>Never logged in</>
                  )}
                </span>
              </>
            </div> */}
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.email}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            {record.status === UserStatus.ACTIVE && (
              <span className="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-500/20">
                <CheckCircleIcon className="h-4 w-4 mr-1" />
                Active
              </span>
            )}
            {record.status === UserStatus.INVITED && (
              <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
                <EnvelopeOpenIcon className="h-4 w-4 mr-1" />
                Invited
              </span>
            )}
            {record.status === UserStatus.DECLINED && (
              <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">
                <XMarkIcon className="h-4 w-4 mr-1" />
                Declined
              </span>
            )}
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            {record.permissions.length > 0 ? (
              <TruncatedBadges values={record.permissions} />
            ) : (
              <>No inline permissions</>
            )}
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            {record.groups.length > 0 ? (
              <TruncatedBadges values={record.groups} />
            ) : (
              <>No groups</>
            )}
          </td>
        </>
      );
    },
    createButtonTitle: (
      <div className="flex items-center">
        <EnvelopeIcon className="h-4 w-4 mr-1" />
        Invite User
      </div>
    ),
  },
  {
    name: "Groups",
    permissionPrefix: "group",
    validationSchema: getGroupValidationSchema,
    headers: ["Name", "Permissions", "Users"],
    rowRenderer: (record: any) => {
      return (
        <>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">{record.name}</div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            {record.permissions ? (
              <TruncatedBadges values={record.permissions} />
            ) : (
              <>No permissions</>
            )}
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500 cursor-pointer">
            {record.users.length} user{record.users.length > 1 ? "s" : ""} in
            this group
          </td>
          {/* <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.email}
              </div>
            </div>
          </td>
          <td className="px-6 py-3 text-sm font-medium text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0 -space-x-1">
                {record.isSuperuser && (
                  <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                    <ShieldCheckIcon className="w-4 h-4 mr-1" /> Super User
                  </span>
                )}
              </div>
            </div>
          </td> */}
        </>
      );
    },
    itemRenderer: (record: any) => {
      return (
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              {record.name}
            </h3>
            {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-400">
              Personal details and application.
            </p> */}
          </div>
          <div className="mt-6 border-t border-gray-200 dark:border-white/5">
            <dl className="divide-y dark:divide-white/5 divide-gray-200">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400 sm:col-span-2 sm:mt-0">
                  {record.name}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Permissions
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                  {record.permissions.map((permission: DropdownOption) => (
                    <div
                      key={permission.id}
                      className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-500 dark:text-gray-400 ring-1 ring-inset ring-gray-400/20 mr-2"
                    >
                      {toHeaderCase(permission.value.replace(/^Can\s+/i, ""))}
                    </div>
                  ))}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Users in this group
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                  {record.users.map((user: User) => (
                    <div
                      key={user.id}
                      className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-500 dark:text-gray-400 ring-1 ring-inset ring-gray-400/20 mr-2"
                    >
                      {user.firstName} {user.lastName} ({user.email})
                    </div>
                  ))}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      );
    },
  },
  {
    name: "Forms",
    permissionPrefix: "form",
    headers: ["Name"],
    createPage: "/dashboard/forms/create",
    editPage: "/dashboard/forms",
    rowRenderer: (record: any) => {
      return (
        <>
          <td
            id={record.uuid}
            className="px-6 py-3 text-sm font-medium text-gray-500 max-w-10"
          >
            <div className="flex items-center space-x-2">
              <div className="flex flex-shrink-0">
                <div className="text-md ml-2 dark:text-white">
                  {record.name}
                </div>
              </div>
            </div>
          </td>
        </>
      );
    },
    rowButtons: (record: any) => {
      const organisation = localStorage.getItem(ORGANISATION_KEY);
      const formUrl = getFormUrl(record.uuid, JSON.parse(organisation || "{}"));
      console.log(record);
      return <FormRowButtons formUrl={formUrl} form={record} />;
    },
  },
];
