import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "@/dashboardQueries";
import { PaginatedResponse } from "@/models/Dashboard";
import {
  FormResponses as FormResponsesModel,
  FormResponse,
  Form,
} from "@/models/form";
import { Table } from "@/components/Table";
import {
  formatDateWithoutOrdinal,
  formatDateWithSmallMonth,
  formatDateWithTime,
  formatTimeFromDate,
} from "@/utils/date";
import {
  ChevronRightIcon,
  HomeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import { FORM_FIELDS, FormFieldType } from "@/constants/form";
import { toSentenceCase } from "js-convert-case";
import { ViewResponseDialog } from "@/components/dashboard/forms/ViewResponseDialog";
import { CheckCircle, XIcon } from "lucide-react";
import { FieldResponseRenderer } from "@/components/dashboard/forms/FieldResponseRenderer";
import { Loading } from "@/components/portal/Loading";
import { useDarkMode } from "@/hooks";

const fetchFormResponses = async (
  formId: string,
  page: number
): Promise<PaginatedResponse<FormResponsesModel>> => {
  const { data } = await axios.get(
    `${BASE_URL}/dashboard/forms/${formId}/responses?page=${page}`
  );
  return data;
};

const getForm = async (formId: number): Promise<Form> => {
  const { data } = await axios.get(`${BASE_URL}/dashboard/forms/${formId}`);
  return data;
};

export const getFormCategoryForFieldType = (fieldType: FormFieldType) => {
  return Object.values(FORM_FIELDS).find((category) =>
    category.fields.some((f) => f.type === fieldType)
  );
};

// We don't want to show certain fields here like textContent
export const FIELDS_NOT_TO_DISPLAY = ["textContent"];

export const FormResponses = () => {
  const isDarkMode = useDarkMode();
  const { formId } = useParams<{ formId: string }>();
  const [response, setResponse] = useState<FormResponsesModel | null>(null);
  const [viewResponseOpen, setViewResponseOpen] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: formData,
    error: formError,
    isLoading: formLoading,
  } = useQuery(["form", formId], () => getForm(formId), {
    enabled: !!formId,
  });

  const {
    data: responsesData,
    error: responsesError,
    isLoading: responsesLoading,
  } = useQuery(
    ["formResponses", formId, page],
    () => fetchFormResponses(formId, page),
    {
      enabled: !!formId,
    }
  );

  if (formLoading || responsesLoading) {
    return (
      <DashboardLayout requiredPermissions={[]} pageTitle="Form Responses">
        <div className="w-full h-screen flex items-center justify-center -mt-64">
          <Loading colour={isDarkMode ? "white" : "gray"} />
        </div>
      </DashboardLayout>
    );
  }

  if (formError || responsesError) {
    return (
      <DashboardLayout requiredPermissions={[]} pageTitle="Error">
        <div className="h-screen text-white">
          Error: {formError?.message || responsesError?.message}
        </div>
      </DashboardLayout>
    );
  }

  const getColumnHeaders = (): Array<ReactNode> => {
    if (responsesData?.results) {
      return formData.fields
        .filter((field) => !FIELDS_NOT_TO_DISPLAY.includes(field.fieldType))
        .map((field) => {
          const fieldType = field.fieldType as FormFieldType;
          const fieldCategory = getFormCategoryForFieldType(fieldType);

          if (fieldCategory) {
            const fieldInfo = fieldCategory.fields.find(
              (f) => f.type === fieldType
            );
            if (fieldInfo) {
              const Icon = fieldInfo.icon;
              return (
                <div className="flex items-center space-x-2 text-nowrap">
                  <div
                    className={`${fieldCategory.colour} p-1 rounded-md shadow-lg`}
                  >
                    <Icon className="h-4 w-4" />
                  </div>
                  <span>{toSentenceCase(field.name)}</span>
                </div>
              );
            }
          }

          return <span>{field.fieldType}</span>;
        });
    }
  };

  const getPageOfRecords = (page: number) => {
    setPage(page);
  };

  return (
    <DashboardLayout
      requiredPermissions={[]}
      pageTitle={`${formData?.name} Responses`}
    >
      <ViewResponseDialog
        formResponse={response}
        open={viewResponseOpen}
        form={formData}
        setOpen={setViewResponseOpen}
      />
      <div className="py-4 px-8">
        <nav aria-label="Breadcrumb" className="flex">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex items-center">
                <PencilSquareIcon
                  aria-hidden="true"
                  className="size-5 shrink-0 text-gray-400"
                />
                <Link
                  to="/dashboard/forms"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  Forms
                </Link>
              </div>
            </li>
            {[
              {
                name: formData?.name,
                href: `/dashboard/forms/${formData?.id}`,
              },
              {
                name: "Responses",
                href: `/dashboard/forms/${formData?.id}/responses`,
                current: true,
              },
            ].map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="size-5 shrink-0 text-gray-400"
                  />
                  {page.href ? (
                    <Link
                      to={page.href}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {page.name}
                    </Link>
                  ) : (
                    <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                      {page.name}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      {formData && responsesData && responsesData.results.length > 0 ? (
        <>
          <div className="border-t border-white/10 relative w-full overflow-hidden">
            <Table
              headers={getColumnHeaders()}
              records={responsesData.results}
              getPageOfRecords={getPageOfRecords}
              rowRenderer={(formResponses: FormResponsesModel) => (
                <>
                  {formData.fields
                    .filter(
                      (field) =>
                        !FIELDS_NOT_TO_DISPLAY.includes(field.fieldType)
                    )
                    .map((field) => field)
                    .map((field, index) => {
                      return (
                        <td
                          data-field-type={field.fieldType}
                          onClick={() => {
                            if (responsesData) {
                              setViewResponseOpen(true);
                              setResponse(formResponses);
                            }
                          }}
                          className={`${
                            index === 0 ? " pl-8" : "pl-6"
                          } py-3 text-sm font-medium text-gray-500 cursor-pointer min-w-[16rem]`}
                        >
                          <div className="flex items-center space-x-2 text-gray-400">
                            <FieldResponseRenderer
                              uuid={field.uuid}
                              formData={formData}
                              formResponses={formResponses}
                            />
                          </div>
                        </td>
                      );
                    })}
                </>
              )}
              pagination={responsesData}
            />
          </div>
        </>
      ) : (
        <div className="px-4 -mt-3">
          <div className="mt-3 h-40 border dark:border-white/10 border-dashed rounded-md flex items-center justify-center px-4">
            <p className="dark:text-gray-400 text-sm text-center">
              There are no form responses yet, once users start submitting you
              will see them here.
            </p>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};
