import { AccreditationHorizontalNav } from "@/components/dashboard/accreditation/AccreditationHorizontalNav";
import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";
import { Modal } from "@/components/dashboard/Modal";
import { Table } from "@/components/Table";
import { Input } from "@/crud/form/Input";
import { Quill } from "@/crud/form/Quill/Quill";
import yup from "@/crud/yup-extended";
import { BASE_URL } from "@/dashboardQueries";
import { AccreditationGroup, EmailTemplate } from "@/models/accreditation";
import { PaginatedResponse } from "@/models/Dashboard";
import { Dialog } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { Formik } from "formik";
import { PencilRuler } from "lucide-react";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getEmails = async (
  page: number,
  filters?: Record<string, any>
): Promise<PaginatedResponse<EmailTemplate>> => {
  console.log(new URLSearchParams(filters).toString());
  const url = `${BASE_URL}/dashboard/email-templates?page=${page}&${new URLSearchParams(
    filters
  ).toString()}`;
  const { data } = await axios.get(url);
  return data;
};

const deleteEmail = async (id: number) => {
  const url = `${BASE_URL}/dashboard/email-templates/${id}`;
  await axios.delete(url);
};

const createEmail = async (email: Partial<EmailTemplate>) => {
  const url = `${BASE_URL}/dashboard/email-templates`;
  const { data } = await axios.post(url, email);
  return data;
};

const updateEmail = async (email: EmailTemplate) => {
  const url = `${BASE_URL}/dashboard/email-templates/${email.id}`;
  const { data } = await axios.put(url, email);
  return data;
};

export const Emails = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [emailToDelete, setEmailToDelete] = useState<EmailTemplate | null>(
    null
  );
  const [emailToEdit, setEmailToEdit] = useState<EmailTemplate | null>(null);
  const [emailToExtend, setEmailToExtend] = useState<EmailTemplate | null>(
    null
  );
  const [createEmailOpen, setCreateEmailOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data, error, isLoading, isError } = useQuery<
    PaginatedResponse<EmailTemplate>
  >(["emailTemplates", page, search], () => getEmails(page, { search }), {
    keepPreviousData: true,
    //   enabled: !!groupId,
  });

  const mutation = useMutation(deleteEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(["emailTemplates"]);
      setEmailToDelete(null);
    },
  });

  const createMutation = useMutation(createEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(["emailTemplates"]);
      setCreateEmailOpen(false);
      toast.success("Email created successfully");
    },
  });

  const updateMutation = useMutation(updateEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(["emailTemplates"]);
      setEmailToEdit(null);
      setCreateEmailOpen(false);
      toast.success("Email updated successfully");
    },
  });

  const handleDelete = () => {
    if (emailToDelete) {
      mutation.mutate(emailToDelete.id);
      toast.success("♻️ Email deleted successfully");
    }
  };

  const handleSearch = useCallback((search: string) => {
    setSearch(search);
    setPage(1); // Reset to first page on new search
    // queryClient.invalidateQueries(["emailTemplates"]);
  }, []);

  const getPageOfRecords = (page: number) => {};

  const closeCreateEditModal = () => {
    setEmailToEdit(null);
    setEmailToExtend(null);
    setCreateEmailOpen(false);
  };

  const renderSubject = (subject: string) => {
    const parts = subject.split(/(\{[^}]+\})/g);
    return parts.map((part, index) =>
      part.match(/\{[^}]+\}/) ? (
        <span
          key={index}
          className="font-mono bg-gray-400/10 ring-1 ring-inset ring-gray-400/20 rounded-md px-0.5 py-0.5 text-[0.6rem]"
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <DashboardLayout
      requiredPermissions={["view_user"]}
      pageTitle="Emails"
      rightControls={
        <>
          <button
            onClick={() => setCreateEmailOpen(true)}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create
          </button>
        </>
      }
    >
      {/* <AccreditationHorizontalNav activePage="Emails" /> */}

      {/* Delete Modal */}
      <Modal
        isOpen={!!emailToDelete}
        setIsOpen={(isOpen: boolean) => setEmailToDelete(null)}
      >
        <div className="w-full max-w-lg">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
              >
                Delete {emailToDelete?.name}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete? All of your data will be
                  permanently removed forever. This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
            <button
              type="button"
              onClick={handleDelete}
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
            >
              Yes, delete
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
              onClick={() => setEmailToDelete(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* Create/Edit Modal */}
      <Modal
        isOpen={!!emailToEdit || !!emailToExtend || createEmailOpen}
        setIsOpen={closeCreateEditModal}
      >
        <div className="w-full w-[40rem]">
          <Formik
            // email to edit if it's there, or email to extend, else {}
            initialValues={
              emailToEdit ? emailToEdit : emailToExtend ? emailToExtend : {}
            }
            validationSchema={yup.object().shape({
              name: yup.string().required("Required"),
              subject: yup.string().required("Required"),
              body: yup.string().required("Required"),
            })}
            onSubmit={async (values) => {
              if (emailToEdit) {
                updateMutation.mutate({ ...values, id: emailToEdit.id });
              } else {
                createMutation.mutate(values);
              }
              closeCreateEditModal();
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <div>
                <h4 className="dark:text-gray-400 font-semibold text-sm mb-3">
                  Create email
                </h4>

                {emailToExtend && (
                  <div className="rounded-md bg-light-primary dark:bg-dark-secondary bg-gray-50 p-4 mb-4 mt-1">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-gray-900 dark:text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-gray-900 dark:text-white">
                          You are extending the {emailToExtend.name} email
                          template
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-1/2">
                  <label
                    htmlFor="name"
                    className="block text-sm/6 font-medium dark:text-white mb-2"
                  >
                    Name
                  </label>
                  <Input name="name" type="text" value={values.name} required />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="subject"
                    className="block text-sm/6 font-medium dark:text-white mb-2"
                  >
                    Subject
                  </label>
                  <Input
                    name="subject"
                    type="text"
                    value={values.subject}
                    required
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="subject"
                    className="block text-sm/6 font-medium dark:text-white mb-2"
                  >
                    Body
                  </label>
                  <div className="w-[40rem]">
                    <Quill name="body" />
                  </div>
                </div>

                <div className="flex space-x-3 mt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={closeCreateEditModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => handleSubmit()}
                  >
                    {emailToEdit ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Modal>

      {data && data.results && (
        <Table
          onSearch={(search: string) => handleSearch(search)}
          headers={["Subject", ""]}
          records={data.results}
          pagination={data}
          getPageOfRecords={getPageOfRecords}
          rowRenderer={(emailTemplate: EmailTemplate) => (
            <>
              <td className="px-6 py-3 text-sm font-medium text-gray-500 pl-8">
                <div className="flex items-center space-x-2">
                  <div className="flex flex-shrink-0 -space-x-1">
                    <h2 className="min-w-0 text-sm/6 font-semibold dark:text-white">
                      <p className="flex gap-x-2">
                        <span className="truncate">{emailTemplate.name}</span>
                      </p>
                    </h2>
                  </div>
                  {emailTemplate.default && (
                    <h3 className="ml-10 inline-flex items-center rounded-md bg-blue-400/10 px-1.5 py-0.5 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30 mr-1.5">
                      Default
                    </h3>
                  )}
                </div>
                <div className="flex items-center gap-x-2.5 text-xs/5 text-gray-400 font-normal">
                  <p className="truncate">
                    {renderSubject(emailTemplate.subject)}
                  </p>
                </div>
              </td>
              <td className="table-cell px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400 flex justify-end items-center">
                <div className="flex items-center space-x-2 justify-end">
                  <div className="flex">
                    {!emailTemplate.default && (
                      <>
                        {" "}
                        <button
                          onClick={() => setEmailToDelete(emailTemplate)}
                          type="button"
                          className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20"
                        >
                          <TrashIcon className="text-red-500 h-4 w-4" />
                        </button>
                        <button
                          onClick={() => setEmailToEdit(emailTemplate)}
                          type="button"
                          className="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/20 ml-2"
                        >
                          <PencilSquareIcon className="text-green-500 h-4 w-4" />
                        </button>
                      </>
                    )}
                    <button
                      onClick={() =>
                        setEmailToExtend({
                          name: emailTemplate.name,
                          subject: emailTemplate.subject,
                          body: emailTemplate.body,
                        } as EmailTemplate)
                      }
                      type="button"
                      className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/20 ml-2"
                    >
                      <PencilRuler className="text-blue-500 h-4 w-4" />
                    </button>
                  </div>
                </div>
              </td>
            </>
          )}
          onRowClick={(emailTemplate: EmailTemplate) => console.log("clciel")}
        />
      )}
    </DashboardLayout>
  );
};
