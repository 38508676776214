import { EVENT_KEY, ORGANISATION_KEY } from "@/pages/protected-route";
import { SetURLSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

export const addSearchParam = (
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  key: string,
  value: string
) => {
  const newSearchParams = new URLSearchParams(searchParams.toString());
  if (value) {
    newSearchParams.set(key, value);
  } else {
    newSearchParams.delete(key);
  }
  setSearchParams(newSearchParams, { replace: true });
};

export const constructQueryString = (obj: Record<string, any>): string => {
  const queryStringParts: string[] = [];

  const buildQueryString = (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (value.hasOwnProperty("id")) {
        queryStringParts.push(`${key}Id=${value.id}`);
      }
    } else {
      queryStringParts.push(`${key}=${value}`);
    }
  };

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      buildQueryString(key, obj[key]);
    }
  }

  return queryStringParts.join("&");
};

// Get base event URL for dashboard for links etc.
export const getBaseEventUrl = () => {
  if (import.meta.env.VITE_ENVIRONMENT == "local") {
    return "http://localhost:5173";
  }
  const selectedEvent = JSON.parse(localStorage.getItem(EVENT_KEY) || "{}");
  const selectedOrganisation = JSON.parse(
    localStorage.getItem(ORGANISATION_KEY) || "{}"
  );
  return `https://${selectedOrganisation.domain}/${selectedEvent.slug}`;
};

// For dashboard
export const useBaseOrganisationUrl = () => {
  const [baseOrganisationUrl, setBaseOrganisationUrl] = useState("");

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT == "local") {
      setBaseOrganisationUrl("http://localhost:5173");
    } else {
      const selectedOrganisation = JSON.parse(
        localStorage.getItem(ORGANISATION_KEY) || "{}"
      );
      setBaseOrganisationUrl(`https://${selectedOrganisation.domain}`);
    }
  }, []);

  return baseOrganisationUrl;
};
