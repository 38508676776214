import { SelectedContentField, SelectedField } from "@/models/form";
import { useFormikContext } from "formik";

interface Props {
  field: SelectedField;
}

export const ContentField = ({ field }: Props) => {
  const { values } = useFormikContext();

  const getFieldIndex = (fieldId: number) => {
    return values.fields.findIndex((field_: any) => field_.id === fieldId);
  };

  const fieldIndex = getFieldIndex(field.id);

  return (
    <div
      className="prose dark:prose-invert"
      dangerouslySetInnerHTML={{ __html: field.content }}
    ></div>
  );
};
