import { FileFieldResponse, Form, FormResponses } from "@/models/form";
import { formatDateWithSmallMonth, formatDateWithTime } from "@/utils/date";
import { CheckCircle, XIcon } from "lucide-react";
import { FileResponseField } from "./FileResponseField";
import { toSentenceCase } from "js-convert-case";
import { formatNumberToMoney } from "@/utils/cart";

interface Props {
  uuid: string;
  formData: Form;
  formResponses: FormResponses;
  detail?: boolean;
}

export const FieldResponseRenderer = ({
  uuid,
  formData,
  formResponses,
  detail,
}: Props) => {
  // function to take some string and truncate it to a given length if it exceeds that length
  const truncateString = (str: string, length: number) => {
    if (str.length > length) {
      return str.substring(0, length) + "...";
    }
    return str;
  };

  const response = formResponses.responses.find(
    (field) => field.field.uuid === uuid
  );

  if (!response) {
    return "";
  }

  const fieldType = formData.fields.find(
    (field) => field.uuid === uuid
  )?.fieldType;

  // Date fields
  if (fieldType === "date") {
    return formatDateWithSmallMonth(response.response);
  } else if (fieldType === "dateAndTime") {
    return formatDateWithTime(response.response);
  }

  // Multiple choice fields
  if (fieldType === "multipleChoice") {
    return response?.response.join(", ");
  }

  // Legal
  if (fieldType === "legal") {
    return response?.response === "i_accept" ? (
      <div className="flex items-center">
        <CheckCircle className="h-5 w-5 text-green-500 mr-1" />I accept
      </div>
    ) : (
      <XIcon className="h-5 w-5 text-red-500" />
    );
  }

  if (fieldType === "yesNo" && response?.response) {
    return toSentenceCase(response?.response);
  }

  // Website
  if (fieldType === "website" && response?.response) {
    return (
      <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-400/20">
        {response?.response}
      </span>
    );
  }

  if (fieldType === "fileUpload" && response && response.response) {
    return (
      <div className="-ml-1">
        {response.response.map((file: FileFieldResponse) =>
          detail ? (
            <FileResponseField key={file.name} file={file} />
          ) : (
            <span className="mr-2 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-400/20">
              {file.name}
            </span>
          )
        )}
      </div>
    );
  }

  if (fieldType === "moneyField") {
    return formatNumberToMoney(response?.response);
  }

  if (fieldType === "number" || fieldType === "decimal") {
    return response?.response.toFixed(2);
  }

  return detail
    ? response.response
    : truncateString(response?.response, 40) || "No value";
};
