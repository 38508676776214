import { AccreditationIndividual } from "@/models/accreditation";
import { create } from "zustand";

interface AccreditationStore {
  createGroupOpen: boolean;
  setCreateGroupOpen: (open: boolean) => void;
  createGroupFromTemplateOpen: boolean;
  setCreateGroupFromTemplateOpen: (open: boolean) => void;
  allocateTicketsOpen: boolean;
  setAllocateTicketsOpen: (open: boolean) => void;
  groupSettingsOpen: boolean;
  setGroupSettingsOpen: (open: boolean) => void;
  createIndividualOpen: boolean;
  setCreateIndividualOpen: (open: boolean) => void;
  individual: AccreditationIndividual;
  setIndividual: (individual: AccreditationIndividual) => void;
}

export const useAccreditationStore = create<AccreditationStore>((set) => ({
  createGroupOpen: false,
  setCreateGroupOpen: (open: boolean) => set({ createGroupOpen: open }),
  createGroupFromTemplateOpen: false,
  setCreateGroupFromTemplateOpen: (open: boolean) =>
    set({ createGroupFromTemplateOpen: open }),
  allocateTicketsOpen: false,
  setAllocateTicketsOpen: (open: boolean) => set({ allocateTicketsOpen: open }),
  groupSettingsOpen: false,
  setGroupSettingsOpen: (open: boolean) => set({ groupSettingsOpen: open }),
  createIndividualOpen: false,
  setCreateIndividualOpen: (open: boolean) =>
    set({ createIndividualOpen: open }),
  individual: {} as AccreditationIndividual,
  setIndividual: (individual: AccreditationIndividual) => set({ individual }),
}));
