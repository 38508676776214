import { BASE_URL } from "@/dashboardQueries";
import { useAccreditationStore } from "@/store/accreditation";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import {
  BoxesIcon,
  BoxIcon,
  CogIcon,
  ShapesIcon,
  UserIcon,
} from "lucide-react";
import { useQueryClient } from "react-query";

interface Props {
  isInGroup?: boolean;
}

export const GroupOptionsButton = ({ isInGroup }: Props) => {
  const {
    setCreateGroupOpen,
    setCreateGroupFromTemplateOpen,
    setGroupSettingsOpen,
    setCreateIndividualOpen,
  } = useAccreditationStore();
  const queryClient = useQueryClient();

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            id="group-options-button"
            className="dark:text-white dark:ring-white/10 inline-flex w-full justify-center gap-x-1.5 rounded-md dark:bg-zinc-800 bg-white px-3 py-2 text-sm font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:hover:bg-zinc-800 hover:bg-gray-100"
          >
            Options
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 size-5 text-gray-400"
            />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-white/10 dark:bg-zinc-800 dark:ring-white/10 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
          <div className="py-1">
            <Menu.Item>
              <button
                data-button-id="create-group"
                type="button"
                onClick={() => setCreateGroupOpen(true)}
                className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-500 hover:text-zinc-800"
              >
                <BoxIcon aria-hidden="true" className="mr-3 size-5" />
                Create group
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                data-button-id="create-individual"
                type="button"
                onClick={() => setCreateIndividualOpen(true)}
                className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-500 hover:text-zinc-800"
              >
                <UserIcon
                  aria-hidden="true"
                  className="mr-3 size-5 group-data-[focus]:text-gray-500"
                />
                Create individual
              </button>
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              <button
                data-button-id="create-from-template-option"
                type="button"
                onClick={() => setCreateGroupFromTemplateOpen(true)}
                className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-500 hover:text-zinc-800"
              >
                <ShapesIcon
                  aria-hidden="true"
                  className="mr-3 size-5 group-data-[focus]:text-gray-500"
                />
                Create from template
              </button>
            </Menu.Item>
            {/* <Menu.Item>
              <a
                href="#"
                className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                <ArrowRightCircleIcon
                  aria-hidden="true"
                  className="mr-3 size-5 text-gray-400 group-data-[focus]:text-gray-500"
                />
                Move
              </a>
            </Menu.Item> */}
          </div>
          {isInGroup && (
            <>
              <div className="py-1">
                <Menu.Item>
                  <button
                    type="button"
                    onClick={() => setGroupSettingsOpen(true)}
                    className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-700 hover:text-zinc-500"
                  >
                    <CogIcon
                      aria-hidden="true"
                      className="mr-3 size-5 group-data-[focus]:text-gray-500"
                    />
                    Settings
                  </button>
                </Menu.Item>
                {/* <Menu.Item>
              <a
                href="#"
                className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                <HeartIcon
                  aria-hidden="true"
                  className="mr-3 size-5 text-gray-400 group-data-[focus]:text-gray-500"
                />
                Add to favorites
              </a>
            </Menu.Item> */}
              </div>
              <div className="py-1">
                <Menu.Item>
                  <a
                    href="#"
                    className="group dark:text-zinc-300 flex items-center px-4 py-2 text-sm text-gray-700 hover:text-zinc-500"
                  >
                    <TrashIcon
                      aria-hidden="true"
                      className="mr-3 size-5 group-data-[focus]:text-gray-500"
                    />
                    Delete
                  </a>
                </Menu.Item>
              </div>
            </>
          )}
        </Menu.Items>
      </Menu>

      {/* <Menu as="div" className="relative ml-3">
        <Menu.Button className="inline-flex items-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
          Options
          <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-1.5 size-5" />
        </Menu.Button>
        <Menu.Items className="absolute right-0 z-10 -ml-1 mt-2 w-48 origin-top-left dark:bg-zinc-800 rounded-md py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
          <Menu.Item>
            <button
              type="button"
              onClick={onCreateGroup}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none dark:text-white"
            >
              Group
            </button>
          </Menu.Item>
          <Menu.Item>
            <button
              type="button"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none dark:text-white"
            >
              Individual
            </button>
          </Menu.Item>
          <Menu.Item>
            <button
              type="button"
              onClick={() => setCreateFromTemplateOpen(true)}
              className="border-t mt-1 pt-3 dark:border-white/5 block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none dark:text-white"
            >
              From template
            </button>
          </Menu.Item>
        </Menu.Items>
      </Menu> */}
    </>
  );
};
