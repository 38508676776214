import { Documents } from "@/components/portal/accreditation/Documents";
import ManageGroupHelpDrawer from "@/components/portal/accreditation/ManageGroupHelpDrawer";
import { ManageGroupNav } from "@/components/portal/accreditation/ManageGroupNav";
import { TicketAllocationDropdown } from "@/components/portal/accreditation/TicketAllocationDropdown";
import { Dropdown } from "@/crud/form/Dropdown";
import yup from "@/crud/yup-extended";
import { BASE_URL } from "@/dashboardQueries";
import {
  AccreditationIndividual,
  ManageAccreditationGroup,
  ManageAccreditationGroupRequest,
  TicketAllocation,
} from "@/models/accreditation";
import { DropdownOption } from "@/models/Dashboard";
import { usePortalStore } from "@/store/portal";
import { classNames } from "@/utils/styles";
import {
  ChevronDownIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import axios, { all } from "axios";
import {
  Field,
  FieldArray,
  Formik,
  FormikValues,
  getIn,
  useFormikContext,
} from "formik";
import { debounce } from "lodash";
import { FilesIcon, MailIcon, PlusIcon, TicketIcon } from "lucide-react";
import { act, useCallback, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

/* todo: 

ticket dropdown is more complicated than we first thought.
it not only has to be a multi select, but it also needs to make sure that we only allocate the number of tickets that are available.
probably needs to be it's own sub component in here

if we allow the Formik form to select tickets for each user, each time we render whats available to be picked, we essentially need
to show the total number of tickets available, minus the number of tickets already allocated to other users from the form values.


*/

const Cell = ({
  name,
  index,
  underline,
}: {
  name: string;
  index: number;
  underline?: boolean;
}) => {
  const { values, errors } = useFormikContext();
  const {
    manageAccreditationGroupCellOpen,
    setManageAccreditationGroupCellOpen,
  } = usePortalStore();
  const inputRef = useRef<HTMLInputElement>(null);

  const setGroupCellOpen = (field: string, idx: number) => {
    const cell = `${field}-${idx}`;
    if (manageAccreditationGroupCellOpen !== cell) {
      setManageAccreditationGroupCellOpen(cell);
    }
  };

  const fieldKey = `individuals[${index}].${name}`;
  const fieldError = getIn(errors, fieldKey);
  const value = getIn(values, fieldKey);

  useEffect(() => {
    if (
      manageAccreditationGroupCellOpen === `${name}-${index}` &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [manageAccreditationGroupCellOpen, name, index]);

  // When someone presses tab move to the next cell
  useEffect(() => {
    if (
      manageAccreditationGroupCellOpen === `${name}-${index}` &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [manageAccreditationGroupCellOpen, name, index]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const fields = ["firstName", "lastName", "email"];
      const currentIndex = fields.indexOf(name);
      const nextIndex = (currentIndex + 1) % fields.length;
      const nextField = fields[nextIndex];
      setGroupCellOpen(nextField, index);
    }
  };

  return (
    <td
      className={`${
        fieldError ? "bg-red-400/10 text-red-400" : ""
      } relative whitespace-nowrap py-2 pl-1 pr-4 text-sm font-medium cursor-pointer`}
      onClick={() => setGroupCellOpen(name, index)}
    >
      {manageAccreditationGroupCellOpen === `${name}-${index}` ? (
        <div className="absolute inset-0 w-full h-full rounded-md shadow-lg">
          <Field
            innerRef={inputRef}
            style={{ paddingLeft: "0.65rem", letterSpacing: "-0.01rem" }}
            onKeyDown={handleKeyDown}
            className="w-full h-full bg-transparent -none dark:bg-zinc-800 text-sm pt-[0.4rem]"
            name={`individuals[${index}].${name}`}
          />
        </div>
      ) : (
        <div
          className={`${name != "firstName" ? "pl-2" : ""} ${
            !value ? "py-2.5" : ""
          } ${underline ? "underline decoration-zinc-700" : ""}`}
        >
          {getIn(values, `individuals[${index}].${name}`)}
        </div>
      )}
      {fieldError && (
        <div className="absolute top-0 right-0 translate-y-1/2 mr-2 mt-0.5">
          <ExclamationCircleIcon className="text-red-400 w-4 h-4" />
        </div>
      )}
    </td>
  );
};

const fetchGroup = async (id: string): Promise<ManageAccreditationGroup> => {
  const { data } = await axios.get(
    `${BASE_URL}/accreditation/groups/${id}/manage`
  );
  return data;
};

const updateGroupRequest = async (
  id: string,
  group: ManageAccreditationGroupRequest
): Promise<ManageAccreditationGroup> => {
  const { data } = await axios.put(
    `${BASE_URL}/accreditation/groups/${id}/manage`,
    group
  );
  return data;
};

interface IndividualRowProps {
  individual: AccreditationIndividual;
  index: number;
  ticketCount: Record<number, number>;
  groupAllocatedTickets: Array<TicketAllocation>;
  deleteIndividual: (uuid: string) => void;
  sendEmail: (individualId: number) => void;
}

const IndividualRow = ({
  individual,
  index,
  ticketCount,
  groupAllocatedTickets,
  deleteIndividual,
  sendEmail,
}: IndividualRowProps) => {
  return (
    <tr
      key={index}
      className="divide-x divide-gray-200 dark:divide-white/10 dark:text-gray-200 text-gray-500"
    >
      <Field type="text" name={`individuals[${index}].id`} hidden />
      <Field type="text" name={`individuals[${index}].id`} hidden />
      <Cell name="firstName" index={index} />
      <Cell name="lastName" index={index} />
      <Cell name="email" index={index} underline />
      <TicketAllocationDropdown
        name={`individuals[${index}].tickets`}
        tickets={groupAllocatedTickets}
        ticketCount={ticketCount}
      />
      <td className="whitespace-nowrap py-1 pl-2 pr-4 text-sm sm:pr-0 w-16">
        {individual.isGroupContact ? (
          <span className="inline-flex items-center rounded-md bg-blue-400/10 px-1.5 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
            Group contact
          </span>
        ) : (
          <div>
            <span
              onClick={() => deleteIndividual(individual.uuid)}
              className="mr-1.5 cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
            >
              <TrashIcon className="h-4 w-4" />
            </span>
            <span
              onClick={() => sendEmail(individual.id)}
              className="cursor-pointer inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
            >
              <MailIcon className="h-4 w-4" />
            </span>
          </div>
        )}
      </td>
    </tr>
  );
};

export const ManageGroup = () => {
  const queryClient = useQueryClient();
  const {
    manageAccreditationGroupCellOpen,
    setManageAccreditationGroupCellOpen,
  } = usePortalStore();
  const { groupId } = useParams<{ groupId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  // query to get the data
  const { data, error, isLoading, isError } = useQuery(["group", groupId], () =>
    fetchGroup(groupId)
  );

  // query to mutate the data
  const mutation = useMutation(
    ({ id, group }: { id: string; group: ManageAccreditationGroupRequest }) =>
      updateGroupRequest(id, group),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group", groupId]);
      },
    }
  );

  const deleteMutation = useMutation(
    (uuid: string) =>
      axios.delete(
        `${BASE_URL}/accreditation/groups/${groupId}/individuals/${uuid}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group", groupId]);
      },
    }
  );

  const emailMutation = useMutation(
    (individualId: number) =>
      axios.post(
        `http://127.0.0.1:8000/accreditation/groups/${groupId}/email`,
        {
          individuals: [individualId],
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group", groupId]);
      },
    }
  );

  const setGroupCellOpen = (field: string, index: number) => {
    const cell = `${field}-${index}`;
    setManageAccreditationGroupCellOpen(cell);
  };

  // When someone clicks outside the table, unfocus all
  const handleClickOutside = (event: MouseEvent) => {
    if (!(event.target as HTMLElement).closest("td")) {
      setManageAccreditationGroupCellOpen("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const debouncedSubmit = useCallback(
    debounce((values) => {
      console.log("asshh");
      console.log(values);

      // Check if any row has firstName, lastName, and email
      const validRows = values.individuals.filter(
        (individual: AccreditationIndividual) =>
          individual.firstName != "" &&
          individual.lastName != "" &&
          individual.email != ""
      );

      const individuals = validRows.map(
        (individual: AccreditationIndividual) => ({
          ...individual,
          group: data?.id,
        })
      );

      if (validRows.length > 0) {
        // Call your server API here
        console.log("Submitting form with values:", individuals);
        updateGroup({ individuals });
      }
    }, 300),
    [data]
  );

  const updateGroup = async (data: ManageAccreditationGroupRequest) => {
    if (groupId) {
      console.log("about to update, it is");
      console.log(data);
      mutation.mutate({ id: groupId, group: data });
    }
  };

  const deleteIndividual = (uuid: string) => {
    deleteMutation.mutate(uuid);
  };

  const sendEmail = (individualId: number) => {
    emailMutation.mutate(individualId);
  };

  const activeTab = searchParams.get("tab");

  return (
    <div className="dark:bg-dark-primary text-gray-200 font-sans min-h-screen">
      {/* <div className="dark:bg-dark-secondary dark:border-white/10 py-3 border-b">
        <div className="max-w-4xl mx-auto">
          <Logo height={22} />
        </div>
      </div> */}
      {data && (
        <div className="max-w-4xl mx-auto dark:bg-dark-secondary p-4">
          <ManageGroupHelpDrawer />
          <div className="">
            <Formik
              initialValues={{ individuals: data.individuals }}
              validationSchema={yup.object().shape({
                individuals: yup.array().of(
                  yup.object().shape({
                    id: yup.number(),
                    firstName: yup.string().required("Required"),
                    lastName: yup.string().required("Required"),
                    email: yup
                      .string()
                      .email("Must be a valid email")
                      .required("Required"),
                    tickets: yup.array().of(
                      yup.object().shape({
                        id: yup.number(),
                        value: yup.string().required("Required"),
                      })
                    ),
                  })
                ),
              })}
              onSubmit={(values: FormikValues) => console.log("wowowo")}
            >
              {({ values, handleSubmit, errors }) => {
                useEffect(() => {
                  if (Object.keys(errors).length === 0) {
                    debouncedSubmit(values);
                  }
                }, [values, handleSubmit]);

                // use the formik values to get a list of all the tickets that have already been picked
                const allPickedTickets =
                  values.individuals?.reduce((acc, individual) => {
                    return acc.concat(individual.tickets || []);
                  }, []) || [];

                // Count each picked ticket by ID
                const ticketCount = allPickedTickets.reduce((acc, ticket) => {
                  acc[ticket.id] = (acc[ticket.id] || 0) + 1;
                  return acc;
                }, {});

                return (
                  <form>
                    <div className="flex">
                      <div className="mb-2 pl-2 -mt-6">
                        <h1 className="text-3xl font-semibold">{data.name}</h1>
                        <ManageGroupNav
                          canUploadDocuments={data.canUploadDocuments}
                        />
                      </div>
                      {activeTab != "documents" && (
                        <div className="flex flex-1 justify-end my-2">
                          <div className="inline-block w-1/2">
                            <div className="ring-1 ring-inset ring-gray-400/20 rounded-md bg-gray-400/5 p-2">
                              {data.groupAllocatedTickets.map((ticket) => {
                                const pickedCount =
                                  ticketCount[ticket.ticket.id] || 0;
                                const availableQuantity =
                                  ticket.quantity - pickedCount;
                                return (
                                  <div
                                    key={ticket.ticket.id}
                                    className="text-gray-400 text-xs flex"
                                  >
                                    <div className="font-semibold">
                                      {ticket.ticket.name}
                                    </div>
                                    <div className="flex flex-1 justify-end">
                                      {availableQuantity} / {ticket.quantity}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flow-root">
                      <div className="sm:-mx-6 lg:-mx-8 ">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          {(!activeTab ||
                            activeTab === "ticket-allocation") && (
                            <table className="min-w-full divide-y divide-gray-300 dark:divide-white/10">
                              <thead>
                                <tr className="divide-x border-t dark:border-white/10 divide-gray-200 dark:divide-white/10 text-gray-900 dark:text-gray-500">
                                  <th
                                    scope="col"
                                    className="py-2 pr-4 text-left text-sm font-medium w-28"
                                  >
                                    First Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2.5 py-2 text-left text-sm font-medium w-28"
                                  >
                                    Last Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2.5 py-2 text-left text-sm  font-medium w-60"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-2 pl-2.5 pr-4 text-left text-sm sm:pr-0  font-medium"
                                  >
                                    Tickets
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-2 pl-4 pr-4 text-left text-sm sm:pr-0"
                                  ></th>
                                </tr>
                              </thead>
                              <FieldArray
                                name="individuals"
                                render={(arrayHelpers) => (
                                  <tbody className="divide-y divide-gray-200 bg-white dark:divide-white/10 dark:bg-transparent">
                                    {values.individuals.map(
                                      (
                                        individual: AccreditationIndividual,
                                        index: number
                                      ) => (
                                        <IndividualRow
                                          key={index}
                                          individual={individual}
                                          index={index}
                                          ticketCount={ticketCount}
                                          groupAllocatedTickets={
                                            data.groupAllocatedTickets
                                          }
                                          deleteIndividual={(uuid) => {
                                            deleteIndividual(uuid);
                                            arrayHelpers.remove(index);
                                          }}
                                          sendEmail={() =>
                                            sendEmail(individual.id)
                                          }
                                        />
                                      )
                                    )}

                                    <tr
                                      key="addUser"
                                      className="dark:text-gray-200 text-gray-500 hover:dark:bg-zinc-800/40"
                                      onClick={() => {
                                        // push the new person to the array
                                        arrayHelpers.push({
                                          firstName: "",
                                          lastName: "",
                                          email: "",
                                          role: "",
                                        });

                                        // set the first cell open
                                        setGroupCellOpen(
                                          "firstName",
                                          values.individuals.length
                                        );
                                      }}
                                    >
                                      <td
                                        colSpan={5}
                                        className="dark:text-gray-500 border-b dark:border-white/10"
                                      >
                                        <div className="flex items-center py-1.5 -ml-0.5 cursor-pointer">
                                          <PlusIcon className="mr-1 h-5 w-5" />
                                          <p className="text-sm">Add person</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              />
                            </table>
                          )}

                          {activeTab === "documents" &&
                            data.uuid &&
                            data.canUploadDocuments && (
                              <div className="relative">
                                <Documents groupId={data.uuid} />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};
