import { Field, getIn, useFormikContext } from "formik";
import { SelectedField } from "../../../../models/form";
import { ChangeEvent, useEffect, useState } from "react";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
  hideLabels?: boolean;
  name?: string;
}

export const TextField = ({
  inFormBuilder,
  field,
  hideLabels,
  name,
}: Props) => {
  const type = field.field.type;
  const { errors, setFieldValue } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, name ? name : field.uuid) || "");
  }, [errors]);

  const getBorderColour = () => {
    if (error && !inFormBuilder) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  return (
    <div className="">
      {type === "text" && (
        <div>
          {!hideLabels && (
            <>
              <div className="flex justify-between">
                <label
                  htmlFor="text"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {field.name ? field.name : "Untitled"}
                </label>
                {field.required && (
                  <span className="text-sm leading-6 text-gray-500">
                    Required
                  </span>
                )}
              </div>
              {field.description && (
                <p className="mt-1 text-sm/6 text-gray-500">
                  {field.description}
                </p>
              )}
            </>
          )}

          <Field
            data-field-type={type}
            // value={inFormBuilder ? "" : field.value}
            id={field.uuid}
            name={name ? name : field.uuid}
            type="text"
            className={`${getBorderColour()} mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
          />
        </div>
      )}

      {type === "longText" && (
        <div>
          {!hideLabels && (
            <>
              <div className="flex justify-between">
                <label
                  htmlFor="longText"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {field.name ? field.name : "Untitled"}
                </label>
                {field.required && (
                  <span
                    id="longText"
                    className="text-sm leading-6 text-gray-500"
                  >
                    Required
                  </span>
                )}
              </div>
              {field.description && (
                <p className="mt-1 text-sm/6 text-gray-500">
                  {field.description}
                </p>
              )}
            </>
          )}

          <div className="mt-2">
            <textarea
              data-field-type={type}
              id={field.uuid}
              name={name ? name : field.uuid}
              rows={4}
              className={`${getBorderColour()} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:bg-zinc-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-white`}
              defaultValue={inFormBuilder ? "" : field.value}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setFieldValue(field.uuid, e.target.value)
              }
            />
          </div>
        </div>
      )}
      {!inFormBuilder && (
        <>
          {error && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </>
      )}
    </div>
  );
};
