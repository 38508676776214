import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  InfoIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  SmileIcon,
} from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AccreditationGroup } from "@/models/accreditation";
import { Menu } from "@headlessui/react";
import { EmojiPicker } from "@/crud/form/EmojiPicker";
import { Formik, FormikValues } from "formik";
import yup from "@/crud/yup-extended";
import { debounce, some } from "lodash";
import axios from "axios";
import { BASE_URL } from "@/dashboardQueries";
import { GroupOptionsButton } from "./GroupOptionsDropdown";

interface Props {
  group: AccreditationGroup;
  openCreateGroupDrawer: () => void;
  onGroupUpdate: () => void;
}

export const GroupDetails = ({
  group,
  openCreateGroupDrawer,
  onGroupUpdate,
}: Props) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingIcon, setIsEditingIcon] = useState(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const descriptionInputRef = useRef<HTMLInputElement>(null);
  // Focus on description when you edit it
  useEffect(() => {
    if (isEditingDescription && descriptionInputRef.current) {
      descriptionInputRef.current.focus();
    }
  }, [isEditingDescription]);

  const handleNameClick = () => {
    setIsEditingName(true);
  };

  const handleDescriptionClick = () => {
    setIsEditingDescription(true);
  };

  const handleIconClick = () => {
    setEmojiPickerOpen(true);
    setIsEditingIcon(true);
  };

  const debouncedSubmit = useCallback(
    debounce((handleSubmit) => {
      handleSubmit();
    }, 750),
    []
  );

  const updateGroupDetails = async (values: FormikValues) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/accreditation/groups/${group.id}`,
        values
      );
      onGroupUpdate();
    } catch (error) {}
  };

  return (
    <div className="lg:flex lg:items-center lg:justify-between hover-parent cursor-text">
      <div className="min-w-0 flex-1">
        {false ? (
          <div className="animate-pulse">
            <div className="h-4 bg-gray-300 dark:bg-zinc-800 rounded w-1/4 mb-4"></div>
            <div className="h-8 bg-gray-300 dark:bg-zinc-800 rounded w-1/2 mb-2"></div>
          </div>
        ) : (
          <Formik
            initialValues={{
              name: group.name,
              description: group.description,
              icon: group.icon,
            }}
            validationSchema={yup.object({
              name: yup.string().required("Name is required"),
              description: yup.string().nullable(),
              icon: yup.string().nullable(),
            })}
            onSubmit={(values: FormikValues) => {
              updateGroupDetails(values);
            }}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              useEffect(() => {
                debouncedSubmit(handleSubmit);
                return () => {
                  debouncedSubmit.cancel();
                };
              }, [values.name, values.description, values.icon]);

              return (
                <form>
                  <div className="ml-2">
                    <nav aria-label="Breadcrumb" className="flex">
                      <ol role="list" className="flex items-center space-x-4">
                        <li>
                          <div className="flex items-center">
                            <Link
                              to={`/dashboard/accreditation/groups`}
                              className="text-sm font-medium dark:text-gray-300 hover:text-white text-gray-500"
                            >
                              Groups
                            </Link>
                          </div>
                        </li>
                        {group?.hierarchy.map((item, index) => (
                          <li key={index}>
                            <div className="flex items-center">
                              <ChevronRightIcon
                                aria-hidden="true"
                                className="size-5 shrink-0 text-gray-500"
                              />
                              <Link
                                to={`/dashboard/accreditation/groups/${item.id}`}
                                className={`ml-4 text-sm font-medium hover:text-white ${
                                  index === group.hierarchy.length - 1
                                    ? "text-gray-800 dark:text-white"
                                    : "dark:text-gray-300 text-gray-500"
                                }`}
                              >
                                {item.name}
                              </Link>
                            </div>
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className="relative flex items-center mt-4">
                    <div className={values.icon ? "ml-1.5 mr-1" : ""}>
                      {isEditingIcon ? (
                        <EmojiPicker
                          name="icon"
                          open={emojiPickerOpen}
                          onSelect={(emoji: any) => {
                            setFieldValue("icon", emoji);
                            handleSubmit();
                            setIsEditingIcon(false);
                          }}
                        />
                      ) : (
                        <span
                          className="text-2xl cursor-pointer"
                          onClick={handleIconClick}
                        >
                          {values.icon}
                        </span>
                      )}
                    </div>
                    {isEditingName ? (
                      <input
                        className="focus:ring-0 focus:ring-offset-0 p-0 pl-1.5 text-2xl/7 font-bold text-white sm:truncate sm:text-3xl sm:tracking-tight bg-transparent border-none focus:outline-none"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          debouncedSubmit(handleSubmit);
                        }}
                      />
                    ) : (
                      <h2
                        className="text-2xl/7 font-bold dark:text-white sm:truncate sm:text-3xl sm:tracking-tight cursor-pointer"
                        onClick={handleNameClick}
                      >
                        <span className="ml-1.5">{values.name}</span>
                      </h2>
                    )}
                  </div>
                  <div className="ml-1.5 mt-2">
                    {isEditingDescription ? (
                      <input
                        type="text"
                        name="description"
                        ref={descriptionInputRef}
                        className="w-full focus:ring-0 focus:ring-offset-0 p-0 dark:text-gray-400 sm:truncate bg-transparent border-none focus:outline-none"
                        value={values.description ? values.description : ""}
                        onChange={(e) => {
                          handleChange(e);
                          debouncedSubmit(handleSubmit);
                        }}
                        // onBlur={handleSubmit}
                      />
                    ) : (
                      group?.description && (
                        <div className="">
                          <p
                            className="dark:text-gray-400 cursor-pointer"
                            onClick={handleDescriptionClick}
                          >
                            {group?.description}
                          </p>
                        </div>
                      )
                    )}
                    {(!values.description || !values.icon) && (
                      <div className="text-white -ml-0.5">
                        <div className="flex">
                          <div className="flex text-zinc-400 text-sm">
                            {!values.icon && !isEditingIcon && (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsEditingIcon(true);
                                  setEmojiPickerOpen(true);
                                }}
                                className="flex items-center mr-2 dark:hover:bg-zinc-800 py-1 px-1.5 rounded-md"
                              >
                                <SmileIcon className="h-4 w-4 mr-1.5" />
                                Add icon
                              </button>
                            )}
                            {!values.description && !isEditingDescription && (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsEditingDescription(true);
                                  descriptionInputRef.current?.focus();
                                }}
                                className="flex items-center dark:hover:bg-zinc-800 py-1 px-1.5 rounded-md text-gray-500 dark:text-gray-300"
                              >
                                <InfoIcon className="h-4 w-4 mr-1.5" />
                                Add description
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <GroupOptionsButton isInGroup />
      </div>
    </div>
  );
};
