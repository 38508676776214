import yup from "@/crud/yup-extended";
import { BASE_URL, getTickets } from "@/dashboardQueries";
import {
  DashboardTicket,
  PaginatedResponse,
  TicketAnswer,
} from "@/models/Dashboard";
import { Ticket } from "@/models/Tickets";
import { useAccreditationStore } from "@/store/accreditation";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Field, Formik, FormikValues } from "formik";
import { ChangeEvent, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Fragment } from "react/jsx-runtime";
import { Pagination } from "../Pagination";
import { Input } from "@/crud/form/Input";
import { AccreditationGroup } from "@/models/accreditation";
import { set } from "lodash";
import { invalidateAccreditationGroups } from "@/utils/accreditation";
import { TrashIcon, XIcon } from "lucide-react";

const fetchTickets = async (
  page: number
): Promise<PaginatedResponse<DashboardTicket>> => {
  const { data } = await axios.get(
    `${BASE_URL}/dashboard/tickets?pageSize=6${page ? "&page=" + page : ""}`
  );
  return data;
};

interface Props {
  group: AccreditationGroup;
}

interface TicketValue {
  name: string;
  quantity: string;
}

interface FormValues {
  tickets: Record<string, TicketValue>;
}

export const AllocateTickets = ({ group }: Props) => {
  const queryClient = useQueryClient();
  const { allocateTicketsOpen, setAllocateTicketsOpen } =
    useAccreditationStore();
  const [page, setPage] = useState(1);

  // Fetching group details
  const { data, error, isLoading } = useQuery<
    PaginatedResponse<DashboardTicket>
  >(["tickets", page], () => fetchTickets(page), {
    keepPreviousData: true,
  });

  const ITEMS_PER_PAGE = 20; // Maximum number of items per page
  const ITEM_HEIGHT = 39.8; // Height of each item in pixels (adjust as needed)

  const allocateTickets = async (values: FormikValues) => {
    try {
      await axios.post(
        `${BASE_URL}/accreditation/groups/${group.id}/allocate-tickets`,
        values
      );
      invalidateAccreditationGroups(queryClient, group.id);
      setAllocateTicketsOpen(false);
    } catch (error) {}
  };

  const initialValues: FormValues = {
    tickets: group.groupAllocatedTickets.reduce((acc, ticketAllocation) => {
      acc[ticketAllocation.ticket.id] = {
        name: ticketAllocation.ticket.name,
        quantity: ticketAllocation.quantity.toString(),
      };
      return acc;
    }, {} as Record<string, TicketValue>),
  };

  return (
    <>
      <Transition.Root show={allocateTicketsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          // initialFocus={cancelButtonRef}
          onClose={setAllocateTicketsOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-dark-secondary dark:bg-opacity-80 backdrop-blur-lg bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center p-4 text-center h-full items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-dark-primary px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                  <div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={yup.object({})}
                      onSubmit={async (values: FormikValues) => {
                        const formattedValues = Object.entries(values.tickets)
                          .filter(
                            ([ticketId, value]) =>
                              value &&
                              typeof value === "object" &&
                              "quantity" in value &&
                              !isNaN(Number(value.quantity)) &&
                              Number(value.quantity) > 0
                          )
                          .map(([ticketId, { name, quantity }]) => ({
                            ticket: ticketId,
                            name: name,
                            quantity: Number(quantity),
                          }));
                        await allocateTickets(formattedValues);
                      }}
                      enableReinitialize
                      validateOnBlur={false}
                      validateOnChange={false}
                    >
                      {({ errors, handleSubmit, values, setFieldValue }) => {
                        const handleQuantityChange = (
                          setFieldValue: any,
                          handleSubmit: any,
                          ticketId: string,
                          ticketName: string,
                          value: string
                        ) => {
                          setFieldValue(`tickets.${ticketId}`, {
                            name: ticketName,
                            quantity: value,
                          });
                        };

                        return (
                          <form>
                            <div className="flex">
                              <div className="w-3/5 border-r dark:border-white/5 pr-6">
                                <div className="mb-4">
                                  <h3 className="text-lg font-semibold text-white">
                                    Available Tickets
                                  </h3>
                                  <p className="text-sm text-gray-400">
                                    Allocate tickets to the group
                                  </p>
                                </div>
                                {data && (
                                  <div className="">
                                    {data.results.map(
                                      (ticket: DashboardTicket) => (
                                        <div className="flex pl-3 items-center border-b dark:border-white/5">
                                          <div className="w-2/3">
                                            <h4 className="dark:text-white font-medium text-sm">
                                              {ticket.name}
                                            </h4>
                                          </div>
                                          <div className="flex-1 flex justify-end items-center">
                                            <Field
                                              name={`tickets.${ticket.id}`}
                                              type="text"
                                              id={`tickets.${ticket.id}`}
                                              value={
                                                values.tickets[ticket.id]
                                                  ?.quantity
                                              }
                                              className="my-2 h-7 dark:ring-white/5 ring-gray-300 block dark:bg-dark-secondary dark:text-gray-400 w-11 text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                              onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                              ) =>
                                                handleQuantityChange(
                                                  setFieldValue,
                                                  handleSubmit,
                                                  ticket.id.toString(),
                                                  ticket.name,
                                                  e.target.value
                                                )
                                              }
                                            />

                                            <button
                                              onClick={(e) => {
                                                setFieldValue(
                                                  `tickets.${ticket.id}.quantity`,
                                                  ""
                                                );
                                              }}
                                              type="button"
                                              className="ml-2 h-7 inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20"
                                            >
                                              <XIcon className="text-red-500 h-4 w-4" />
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <div className="mt-4">
                                      <Pagination
                                        currentPage={page}
                                        totalPages={Math.ceil(
                                          data.count / data.perPage
                                        )}
                                        onPageChange={(page: number) =>
                                          setPage(page)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="w-2/5 ml-6 relative">
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  className="-mb-1 absolute bottom-0 w-full flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                                >
                                  Allocate tickets
                                </button>
                                <div className="mb-4">
                                  <h3 className="text-lg font-semibold text-white">
                                    Allocated Tickets
                                  </h3>
                                  <p className="text-sm text-gray-400">
                                    Review and modify allocations
                                  </p>
                                </div>
                                <div className="">
                                  {values.tickets &&
                                  Object.entries(values.tickets).filter(
                                    ([ticketId, value]) =>
                                      (value as TicketValue).quantity !== "0" &&
                                      (value as TicketValue).quantity !==
                                        null &&
                                      (value as TicketValue).quantity !== ""
                                  ).length > 0 ? (
                                    <>
                                      {Object.entries(values.tickets)
                                        .filter(
                                          ([ticketId, value]) =>
                                            (value as TicketValue).quantity !==
                                              "0" &&
                                            (value as TicketValue).quantity !==
                                              null &&
                                            (value as TicketValue).quantity !==
                                              ""
                                        )
                                        .map(([ticketId, value]) => (
                                          <div key={ticketId} className="flex">
                                            <h3 className="dark:text-white text-medium font-medium text-sm">
                                              {(value as TicketValue).name}
                                            </h3>
                                            <div className="flex-1 flex justify-end items-center">
                                              <h3 className="dark:text-white text-medium font-medium text-sm">
                                                <span className="dark:text-zinc-400 mr-2">
                                                  x
                                                  {
                                                    (value as TicketValue)
                                                      .quantity
                                                  }
                                                </span>
                                              </h3>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  ) : (
                                    <p className="text-sm text-gray-400">
                                      No tickets allocated yet.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
