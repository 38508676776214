import { Loading } from "@/components/portal/Loading";
import { FileUploadField } from "@/crud/form/FileUploadField";
import { Switch } from "@/crud/form/Switch";
import yup from "@/crud/yup-extended";
import { BASE_URL } from "@/dashboardQueries";
import { useDarkMode } from "@/hooks";
import { useFormStore } from "@/store/form";
import { classNames } from "@/utils/styles";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Formik, FormikValues, getIn, useFormikContext } from "formik";
import { Paintbrush, Trash, UploadIcon } from "lucide-react";
import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Fragment } from "react/jsx-runtime";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const navigation = [
  {
    name: "Branding",
    href: "#",
    icon: Paintbrush,
    current: true,
  },
];

export const FormSettings = ({ open, setOpen }: Props) => {
  const isDarkMode = useDarkMode();
  const { form, setForm } = useFormStore();
  const [activeTab, setActiveTab] = useState("Branding");

  const UploadFile = ({ name, label }: { name: string; label: string }) => {
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    const value = getIn(values, name);

    const [isLoading, setIsLoading] = useState(false);

    // if the field is loading and we aren't submitting, set it to false
    useEffect(() => {
      if (!isSubmitting && isLoading) {
        setIsLoading(false);
      }
    }, [isSubmitting]);

    const onDrop = (files: File[]) => {
      // Set that its loading first
      if (files) {
        setIsLoading(true);
        setFieldValue(name, files[0]);
      }
    };

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      multiple: false,
    });

    return (
      <>
        <p className="mt-1 text-sm/6 text-gray-500">{label}</p>
        {isLoading ? (
          <div
            className={`mt-2 h-32 flex items-center justify-center border border-dashed border-gray-300 dark:border-zinc-700 rounded-lg p-4 text-center`}
          >
            <div className="text-center">
              <div className="flex items-center justify-center pl-4">
                <Loading colour={isDarkMode ? "white" : "gray"} />
              </div>
              <div className="dark:text-gray-400 mt-2">Uploading</div>
            </div>
          </div>
        ) : (
          <>
            {value && typeof value === "string" ? (
              <div className="h-32 bg-dark-primary mt-2 overflow-hidden relative">
                <button
                  type="button"
                  onClick={() => setFieldValue(name, null)}
                  className="absolute rounded-lg right-0 mt-2 mr-2 bg-white p-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center"
                >
                  <Trash className="h-4 w-4" />
                </button>
                <img src={value} className="object-cover" />
              </div>
            ) : (
              <div
                {...getRootProps()}
                className={`mt-2 h-32 flex items-center justify-center border border-dashed border-gray-300 dark:border-zinc-700 rounded-lg p-4 text-center`}
              >
                <div className="text-center">
                  <input {...getInputProps()} />
                  <div className="flex items-center justify-center">
                    <UploadIcon className="h-8 w-8 mb-2 dark:text-white" />
                  </div>
                  <p className="text-sm dark:text-white/40">
                    Drag & drop some files here, or click to select files
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-black/80 backdrop-blur-lg bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="h-full min-h-96 bg-purple-500 relative transform rounded-lg bg-white dark:bg-zinc-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                  <main className="min-h-full">
                    <div className="flex min-h-full">
                      <div className="w-1/4 dark:bg-zinc-800 p-6 rounded-l-lg min-h-96">
                        <div className="">
                          <h4 className="dark:text-gray-400 font-semibold text-sm">
                            Settings
                          </h4>
                        </div>
                        <nav
                          aria-label="Sidebar"
                          className="flex flex-1 flex-col mt-3 min-h-full"
                        >
                          <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                          >
                            <li>
                              <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                  <li key={item.name}>
                                    <button
                                      type="button"
                                      onClick={() => setActiveTab(item.name)}
                                      className={classNames(
                                        activeTab == item.name
                                          ? "bg-gray-50 text-indigo-600 dark:bg-dark-secondary dark:text-gray-400"
                                          : "text-gray-700 dark:text-gray-400 hover:bg-gray-50 hover:text-indigo-600 dark:hover:bg-dark-secondary dark:hover:text-gray-400 ",
                                        "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold w-full"
                                      )}
                                    >
                                      <item.icon
                                        aria-hidden="true"
                                        className={classNames(
                                          activeTab == item.name
                                            ? "text-indigo-600 dark:text-gray-400"
                                            : "text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-gray-400",
                                          "size-6 shrink-0"
                                        )}
                                      />
                                      {item.name}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div className="w-3/4 pl-10 mt- p-6">
                        <Formik
                          initialValues={form?.branding ? form.branding : {}}
                          onSubmit={async (values: FormikValues) => {
                            // fileFields is an array of keys, so we can iterate over it and append the file to the form data
                            const formData = new FormData();
                            Object.keys(values)
                              .filter((key) => values[key])
                              .map((key) => formData.append(key, values[key]));
                            // Replace with your endpoint
                            const response = await axios.put(
                              `${BASE_URL}/dashboard/forms/${form.id}`,
                              formData
                            );
                            setForm(response.data);
                          }}
                          validationSchema={yup.object().shape({})}
                          enableReinitialize
                        >
                          {({
                            values,
                            setFieldValue,
                            submitForm,
                            isSubmitting,
                          }) => {
                            useEffect(() => {
                              submitForm();
                            }, [values]);

                            return (
                              <form>
                                {activeTab === "Branding" && (
                                  <div>
                                    <div>
                                      <div className="flex justify-between">
                                        <label
                                          htmlFor="file-upload"
                                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                        >
                                          Logo
                                        </label>
                                      </div>

                                      <div className="flex">
                                        <div className="w-1/2 pr-2">
                                          <UploadFile
                                            name="logoLight"
                                            label="Light mode (dark logo)"
                                          />
                                        </div>
                                        <div className="w-1/2 pl-2">
                                          <UploadFile
                                            name="logoDark"
                                            label="Dark mode (light logo)"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <p
                                      id="logo-help-text"
                                      className="mt-2 text-sm/6 text-gray-600"
                                    >
                                      This is the logo shown at the top of the
                                      form in light/dark mode
                                    </p>

                                    <div className="mt-8">
                                      <div className="flex justify-between">
                                        <label
                                          htmlFor="file-upload"
                                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                                        >
                                          Background Image
                                        </label>
                                      </div>

                                      <div className="flex">
                                        <div className="w-1/2 pr-2">
                                          <UploadFile
                                            name="backgroundImageLight"
                                            label="Light mode"
                                          />
                                        </div>
                                        <div className="w-1/2 pl-2">
                                          <UploadFile
                                            name="backgroundImageDark"
                                            label="Dark mode"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <p
                                      id="background-image-help-text"
                                      className="mt-2 text-sm/6 text-gray-600"
                                    >
                                      This is the background image shown on the
                                      left side of the Desktop form
                                    </p>
                                  </div>
                                )}
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </main>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
