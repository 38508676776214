import { Field, useFormikContext } from "formik";
import { useFormStore } from "../../../store/form";
import { Input } from "../../../crud/form/Input";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, Fragment, useState } from "react";
import {
  FORM_FIELDS,
  FormFieldType,
  getCategoryForField,
} from "../../../constants/form";
import { FormField } from "./FormField";
import { FormField as FormFieldModel } from "../../../models/form";
import { Switch } from "../../../crud/form/Switch";
import { Textarea } from "@/crud/form/Textarea";
import { AddChoiceOptions } from "./AddChoiceOptions";
import { Quill } from "@/crud/form/Quill/Quill";
import { FieldRules } from "./fields/rules/FieldRules";
import { FieldDropdown } from "./FieldDropdown";
import { get } from "lodash";

export const FieldSettings = () => {
  const { selectedFields, activeField, setActiveField, setSelectedFields } =
    useFormStore();
  const [fieldTypeOpen, setFieldTypeOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<any>();

  const getFieldIndex = (fieldId: number) => {
    return values.fields.findIndex((field: any) => field.id === activeField.id);
  };

  const handleChange = (name: string, value: any) => {
    const fieldIndex = values.fields.findIndex(
      (field: any) => field.id === activeField.id
    );
    if (fieldIndex !== -1) {
      setFieldValue(`fields.${fieldIndex}.${name}`, value);
    }
  };

  const fieldIndex = getFieldIndex(activeField.id);

  const isNotContentField =
    activeField && activeField?.category?.name != "Content";

  const handleTypeChange = (newField: FormFieldModel) => {
    if (activeField) {
      const category = getCategoryForField(newField.name);
      const updatedField = {
        ...activeField,
        field: newField,
        category,
      };

      setFieldValue(`fields.${fieldIndex}`, updatedField);
      setActiveField(updatedField);
    }
  };

  return (
    <div>
      <label
        htmlFor="project-name"
        className="block text- font-medium leading-6 text-gray-900 dark:text-gray-300 mb-3"
      >
        Field Settings
      </label>
      <form>
        <Field name="id" type="hidden" />
        {activeField && activeField.field.type === "textContent" ? (
          <>
            <div>
              <label
                htmlFor="content"
                className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
              >
                Content
              </label>
              <div className="w-full">
                <Quill name={`fields.${fieldIndex}.content`} />
              </div>
            </div>
          </>
        ) : (
          <>
            {values.fields[fieldIndex] && (
              <>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
                  >
                    Name
                  </label>
                  <Input
                    name={`fields.${fieldIndex}.name`}
                    type="text"
                    required={true}
                    value={values.fields[fieldIndex].name || ""}
                    onChange={(e) =>
                      setFieldValue(`fields.${fieldIndex}.name`, e.target.value)
                    }
                  />
                </div>

                <div className="mt-2">
                  <label
                    htmlFor="description"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
                  >
                    Description
                  </label>
                  <Input
                    name={`fields.${fieldIndex}.description`}
                    type="text"
                    required={false}
                    value={values.fields[fieldIndex].description || ""}
                    onChange={(e) =>
                      setFieldValue(
                        `fields.${fieldIndex}.description`,
                        e.target.value
                      )
                    }
                  />
                </div>

                <div className="mt-2">
                  <label
                    htmlFor="helpText"
                    className="block text-xs font-medium leading-6 text-gray-900 sm:mt-1.5 dark:text-white mb-1"
                  >
                    Help text
                  </label>
                  <Input
                    name={`fields.${fieldIndex}.helpText`}
                    type="text"
                    required={false}
                    value={values.fields[fieldIndex].helpText || ""}
                    onChange={(e) =>
                      setFieldValue(
                        `fields.${fieldIndex}.helpText`,
                        e.target.value
                      )
                    }
                  />
                </div>
              </>
            )}
          </>
        )}

        <div className="dark:border-white/5 border-gray-200 border-t border-b py-3 mt-4">
          <FieldDropdown
            fields={Object.keys(FORM_FIELDS)
              .map((category) => {
                return FORM_FIELDS[category].fields.filter(
                  (field: FormFieldModel) =>
                    field.type !== activeField?.field.type
                );
              })
              .flat()}
            activeField={activeField?.field}
            handleFieldChange={(field: FormFieldModel) =>
              handleTypeChange(field)
            }
          />
        </div>

        {isNotContentField && (
          <div className="flex items-center mt-4">
            <div className="flex-1">
              <label
                htmlFor="required"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Required
              </label>
            </div>
            <Switch
              name={`fields.${fieldIndex}.required`}
              checked={values.required}
            />
          </div>
        )}

        {activeField &&
          (activeField.field.type === "dropdown" ||
            activeField.field.type === "multipleChoice") && (
            <div className="dark:border-white/5 border-gray-200 border-t mt-4">
              <AddChoiceOptions fieldIndex={fieldIndex} />
            </div>
          )}

        {/* {isNotContentField && values.fields.length > 1 && (
          <FieldRules index={fieldIndex} />
        )} */}
      </form>
    </div>
  );
};
