import { Switch } from "@/crud/form/Switch";

export const Documents = () => {
  return (
    <dl className="space-y-6 divide-y divide-gray-100 dark:divide-white/10 text-sm/6">
      <div className="pt-1">
        <div className="flex">
          <dt className="flex-1 pr-6 font-medium text-gray-900 sm:w-64 dark:text-white">
            Allow group contacts to upload documents
            <div className="text-xs font-normal text-gray-400 ">
              If enabled, group contacts can upload documents for this group in
              the portal.
            </div>
          </dt>
          <dd className="flex items-center justify-end">
            <Switch name="canUploadDocuments" />
          </dd>
        </div>
      </div>
    </dl>
  );
};
