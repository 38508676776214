import { Dropdown } from "@/crud/form/Dropdown";
import { BASE_URL } from "@/dashboardQueries";
import { PaginatedResponse } from "@/models/Dashboard";
import { Form } from "@/models/form";
import axios from "axios";
import { useQuery } from "react-query";

export const CompletesAForm = () => {
  const fetchForms = async (): Promise<PaginatedResponse<Form>> => {
    const { data } = await axios.get(`${BASE_URL}/dashboard/forms`);
    return data;
  };

  const {
    data: forms,
    error: formsError,
    isLoading: formsLoading,
  } = useQuery("forms", fetchForms);

  const formOptions =
    forms?.results.map((form) => ({
      id: form.id,
      value: form.name,
    })) || [];

  return (
    <div>
      <h4
        data-sidebar="group-label"
        className="px-3 border- border-white/5 text-xs text-stone-400 flex h-8 shrink-0 items-center text-sm font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
      >
        When an individual completes a form
      </h4>
      <div className="px-2.5">
        <div className="my-1">
          <Dropdown name="form" options={formOptions} />
        </div>
      </div>
    </div>
  );
};
