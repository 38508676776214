import {
  EyeIcon,
  FaceFrownIcon,
  PencilSquareIcon,
  PlusIcon,
  TicketIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChangeEvent, Fragment, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { DashboardLayout } from "../components/dashboard/layouts/DashboardLayout";
import { SkeletonLoading } from "../components/SkeletonLoading";
import { Table } from "../components/Table";
import { Base, PaginatedResponse, PaginationBase } from "../models/Dashboard";
import { CRUDResource } from "./models";
import {
  convertToSentenceCase,
  singularize,
  useSelectorByStringPath,
} from "./utils";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Create } from "./Create";
import { toHeaderCase, toPascalCase, toSentenceCase } from "js-convert-case";
import { useDebounce } from "usehooks-ts";
import { Search } from "./Search";
import { CRUDRenderer } from "./CRUDRenderer";
import { de, fi } from "date-fns/locale";
import { addSearchParam, constructQueryString } from "../utils/url";

interface Props<T, R> {
  resource: CRUDResource<T, R>;
  rowRenderer?: (record: T) => R;
  useStandardLayout?: boolean;
}

export const List = <T extends object, R extends ReactNode>({
  resource,
  rowRenderer,
  useStandardLayout,
}: Props<T, R>) => {
  const navigate = useNavigate();
  const resourceName = resource.name.replaceAll(" ", "");
  const location = useLocation();
  const isLoading = useSelectorByStringPath(`${resource.name}.IsLoading`);

  const selectedEvent = useSelector(
    (state: State) => state.dashboard.selectedEvent
  );

  const dispatch = useDispatch();
  const [recordToDelete, setRecordToDelete] = useState<Base | null>(null);
  const [recordToView, setRecordToView] = useState<Base | null>(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const createSlideOverOpen = useSelectorByStringPath(
    `${resource.name}.CreateSlideOverOpen`
  );

  // For search
  const [search, setSearch] = useState<string>("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedValue = useDebounce<string>(search, 500);

  // Filters
  const [selectedFilters, setSelectedFilters] = useState("");
  const [rawFilters, setRawFilters] = useState<Array<any>>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchChange = (
    event: ChangeEvent<HTMLInputElement> | string,
    isClear?: boolean
  ) => {
    const params = new URLSearchParams(location.search);

    const search = typeof event === "string" ? event : event.target.value;

    // if we have a debounced and there is no search query param yet, we are just starting the search, so clear the page
    if (search && !params.get("search")) {
      params.delete("page");
      setSearchParams(params);
    }

    // Search has changed so we need to remove the page param
    // or search is empty, in which case we should remove the page param
    if (debouncedValue) {
      params.delete("page");
      setSearchParams(params);
    }

    // If the search is an empty string, remove the search param
    if ((typeof event === "string" && event === "") || search === "") {
      params.delete("search");
      params.delete("page");
      setSearchParams(params);
    }
    setIsSearching(true);
    setSearch(typeof event === "string" ? event : event.target.value);

    // if we are clearing, just search once
    if (isClear) {
      performQueries(1, "", true);
      setIsSearching(false);
    }
  };

  const isUpdating = useSelectorByStringPath(
    `${resource.name}.IsUpdating`
  ) as boolean;

  // Check Component Load Debounce
  // useEffect(() => {
  //   setSearch("");
  // }, [location]);

  // When the page changes, clear the filters
  useEffect(() => {
    setSelectedFilters("");
    setRawFilters([]);
  }, [location.pathname]);

  const performQueries = (
    pageOverride?: number,
    searchOverride?: string,
    isClear?: boolean
  ) => {
    // Query param stuff to remember pages, searches and queries
    const params = new URLSearchParams(location.search);

    // Try get page from URL
    const page = params.get("page");

    const search =
      searchOverride != undefined ? searchOverride : params.get("search");

    // Extract all remaining query parameters as filters
    const filters: { [key: string]: string } = {};

    // get the params that aren't page or search
    params.forEach((value, key) => {
      if (key !== "page" && key !== "search") {
        filters[key] = value;
      }
    });
    const parsedQueryString = constructQueryString(filters);

    // if we have a debounced value or a search from the URL
    if (debouncedValue || search) {
      // Search
      dispatch({
        type: `${resourceName}/SearchAll`,
        payload: {
          query: debouncedValue ? debouncedValue : search,
          page: pageOverride ? pageOverride : page ? page : 1,
          filters: parsedQueryString ? parsedQueryString : selectedFilters,
        },
      });
      // Only add the search to the URL if it's not already there
      if (debouncedValue && !isClear) {
        // Set the search in the URL
        addSearchParam(searchParams, setSearchParams, "search", debouncedValue);
      }
    } else {
      dispatch({
        type: `${resourceName}/GetAll`,
        payload: {
          page: pageOverride ? pageOverride : page ? page : 1,
          filters: parsedQueryString ? parsedQueryString : selectedFilters,
        },
      });
    }

    if (pageOverride && !isClear) {
      addSearchParam(
        searchParams,
        setSearchParams,
        "page",
        pageOverride.toString()
      );
    }
  };

  // Fetch API (optional)
  useEffect(() => {
    performQueries();
    setIsSearching(false);
  }, [debouncedValue, selectedFilters, selectedEvent]);

  // Check for /create in URL and open Slide Over if it's there
  useEffect(() => {
    if (location.pathname.includes("/create")) {
      // setCreateSlideOverOpen(true);
    }
  }, [location.pathname]);

  // As we open and close the slide over we need to add to the path
  const setCreateSlideOverOpen = (isOpen: boolean) => {
    dispatch({
      type: `${resourceName}/SetCreateSlideOverOpen`,
      payload: { createSlideOverOpen: isOpen },
    });
  };

  const closeDeleteModal = () => {
    setRecordToDelete(null);
  };

  const closeViewModal = () => {
    setViewModalOpen(false);
    setTimeout(() => {
      setRecordToView(null);
    }, 500);
  };

  const allResources = useSelectorByStringPath(
    `${resourceName}.All`
  ) as PaginatedResponse<any>;

  // For handling loading done
  useEffect(() => {
    dispatch({
      type: `${resourceName}/SetIsLoading`,
      payload: { isLoading: !!!allResources },
    });
  }, [allResources]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    dispatch({
      type: `${resourceName}/GetAll`,
      payload: { page: page ? page : 1 },
    });
    // Reset search
    // setSearch("");
  }, [location.pathname]);

  // useEffect(() => {
  //   if (!debouncedValue) {
  //     console.log("getting again for some reason?");
  //     if (allResources?.results[0]) {
  //       dispatch({
  //         type: `${resource.name}/Get`,
  //         payload: { id: allResources.results[0].id },
  //       });
  //     }
  //   }
  //   // Reset search
  //   // setSearch("");
  // }, [allResources]);

  // Does what needs to be done when the instance is clicked to be edited
  const handleUpdateInstance = (id: number) => {
    if (resource.editPage) {
      navigate(`${resource.editPage}/${id}`);
    } else {
      setCreateSlideOverOpen(true);
      dispatch({
        type: `${resourceName}/Get`,
        payload: { id },
      });
      dispatch({
        type: `${resourceName}/SetIsUpdating`,
        payload: { isUpdating: true },
      });
    }
  };

  const content = (
    <>
      {/* Only show create slideover if it has a validation schema, i.e. creation is managed by CRUD */}
      {resource.validationSchema && (
        <>
          <Create
            isUpdating={isUpdating}
            resource={resource}
            createSlideOverOpen={createSlideOverOpen}
            setCreateSlideOverOpen={setCreateSlideOverOpen}
          />
        </>
      )}

      {/* View Modal */}
      {resource.itemRenderer && (
        <Transition.Root show={viewModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            // initialFocus={cancelButtonRef}
            onClose={closeViewModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-40 bg-opacity-75 backdrop-blur-md transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                    <div className="absolute right-0 top-0 mr-5 mt-5">
                      <XMarkIcon
                        className="w-6 h-6 text-gray-400 cursor-pointer"
                        onClick={closeViewModal}
                      />
                    </div>
                    {recordToView && resource.itemRenderer(recordToView as T)}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {/* Delete Modal */}
      <Transition.Root show={!!recordToDelete} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          // initialFocus={cancelButtonRef}
          onClose={closeDeleteModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-zinc-900 dark:bg-opacity-40 bg-opacity-75 backdrop-blur-md transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-dark-primary bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        Delete {toHeaderCase(singularize(resource.name))}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Are you sure you want to delete? All of your data will
                          be permanently removed forever. This action cannot be
                          undone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      id="confirm-delete"
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                      onClick={() => {
                        if (recordToDelete) {
                          dispatch({
                            type: `${resourceName}/Delete`,
                            payload: { id: recordToDelete.id },
                          });
                          closeDeleteModal();
                        }
                      }}
                    >
                      Yes, delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => closeDeleteModal()}
                      // ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {isLoading ? (
        <div className="p-8">
          <SkeletonLoading />
        </div>
      ) : (
        <div className="overflow-x-scroll">
          {/* Search for when there is no results */}
          {debouncedValue && allResources.results.length === 0 && (
            <Search
              getFilters={resource.getFilters}
              isSearching={isSearching}
              handleSearchChange={handleSearchChange}
              debouncedValue={debouncedValue}
              setSelectedFilters={setSelectedFilters}
              rawFilters={rawFilters}
              setRawFilters={setRawFilters}
            />
          )}
          {allResources &&
          allResources.results &&
          allResources?.results.length > 0 ? (
            <>
              <Search
                getFilters={resource.getFilters}
                isSearching={isSearching}
                handleSearchChange={handleSearchChange}
                debouncedValue={debouncedValue}
                setSelectedFilters={setSelectedFilters}
                rawFilters={rawFilters}
                setRawFilters={setRawFilters}
              />
              <Table
                headers={
                  resource.headers
                    ? resource.headers.concat([""])
                    : Object.keys(allResources.results[0])
                        .map((header: string) => toSentenceCase(header))
                        .concat([""])
                }
                records={allResources.results}
                pagination={allResources as PaginationBase}
                getPageOfRecords={(page: number) => {
                  performQueries(page);
                }}
                rowRenderer={(record: any) => (
                  <>
                    {resource.rowRenderer ? (
                      <>{resource.rowRenderer(record)}</>
                    ) : (
                      <>
                        {Object.keys(record).map((key) => {
                          return (
                            <td className="px-6 py-3 text-sm font-medium text-gray-500">
                              <div className="flex items-center space-x-2">
                                <div className="flex flex-shrink-0 -space-x-1">
                                  {record[key]}
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </>
                    )}
                    <td className="table-cell px-6 py-3 text-sm font-medium text-gray-500 dark:text-gray-400 flex justify-end items-center">
                      <div className="flex items-center space-x-2 justify-end">
                        <div className="flex">
                          {resource.itemRenderer && (
                            <button
                              onClick={() => {
                                setRecordToView(record);
                                setViewModalOpen(true);
                              }}
                              type="button"
                              className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/20 mr-2"
                            >
                              <EyeIcon className="text-blue-500 h-4 w-4" />
                            </button>
                          )}
                          {resource.rowButtons && resource.rowButtons(record)}
                          <button
                            id="delete"
                            onClick={() => setRecordToDelete(record)}
                            type="button"
                            className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20"
                          >
                            <TrashIcon className="text-red-500 h-4 w-4" />
                          </button>
                          <button
                            onClick={() => handleUpdateInstance(record.id)}
                            type="button"
                            className="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/20 ml-2"
                          >
                            <PencilSquareIcon className="text-green-500 h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </>
                )}
              />
            </>
          ) : (
            <div className="w-full pb-36 pt-28">
              <div className="text-center">
                {debouncedValue ? (
                  <>
                    <span className="text-3xl">☹️</span>
                    {/* <FaceFrownIcon className="mx-auto h-12 w-12 text-gray-400" /> */}
                    <h3 className="mt-2 text-xl font-semibold text-gray-900 dark:text-white">
                      No results for that search
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Sorry about that... Maybe try searching something else
                    </p>
                  </>
                ) : (
                  <>
                    <h3 className="mt-2 text-xl font-semibold text-gray-900 dark:text-white">
                      No {toHeaderCase(resource.name)} yet
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      You can start by creating a{" "}
                      {toHeaderCase(singularize(resource.name))} below
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        onClick={() => {
                          if (resource.createPage) {
                            navigate(resource.createPage);
                          } else {
                            setCreateSlideOverOpen(true);
                          }
                        }}
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <PlusIcon
                          className="-ml-0.5 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create {toHeaderCase(singularize(resource.name))}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );

  return useStandardLayout ? (
    content
  ) : (
    <DashboardLayout
      requiredPermissions={[`view_${resource.permissionPrefix}`]}
      pageTitle={convertToSentenceCase(resource.name)}
      rightControls={
        <>
          <button
            onClick={() => {
              if (resource.createPage) {
                navigate(resource.createPage);
              } else {
                setCreateSlideOverOpen(true);
              }
            }}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create
          </button>
        </>
      }
    >
      {content}
    </DashboardLayout>
  );
};
