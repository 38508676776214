import { FileFieldResponse } from "@/models/form";
import { Menu } from "@headlessui/react";
import {
  Ellipsis,
  EllipsisVerticalIcon,
  FileUpIcon,
  MessageCircle,
  Sparkle,
  SparklesIcon,
} from "lucide-react";
import React, { useState } from "react";

interface Props {
  file: FileFieldResponse;
}

export const FileResponseField = ({ file }: Props) => {
  const [open, setOpen] = useState(false);

  const handleEllipsisClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleFileClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(file.url, "_blank");
  };

  return (
    <div className="relative">
      <div
        className="flex group items-center hover:bg-zinc-800 p-2 rounded-md"
        onClick={handleFileClick}
      >
        <div>
          <FileUpIcon className="h-4 w-4 text-gray-400" />{" "}
        </div>
        <div className="ml-2">
          <h4 className="text-gray-400 truncate">{file.name}</h4>
        </div>
        {!open && (
          <div
            className="ml-4 flex items-center flex-1 justify-end text-green-500 cursor-pointer mr-1"
            onClick={handleEllipsisClick}
          >
            <SparklesIcon className="h-4 w-4 mr-1" />
            Ask AI
          </div>
        )}
      </div>
      {open && (
        <div>
          <div className="absolute w-full z-40 bg-red-500 flex">
            <div className="bg-white inline-flex p-1 rounded-full">
              <SparklesIcon className="h-5 w-5 text-dark-primary" />
            </div>
            <div className="flex-1 flex items-center">
              <input
                type="text"
                className="w-full p-0 text-sm"
                placeholder={`Ask AI anything about ${file.name}`}
              />
            </div>
          </div>
          <div className="absolute bg-red-500 w-2/5 mt-9">
            <div>recommended</div>
          </div>
        </div>
      )}
    </div>
  );
};
