import { Listbox, Transition } from "@headlessui/react";
import { FormField } from "./FormField";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react/jsx-runtime";
import { FormField as FormFieldModel } from "../../../models/form";
import { getCategoryForField } from "@/constants/form";

export interface FormFieldWithOptionalId extends FormFieldModel {
  id?: number;
}

interface Props {
  fields: FormFieldWithOptionalId[];
  activeField: FormFieldModel;
  handleFieldChange: (field: FormFieldWithOptionalId) => void;
}

export const FieldDropdown = ({
  fields,
  activeField,
  handleFieldChange,
}: Props) => {
  return (
    <Listbox
      value={activeField || {}}
      onChange={(newField: FormFieldModel) => handleFieldChange(newField)}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`flex dark:ring-white/5 ring-gray-300 w-full relative cursor-default rounded-md bg-white dark:bg-dark-secondary dark:text-gray-400 p-1 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            >
              {activeField && (
                <div className="w-full flex">
                  <div className="flex-1">
                    <FormField
                      field={activeField}
                      category={getCategoryForField(activeField.name)}
                      onClick={() => handleFieldChange(activeField)}
                    />
                  </div>
                  <div className="flex items-center justify-center pr-1">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Listbox.Options className="py-1 px-1 absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-dark-secondary dark:ring-white/5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {fields.map((field: FormFieldModel, index: number) => {
                  const category = getCategoryForField(field.name);
                  return (
                    <Listbox.Option key={index} value={index}>
                      <FormField
                        key={index}
                        field={field}
                        category={category}
                        onClick={() => handleFieldChange(field)}
                      />
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
