import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, HomeIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Field, useFormik, useFormikContext } from "formik";
import { debounce } from "lodash";

export const FormBreadcrumbs = () => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { values } = useFormikContext();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol role="list" className="flex items-center space-x-4 w-full">
        <li>
          <div className="flex items-center z-10 relative">
            <Link
              to="/dashboard/forms"
              className="flex ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              <PencilSquareIcon className="size-5 shrink-0 text-gray-400 mr-3" />
              Forms
            </Link>
          </div>
        </li>
        <li className="w-full">
          <div
            id="form-name-container"
            className="flex items-center z-10 relative cursor-text"
            onClick={() => setIsEditing(true)}
          >
            <ChevronRightIcon
              aria-hidden="true"
              className="size-5 shrink-0 text-gray-400"
            />
            {isEditing ? (
              <Field
                innerRef={inputRef}
                type="text"
                name="name"
                className="flex-1 w-full p-0 bg-transparent ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 border-none focus:outline-none focus:ring-0 focus:ring-offset-0"
              />
            ) : (
              <Link
                to="#"
                aria-current="page"
                className="flex ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-text"
                onClick={() => setIsEditing(true)}
              >
                {values.name}
              </Link>
            )}
          </div>
        </li>
      </ol>
    </nav>
  );
};
