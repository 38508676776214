import React, { useState, useEffect, Fragment } from "react";
import {
  Appearance,
  loadStripe,
  PaymentIntent,
  SetupIntent,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useSelector } from "react-redux";
import { State } from "../store/store";
import { PaymentDetailsForm } from "../forms/PaymentDetailsForm";
import { Transition } from "@headlessui/react";
import { PaymentPlanSchedule } from "./PaymentPlanSchedule";
import { Order, OrderResponse } from "../models/Order";
import { Card, CardContent } from "./ui/card";
import { useDarkMode } from "usehooks-ts";

interface BaseProps {
  clientSecret: string;
  redirectUrl?: string;
  stripeAccountId: string;
  buttonText?: string;
  onPaymentSuccess?: (intent: PaymentIntent | SetupIntent) => void;
}

interface TotalProps extends BaseProps {
  total: number | undefined;
  monthlyTotal?: number | undefined;
}

interface MonthlyTotalProps extends BaseProps {
  monthlyTotal: number | undefined;
  total?: never;
}

export type PaymentDetailsProps = TotalProps | MonthlyTotalProps;

export const PaymentDetails = ({
  clientSecret,
  redirectUrl,
  total,
  monthlyTotal,
  stripeAccountId,
  buttonText,
  onPaymentSuccess,
}: PaymentDetailsProps) => {
  const darkMode = useDarkMode();
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );

  const stripePromise = loadStripe(
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId,
    }
  );

  const appearance: Appearance = {
    theme: "stripe",
    variables: {
      colorText: darkMode.isDarkMode ? "#ffffff" : "",
      fontFamily: "Finlandica, sans-serif",
    },
    rules: {
      ".Label": {
        padding: "6px 0",
        fontWeight: "500",
        // color: darkMode.isDarkMode ? "#ffffff" : "#000000",
      },
      ".Input": {
        backgroundColor: darkMode.isDarkMode ? "#27272a" : "",
        borderColor: darkMode.isDarkMode ? "#3f3f46" : "",
      },
      ".Tab": {
        backgroundColor: darkMode.isDarkMode ? "#27272a" : "",
        borderColor: darkMode.isDarkMode ? "#3f3f46" : "",
      },
    },
  };

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Finlandica:wght@400;500;700&display=swap",
      },
    ],
  };

  return (
    <div className="">
      <Transition
        as={Fragment}
        show={!!clientSecret}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95 "
      >
        <div className="lg:pl-0.5">
          {total && monthlyTotal && (
            <PaymentPlanSchedule
              total={total}
              monthlyTotal={monthlyTotal}
              showPaymentTerms={true}
            />
          )}
          {clientSecret && total && (
            <Elements options={options} stripe={stripePromise}>
              <PaymentDetailsForm
                clientSecret={clientSecret}
                total={total}
                monthlyTotal={monthlyTotal}
                redirectUrl={redirectUrl}
                buttonText={buttonText}
                onPaymentSuccess={onPaymentSuccess}
                stripeAccountId={stripeAccountId}
              />
            </Elements>
          )}
        </div>
      </Transition>
      {billingDetailsSubmitting && (
        <div className="flex items-center justify-center w-full h-64">
          <svg
            className="animate-spin h-12 w-12 text-blue-200"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
    </div>
  );
};
