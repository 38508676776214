import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  BanknotesIcon,
  HomeIcon,
  InboxArrowDownIcon,
  TicketIcon,
  UsersIcon,
  XMarkIcon,
  TagIcon,
  ClockIcon,
  PlusCircleIcon,
  BuildingStorefrontIcon,
  QrCodeIcon,
  DocumentChartBarIcon,
  PencilSquareIcon,
  CubeIcon,
  BoltIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { dashboardActions } from "../../../store/dashboard";
import { EventSelector } from "./EventSelector";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import {
  EMAIL_KEY,
  ORGANISATION_KEY,
  signOut,
} from "../../../pages/protected-route";
import { useDarkMode } from "usehooks-ts";
import { AccountMenu } from "./account-menu/AccountMenu";
import {
  ChevronDownCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  Send,
  TicketCheckIcon,
  Workflow,
} from "lucide-react";
import { fi } from "date-fns/locale";
import { Organisation } from "@/models/Dashboard";

export const Nav = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(
    (state: State) => state.dashboard.sidebarOpen
  );
  const darkMode = useDarkMode();

  const setSidebarOpen = (sidebarOpen: boolean) => {
    dispatch(dashboardActions.SetSideBarOpen({ sidebarOpen }));
  };

  const organisation: Organisation = JSON.parse(
    localStorage.getItem(ORGANISATION_KEY) || "{}"
  );

  const featureFlags = {
    accreditationEmails: organisation.accreditationEmailsEnabled,
    accreditationGroups: organisation.accreditationGroupsEnabled,
    flows: organisation.flowsEnabled,
    forms: organisation.formsEnabled,
    ticketing: organisation.ticketingEnabled,
  };

  const NAVIGATION_GROUPS = [
    {
      name: "Ticketing",
      items: [
        {
          name: "Home",
          href: "/dashboard",
          icon: HomeIcon,
        },
        {
          name: "Tickets",
          href: "/dashboard/tickets",
          icon: TicketIcon,
        },
        {
          name: "Addons",
          href: "/dashboard/addons",
          icon: PlusCircleIcon,
        },
        {
          name: "Promo Codes",
          href: "/dashboard/promo-codes",
          icon: TagIcon,
        },
        {
          name: "Orders",
          href: "/dashboard/orders",
          icon: BanknotesIcon,
        },
        {
          name: "Attendees",
          href: "/dashboard/attendees",
          icon: QrCodeIcon,
        },
        {
          name: "Reporting",
          href: "/dashboard/reporting",
          icon: DocumentChartBarIcon,
        },
      ],
    },
    {
      name: "Accreditation",
      items: [
        {
          name: "Groups",
          href: "/dashboard/accreditation/groups",
          icon: CubeIcon,
        },
        {
          name: "Emails",
          href: "/dashboard/accreditation/emails",
          icon: EnvelopeIcon,
        },
        {
          name: "Forms",
          href: "/dashboard/forms",
          icon: PencilSquareIcon,
        },
        {
          name: "Flows",
          href: "/dashboard/flows",
          icon: BoltIcon,
        },
      ],
    },
    {
      name: "Platform",
      items: [
        {
          name: "Users",
          href: "/dashboard/users",
          icon: UsersIcon,
          children: [
            {
              name: "Users",
              href: "/dashboard/users",
            },
            {
              name: "Groups",
              href: "/dashboard/groups",
            },
          ],
        },
      ],
    },
  ];

  const filteredNavigation = NAVIGATION_GROUPS.map((group) => ({
    ...group,
    items: group.items.filter((item) => {
      if (group.name === "Accreditation") {
        if (item.name === "Groups" && !featureFlags.accreditationGroups)
          return false;
        if (item.name === "Emails" && !featureFlags.accreditationEmails)
          return false;
        if (item.name === "Flows" && !featureFlags.flows) return false;
        if (item.name === "Forms" && !featureFlags.forms) return false;
      }
      if (group.name === "Ticketing") {
        // Always return tickets as we always need them
        if (item.name === "Tickets") return true;
        // if it's anything else, ticketing must be enabled
        if (featureFlags.ticketing) return true;
        return false;
      }
      return true;
    }),
  })).filter((group) => group.items.length > 0);

  const Item = ({ item }: any) => {
    const isCurrent =
      location.pathname === item.href ||
      (item.children &&
        item.children.some((child: any) => location.pathname === child.href));
    const [open, setOpen] = useState(isCurrent);

    return item.children ? (
      <div onClick={() => setOpen(!open)} className="">
        <div className="flex items-center text-sm group dark:hover:bg-white/10 hover:bg-gray-100 p-1.5 rounded-md cursor-pointer text-gray-500 dark:text-white/80">
          <div>
            <item.icon className="h-[1.1rem] h-[1.1rem] shrink-0 dark:text-white/80" />
          </div>
          <div className="ml-2">{item.name}</div>
          <div className="flex flex-1 justify-end text-gray-400">
            {open ? (
              <ChevronDownIcon className="h-5 w-5 shrink-0 dark:text-white" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 shrink-0 dark:text-white" />
            )}
          </div>
        </div>
        {open && (
          <div className="border-l dark:border-white/15 border-gray-300 ml-3.5 mt-2">
            {item.children.map((child: any) => (
              <Link
                to={child.href}
                className={`${
                  child.href == location.pathname ? "bg-white/10" : ""
                } ml-[0.6rem] flex items-center text-sm dark:hover:bg-white/10 hover:bg-gray-100  text-gray-500 dark:text-white/80 px-2.5 py-1 rounded-md mb-1`}
              >
                {child.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    ) : (
      <Link
        to={item.href}
        className={`${
          item.href === location.pathname ? "bg-white/10" : ""
        } flex items-center text-sm group dark:hover:bg-white/10 hover:bg-gray-100 p-1.5 rounded-md`}
      >
        <div>
          <item.icon className="h-[1.1rem] h-[1.1rem] shrink-0 dark:text-white text-gray-500" />
        </div>
        <div className="ml-2 dark:text-white/90 text-gray-500">{item.name}</div>
      </Link>
    );
  };

  return (
    <div className="">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white dark:dark:bg-dark-primary pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="relative ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4 mb-4">
                  {darkMode.isDarkMode ? (
                    <svg
                      id="Livello_2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 648.3 150.34"
                      className="ml-1"
                      height="25"
                    >
                      <g id="Livello_1-2">
                        <rect
                          className="fill-white"
                          x="111.99"
                          y="38.89"
                          width="33.07"
                          height="109.44"
                        />
                        <path
                          className="fill-white"
                          d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                        />
                        <polygon
                          className="fill-white"
                          points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                        />
                        <path
                          className="fill-white"
                          d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                        />
                        <path
                          className="fill-white"
                          d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                        />
                        <path
                          className="fill-white"
                          d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                        />
                        <polygon
                          className="fill-white"
                          points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      id="Livello_2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 648.3 150.34"
                      className="ml-1"
                      height="25"
                    >
                      <g id="Livello_1-2">
                        <rect
                          className="fill-indigo-500"
                          x="111.99"
                          y="38.89"
                          width="33.07"
                          height="109.44"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                        />
                        <polygon
                          className="fill-indigo-500"
                          points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                        />
                        <path
                          className="fill-indigo-500"
                          d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                        />
                        <polygon
                          className="fill-indigo-500"
                          points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                        />
                      </g>
                    </svg>
                  )}
                </div>
                <EventSelector />
                <div className="mt-4 h-0 flex-1 overflow-y-auto">
                  <nav className="px-6">
                    {filteredNavigation.map((group) => (
                      <div key={group.name} className="mb-5">
                        <div
                          data-sidebar="group-label"
                          className="text-stone-400 flex h-8 shrink-0 items-center rounded-md text-xs font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                        >
                          {group.name}
                        </div>
                        <ul
                          data-sidebar="menu"
                          className="flex w-full min-w-0 flex-col gap-1 text-white -mx-[0.35rem]"
                        >
                          {group.items.map((item) => (
                            <Item item={item} />
                          ))}
                        </ul>
                      </div>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-gray-200 lg:dark:border-white/5 bg-light-primary lg:pb-4 lg:pt-8 dark:bg-dark-primary">
        <div className="flex flex-shrink-0 items-center px-4">
          {/* <div className="bg-purple-500 rounded-md h-8 w-8 flex items-center justify-center mr-2 shadow-md">
            <TicketIcon className="h-6 w-6 text-gray-900" />
          </div>
          <h1 className="text-purple-500 text-2xl font-semibold">ticketr</h1> */}
          {darkMode.isDarkMode ? (
            <svg
              id="Livello_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 648.3 150.34"
              className="ml-1"
              height="25"
            >
              <g id="Livello_1-2">
                <rect
                  className="fill-white"
                  x="111.99"
                  y="38.89"
                  width="33.07"
                  height="109.44"
                />
                <path
                  className="fill-white"
                  d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                />
                <polygon
                  className="fill-white"
                  points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                />
                <path
                  className="fill-white"
                  d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                />
                <path
                  className="fill-white"
                  d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                />
                <path
                  className="fill-white"
                  d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                />
                <polygon
                  className="fill-white"
                  points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                />
              </g>
            </svg>
          ) : (
            <svg
              id="Livello_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 648.3 150.34"
              className="ml-1"
              height="25"
            >
              <g id="Livello_1-2">
                <rect
                  className="fill-indigo-500"
                  x="111.99"
                  y="38.89"
                  width="33.07"
                  height="109.44"
                />
                <path
                  className="fill-indigo-500"
                  d="m215.02,67.75c4.95,0,9.26,1.25,13.2,3.82,3.89,2.53,6.88,6.22,8.89,10.98l1.1,2.59,30.55-10.59-1.25-2.96c-4.64-10.96-11.69-19.57-20.97-25.6-9.26-6.04-19.87-9.11-31.51-9.11-16.51,0-30.5,5.48-41.59,16.29-11.11,10.83-16.74,24.44-16.74,40.44s5.63,29.58,16.74,40.44c11.1,10.81,25.09,16.29,41.59,16.29,11.61,0,22.21-3.06,31.51-9.11,9.29-6.06,16.35-14.67,20.97-25.6l1.25-2.96-30.55-10.59-1.1,2.59c-2.02,4.76-5.03,8.5-8.92,11.1-3.96,2.63-8.27,3.91-13.17,3.91-6.94,0-12.58-2.41-17.25-7.37-4.71-5.02-7-11.13-7-18.69s2.29-13.48,7-18.49c4.65-4.96,10.3-7.37,17.25-7.37Z"
                />
                <polygon
                  className="fill-indigo-500"
                  points="385.78 38.89 343.55 38.89 310.83 76.03 310.83 0 277.76 0 277.76 148.33 310.83 148.33 310.83 97.6 318.49 107.69 350.26 148.33 391.95 148.33 343.22 85.74 385.78 38.89"
                />
                <path
                  className="fill-indigo-500"
                  d="m437.12,36.88c-16.14,0-29.85,5.49-40.79,16.3-10.98,10.82-16.54,24.43-16.54,40.43s5.63,29.59,16.74,40.43c11.1,10.82,25.1,16.3,41.59,16.3,11.33,0,21.59-2.67,30.49-7.94,7.18-4.23,13.15-9.7,17.74-16.28l2.04-2.93-27.58-12.95-1.48,1.86c-4.77,6.03-11.45,8.96-20.41,8.96-6.25,0-11.67-1.72-16.12-5.09-3.45-2.63-6.01-5.83-7.82-9.74h78.01l.4-2.53c.62-3.79.92-7.48.92-11.04,0-13.53-4.39-25.6-13.11-35.98-11.02-13.13-25.86-19.8-44.1-19.8Zm-21.63,42.7c1.74-3.49,4.15-6.37,7.3-8.72,4.25-3.17,8.94-4.71,14.33-4.71,5.67,0,10.46,1.54,14.63,4.71,3.11,2.35,5.49,5.21,7.2,8.72h-43.46Z"
                />
                <path
                  className="fill-indigo-500"
                  d="m535.04,8.94h-33.07v96.29c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-35.64h26.86v-32.31h-26.86V8.94Z"
                />
                <path
                  className="fill-indigo-500"
                  d="m73.5,38.89V8.94h-33.07v31.63H0l13.02,16.15L0,72.86h40.43v32.37c0,14.28,3.75,25.15,11.14,32.33,7.36,7.15,18.66,10.77,33.56,10.77h15.23v-29.87h-15.23c-8.04,0-11.63-3.59-11.63-11.63v-33.98h26.86v-32.29h-26.86v-1.68Z"
                />
                <polygon
                  className="fill-indigo-500"
                  points="648.3 38.91 606.6 38.91 606.6 38.89 573.52 38.89 573.52 38.91 573.52 71.2 573.52 148.33 606.6 148.33 606.6 71.2 633.46 71.2 648.3 71.2 635.28 55.05 648.3 38.91"
                />
              </g>
            </svg>
          )}
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="mt-5 flex h-0 flex-1 flex-col overflow-y-auto pt-1">
          <EventSelector />

          {/* Navigation */}
          <nav className="mt-4 pl-6 pr-2">
            <div className="">
              {filteredNavigation.map((group) => (
                <div key={group.name} className="mb-5">
                  <div
                    data-sidebar="group-label"
                    className="text-stone-400 flex h-8 shrink-0 items-center rounded-md text-xs font-medium text-sidebar-foreground/70 outline-none ring-sidebar-ring transition-[margin,opacity] duration-200 ease-linear focus-visible:ring-2 [&amp;>svg]:size-4 [&amp;>svg]:shrink-0 group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0"
                  >
                    {group.name}
                  </div>
                  <ul
                    data-sidebar="menu"
                    className="flex w-full min-w-0 flex-col gap-1 text-white -mx-[0.35rem]"
                  >
                    {group.items.map((item) => (
                      <Item item={item} />
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </nav>
          <div className="mt-auto">
            <AccountMenu />
          </div>
        </div>
      </div>
    </div>
  );
};
