import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  ConfirmPaymentData,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { State } from "../store/store";
import { Error } from "../components/Error";
import { OrderResponse } from "../models/Order";
import { Button } from "@/components/ui/button";
import { useDarkMode } from "usehooks-ts";
import { PaymentDetailsProps } from "@/components/PaymentDetails";

interface Props {
  order: OrderResponse;
  redirectUrl?: string;
  buttonText?: string;
}

export const PaymentDetailsForm = ({
  total,
  monthlyTotal,
  redirectUrl,
  clientSecret,
  buttonText,
  onPaymentSuccess,
}: PaymentDetailsProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const event = useSelector((state: State) => state.cart.event);
  const addonCart = useSelector((state: State) => state.cart.addonCart);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSetupIntent, setIsSetupIntent] = useState(false);
  const darkMode = useDarkMode();

  // Decide if the payment is a Payment Intent (Once Off) or Setup Intent (Payment Plan)
  useEffect(() => {
    if (monthlyTotal) {
      setIsSetupIntent(true);
    }
  }, [monthlyTotal]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const env = import.meta.env.VITE_ENVIRONMENT;

    // If it's a Setup Intent, the call confirmSetup, not payment
    if (event || onPaymentSuccess) {
      // If we have an event then set the return URL
      const paymentReturnURL =
        event && event.organisation
          ? `${env === "local" ? "http" : "https"}://${
              event.organisation.domain
            }/${event.slug}/complete`
          : null;

      // Only add the return URL if we don't have a callback and we have an event
      const confirmParams = {
        ...(onPaymentSuccess == null && {
          return_url: redirectUrl ? redirectUrl : paymentReturnURL,
        }),
      } as ConfirmPaymentData;

      if (isSetupIntent) {
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          redirect: onPaymentSuccess ? "if_required" : "always",
          confirmParams,
        });
        if (setupIntent) {
          // Call the callback if we have a setupIntent
          onPaymentSuccess && onPaymentSuccess(setupIntent);
        } else if (error) {
          if (
            error.type === "card_error" ||
            error.type === "validation_error"
          ) {
            if (error.message) {
              setMessage(error.message);
            }
          } else {
            setMessage("An unexpected error occurred.");
          }
        }
      } else {
        // if we have onPaymentSuccess, we don't want to redirect, so we set the redirect to if_required
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          redirect: onPaymentSuccess ? "if_required" : "always",
          confirmParams,
        });
        if (paymentIntent) {
          // Call the callback if we have a paymentIntent
          onPaymentSuccess && onPaymentSuccess(paymentIntent);
        } else if (error) {
          if (
            error.type === "card_error" ||
            error.type === "validation_error"
          ) {
            if (error.message) {
              setMessage(error.message);
            }
          } else {
            setMessage("An unexpected error occurred.");
          }
        }
      }
    } else {
      console.error("Cannot setup Payment because there is no Event");
    }
    setIsLoading(false);
    // todo: no error, set that the order is done
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {clientSecret && elements && (
          <Button
            id="submit"
            className="w-full mt-7 text-gray-900"
            variant={darkMode.isDarkMode ? "outline" : "default"}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-blue-200"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `${buttonText ? buttonText : "Pay"} €${
                monthlyTotal
                  ? addonCart
                    ? (
                        monthlyTotal +
                        (addonCart.reduce(
                          (sum, current) => sum + current.lineTotal,
                          0
                        ) +
                          addonCart.reduce(
                            (sum, current) =>
                              sum + current.quantity * parseFloat(current.fee),
                            0
                          ))
                      ).toFixed(2)
                    : monthlyTotal.toFixed(2)
                  : total?.toFixed(2)
              }`
            )}
          </Button>
        )}
        {/* Show any error or success messages */}
        {message && (
          <div className="mt-4">
            <Error error={message} />
          </div>
        )}
      </form>
    </div>
  );
};
