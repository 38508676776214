import { AccreditationGroup } from "@/models/accreditation";
import { useAccreditationStore } from "@/store/accreditation";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, FormikValues } from "formik";
import { Fragment } from "react/jsx-runtime";
import yup from "@/crud/yup-extended";
import axios from "axios";
import { BASE_URL } from "@/dashboardQueries";
import { EmojiPicker } from "@/crud/form/EmojiPicker";
import { Input } from "@/crud/form/Input";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Textarea } from "@/crud/form/Textarea";

interface Props {
  groupId: string | undefined;
  groupToEdit: AccreditationGroup | null | undefined;
}

export const CreateGroupDialog = ({ groupId, groupToEdit }: Props) => {
  const queryClient = useQueryClient();
  const { createGroupOpen, setCreateGroupOpen } = useAccreditationStore();
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);

  const onEmojiSelect = (emoji: any) => {
    setSelectedEmoji(emoji.native);
  };

  const handleSubmit = async (values: FormikValues) => {
    try {
      if (groupId) {
        values["parent"] = groupId;
      }
      const response = await axios.post(
        `${BASE_URL}/accreditation/groups`,
        values
      );
      setCreateGroupOpen(false);
      // Invalidate and refetch the groups query
      queryClient.invalidateQueries(["groups"]);
    } catch (error) {}
  };

  return (
    <Transition.Root show={createGroupOpen} as={Fragment}>
      <Dialog
        open={createGroupOpen}
        onClose={setCreateGroupOpen}
        className="relative z-10"
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="h-full border-l border-white/5 ">
                    <Formik
                      initialValues={
                        groupToEdit
                          ? groupToEdit
                          : { name: "", description: "" }
                      }
                      validationSchema={yup.object({
                        name: yup.string().required("Required"),
                        description: yup.string(),
                        icon: yup.string(),
                      })}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({ errors, handleSubmit, values }) => (
                        <form className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-dark-primary shadow-xl">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="bg-gray-50 px-4 py-6 sm:px-6 dark:bg-dark-secondary">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-base font-semibold text-gray-900 dark:text-white">
                                    {groupToEdit ? "Edit" : "Create"} Group
                                  </Dialog.Title>
                                  <p className="text-sm text-gray-500 dark:text-gray-400">
                                    Fill in the details below to{" "}
                                    {groupToEdit ? "edit" : "create"} a group
                                  </p>
                                </div>
                                <div className="flex h-7 items-center">
                                  <button
                                    type="button"
                                    onClick={() => setCreateGroupOpen(false)}
                                    className="relative text-gray-400 hover:text-gray-500"
                                  >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      aria-hidden="true"
                                      className="size-6"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Divider container */}
                            <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-white/10 sm:py-0">
                              {/* Name */}
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5"
                                  >
                                    Name
                                  </label>
                                </div>
                                <div className="sm:col-span-2 flex">
                                  <div className="mr-2">
                                    <EmojiPicker
                                      name="icon"
                                      onSelect={onEmojiSelect}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <Input
                                      name="name"
                                      type="text"
                                      value={values.name}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Description */}
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-description"
                                    className="block text-sm/6 font-medium text-gray-900 dark:text-white sm:mt-1.5"
                                  >
                                    Description
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <Textarea
                                    name="description"
                                    required={false}
                                    value={values.description}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="shrink-0 border-t border-gray-200 dark:border-white/10 px-4 py-5 sm:px-6 dark:bg-dark-secondary">
                            <div className="flex justify-end space-x-3">
                              <button
                                type="button"
                                onClick={() => setCreateGroupOpen(false)}
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={() => handleSubmit()}
                                className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </Transition.Child>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
