import { useEffect } from "react";
import { Tickets } from "../components/Tickets";
import { Checkout } from "../components/Checkout";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { PortalLayout } from "../components/portal/layouts/PortalLayout";
import { CartStep } from "../constants";
import { Addons } from "../components/addons/Addons";
import { cartActions } from "../store/cart";
import { Nav } from "../components/portal/Nav";
import { getFeatureFlag } from "../utils/feature-flags";
import { NewEventDetails } from "@/components/portal/NewEventDetails";

export const Event = () => {
  const dispatch = useDispatch();
  const portalNavEnabled = getFeatureFlag("PORTAL_NAV_ENABLED");
  const step = useSelector((state: State) => state.cart.step);
  const event = useSelector((state: State) => state.cart.event);

  // todo: store an order ID in the local storage with an expiry and retrieve it at checkout time

  // When we load the page, remove the loaded order
  useEffect(() => {
    dispatch(cartActions.ClearOrder());
  }, []);

  return (
    <PortalLayout>
      <div className="relative lg:mt-0 min-h-screen pt-2">
        {portalNavEnabled && <Nav />}

        {/* {!localStorage.getItem("presale") && <PresaleLock />} */}
        {/* Background color split screen for large screens */}
        <div className="flex min-h-full flex-1 flex-col justify-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-xl mb-4">
            <div
              className={`flex mt-4 ${
                step === CartStep.CHECKOUT ? "lg:hidden" : ""
              }`}
            >
              <NewEventDetails event={event} />
            </div>
          </div>

          {step === CartStep.TICKETS && <Tickets />}
          {step === CartStep.ADDONS && <Addons />}
          {step === CartStep.CHECKOUT && <Checkout />}
        </div>
      </div>
    </PortalLayout>
  );
};
