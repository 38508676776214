import { useFormStore } from "@/store/form";
import {
  SelectedField,
  SelectedFormField,
  SelectedContentField,
  FormField,
} from "../../../models/form";
import { ChoiceField } from "./fields/ChoiceField";
import { ContactField } from "./fields/ContactField";
import { ContentField } from "./fields/ContentField";
import { NumberField } from "./fields/NumberField";
import { OtherField } from "./fields/OtherField";
import { TextField } from "./fields/TextField";
import { TimeField } from "./fields/TimeField";
import { useFormikContext } from "formik";
import { ArrowRightIcon } from "lucide-react";
import { useEffect } from "react";

export const renderField = (
  field: SelectedField,
  index: number,
  inFormBuilder?: boolean,
  hideLabels?: boolean,
  name?: string
) => {
  // debugger;
  if ((field as SelectedFormField).category) {
    const formField = field as SelectedFormField;
    switch (formField.category.name) {
      case "Contact":
        return (
          <ContactField
            inFormBuilder={inFormBuilder}
            field={formField}
            hideLabels={hideLabels}
            key={index}
            name={name}
          />
        );
      case "Number":
        return (
          <NumberField
            inFormBuilder={inFormBuilder}
            index={index}
            field={formField}
            hideLabels={hideLabels}
            key={index}
            name={name}
          />
        );
      case "Text":
        return (
          <TextField
            inFormBuilder={inFormBuilder}
            field={formField}
            hideLabels={hideLabels}
            key={index}
            name={name}
          />
        );
      case "Time":
        return (
          <TimeField
            inFormBuilder={inFormBuilder}
            field={formField}
            hideLabels={hideLabels}
            key={index}
            name={name}
          />
        );
      case "Choice":
        return (
          <ChoiceField
            inFormBuilder={inFormBuilder}
            field={formField}
            hideLabels={hideLabels}
            key={index}
            name={name}
          />
        );
      case "Content":
        return <ContentField field={formField} key={index} />;
      case "Other":
        return (
          <OtherField
            inFormBuilder={inFormBuilder}
            field={formField}
            key={index}
          />
        );
      default:
        return <div key={index}>Unknown field type</div>;
    }
  } else if ((field as SelectedContentField).content) {
    console.log("innnn connntent");
    const contentField = field as SelectedContentField;
    return <ContentField field={contentField} key={index} />;
  } else {
    return <div key={index}>Unknown field type</div>;
  }
};

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  // For when we can't rely on values to render the form (when we are facing the public, pass those in)
  formMetaInformation?: SelectedField[];
}

export const FormRenderer = ({ formMetaInformation, inFormBuilder }: Props) => {
  const { activeField, setActiveField } = useFormStore();
  const { values } = useFormikContext<{ fields: SelectedField[] }>();

  const getFormFieldStyles = (field: SelectedField) => {
    return `${
      activeField.id === field.id
        ? "border-l-4 pl-4 border-green-400/50"
        : "border-transparent"
    } cursor-pointer`;
  };

  useEffect(() => {
    if (activeField) {
      const el = document.getElementById("field-" + activeField.id);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [activeField]);

  const handleFieldClick = (field: FormField) => {
    setActiveField(field);
  };

  const renderValues = (values: any) => {
    return values.fields.map((field, index) => (
      <div
        key={index}
        id={`field-${field.id}`}
        className={`my-5 relative ${
          inFormBuilder ? getFormFieldStyles(field) : ""
        }`}
        onClick={() => inFormBuilder && handleFieldClick(field)}
      >
        {inFormBuilder && activeField.id === field.id && (
          <div className="absolute left-0 top-0 -ml-[1.4rem] -mt-[0.1rem] text-green-400/50">
            <ArrowRightIcon className="h-4 w-4 mr-2" />
          </div>
          // <div
          //   style={{ top: "0.82rem" }}
          //   className="text-xs translate-y-1 mt-1 -rotate-90 -translate-x-1/2 absolute text-white left-[-.75rem] bg-green-400/50 px-1.5 rounded-t-md"
          // >
          //   selected
          // </div>
        )}
        {renderField(field, index, inFormBuilder)}
        {(field as SelectedFormField).helpText && (
          <p id="help-text" className="mt-2 text-sm/6 text-gray-600">
            {(field as SelectedFormField).helpText}
          </p>
        )}
      </div>
    ));
  };

  return (
    <div className="w-full p-4 -mt-4">
      {formMetaInformation
        ? renderValues(formMetaInformation)
        : renderValues(values)}
    </div>
  );
};
