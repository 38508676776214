import { Formik } from "formik";
import yup from "../../../crud/yup-extended";
import { dashboardActions } from "../../../store/dashboard";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { CreateEventForm } from "./CreateEventForm";
import { useDispatch } from "react-redux";
import { Event } from "../../../models/Dashboard";
import { createEvent } from "../../../dashboardQueries";
import { useState } from "react";
import { is } from "date-fns/locale";

interface Props {
  callback?: (eventId: string) => void;
  outsideModal?: boolean;
}

export const CreateEventFormik = ({ callback, outsideModal }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      validationSchema={yup.object({
        name: yup.string().required("Required"),
        location: yup.string().required("Required"),
        startDate: yup.date().required("Required"),
        endDate: yup
          .date()
          .required("Required")
          .min(yup.ref("startDate"), "End date can't be before start date"),
        description: yup.string().required("Required"),
      })}
      onSubmit={async (values, { resetForm }) => {
        setIsLoading(true);
        const response = await createEvent(values as Event);
        const event: Event = response.data;
        if (callback) callback(event.uuid);
        dispatch(
          dashboardActions.SetCreateEventSuccess({
            createEventSuccess: true,
          })
        );
        setIsLoading(false);
      }}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, handleSubmit, values, setFieldValue, initialValues }) => (
        <>
          <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="rounded-md bg-light-primary dark:bg-dark-primary bg-gray-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-gray-900 dark:text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-gray-900 dark:text-white">
                    Events that are submitted will be in "draft mode" and
                    reviewed before they are live.
                  </p>
                </div>
              </div>
            </div>
            <CreateEventForm />
          </div>
          {outsideModal ? (
            <div className="mx-4 sm:mx-6 -mt-5 lg:-mb-6 -mb-3">
              <button
                type="button"
                onClick={() => handleSubmit()}
                disabled={isLoading}
                className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isLoading ? (
                  <>
                    Creating event...
                    <div className="ml-3">
                      <svg
                        className="animate-spin -ml-1 mr-4 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  </>
                ) : (
                  <>Create event</>
                )}
              </button>
            </div>
          ) : (
            <div className="bg-light-primary dark:bg-dark-secondary py-3 sm:flex sm:flex-row-reverse sm:px-3">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};
