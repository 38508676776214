import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import { classNames } from "../../../../utils/styles";
import { SelectedField } from "../../../../models/form";
import { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Field, getIn, useFormikContext } from "formik";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
  hideLabels?: boolean;
  name?: string;
}

const YES_NO_OPTIONS = [
  { id: "yes", title: "Yes" },
  { id: "no", title: "No" },
];

const LEGAL_OPTIONS = [
  { id: "i_accept", title: "I accept" },
  { id: "i_dont_accept", title: "I don't accept" },
];

export const ChoiceField = ({
  inFormBuilder,
  field,
  hideLabels,
  name,
}: Props) => {
  const type = field.field.type;
  const isMultipleChoice = type === "multipleChoice";
  const [selectedChoices, setSelectedChoices] = useState<string | string[]>(
    isMultipleChoice ? [] : ""
  );
  const { setFieldValue, errors } = useFormikContext();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, name ? name : field.uuid) || "");
  }, [errors]);

  const handleChange = (value: string | string[]) => {
    setSelectedChoices(value);
    setFieldValue(name ? name : field.uuid, value);
  };

  const getBorderColour = () => {
    if (error && !inFormBuilder) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  return (
    <>
      {!hideLabels && (
        <div className="flex justify-between">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
          >
            {field.name ? field.name : "Untitled"}
          </label>
          {field.required && (
            <span
              id="email-optional"
              className="text-sm leading-6 text-gray-500"
            >
              Required
            </span>
          )}
        </div>
      )}

      {field.description && !hideLabels && (
        <p className="mt-1 text-sm/6 text-gray-500">{field.description}</p>
      )}

      {(type === "dropdown" || type === "multipleChoice") && (
        <div className="mt-2">
          <Listbox
            value={selectedChoices}
            onChange={handleChange}
            multiple={isMultipleChoice}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button
                    className={`px-3 py-2 flex ${getBorderColour()} w-full relative cursor-default rounded-md bg-white dark:bg-zinc-800 dark:text-gray-400 p-1 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 sm:text-sm sm:leading-6`}
                  >
                    {isMultipleChoice
                      ? selectedChoices.length === 0
                        ? "Select choices"
                        : (selectedChoices as string[]).join(", ")
                      : selectedChoices || "Select"}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="px-1 absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-dark-secondary dark:ring-white/5 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {field.options && (
                        <>
                          {field.options.map((option, index) => (
                            <Listbox.Option
                              key={index}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white "
                                    : "text-gray-900 dark:text-gray-400",
                                  "relative cursor-pointer select-none py-2 pl-2 pr-2 rounded-md "
                                )
                              }
                              value={option}
                            >
                              {({ selected }) => (
                                <>
                                  <div
                                    className={`block truncate flex ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    <div className="flex-1">{option}</div>
                                    <div className="flex justify-end">
                                      {selected && (
                                        <CheckIcon className="h-5 w-5 dark:text-white" />
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </>
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {!inFormBuilder && (
            <>
              {error && Object.keys(error).length > 0 && (
                <div className="mt-2 text-xs text-red-500">{error}</div>
              )}
            </>
          )}
        </div>
      )}

      {(type === "yesNo" || type === "legal") && (
        <fieldset>
          <div className="space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0 mt-2">
            {[...(type === "yesNo" ? YES_NO_OPTIONS : LEGAL_OPTIONS)].map(
              (option) => (
                <div key={option.id} className="flex items-center">
                  <Field
                    id={`${field.uuid}-${option.id}`}
                    name={field.uuid}
                    type="radio"
                    value={option.id}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor={`${field.uuid}-${option.id}`}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900 dark:text-gray-500"
                  >
                    {option.title}
                  </label>
                </div>
              )
            )}
          </div>
          {!inFormBuilder && (
            <>
              {error && Object.keys(error).length > 0 && (
                <div className="mt-2 text-xs text-red-500">{error}</div>
              )}
            </>
          )}
        </fieldset>
      )}
    </>
  );
};
