import { Dropdown } from "@/crud/form/Dropdown";
import { Switch } from "@/crud/form/Switch";
import { ToggleSwitch } from "@/crud/form/ToggleSwitch";
import { EmailTemplate } from "@/models/accreditation";
import { DropdownOption } from "@/models/Dashboard";
import { getIn, useFormikContext } from "formik";
import { toCamelCase } from "js-convert-case";
import { over } from "lodash";
import { useEffect, useState } from "react";

interface Props {
  index: number;
  email: any;
  options: Array<DropdownOption>;
}

export const Email = ({ index, email, options }: Props) => {
  const { values, setFieldValue } = useFormikContext();
  const name = toCamelCase(email.name);

  const [override, setOverride] = useState(!!getIn(values, name));

  useEffect(() => {
    if (!override) {
      setFieldValue(name, null);
    }
  }, [override]);

  return (
    <div className={index > 0 ? "pt-4" : ""} key={email.name}>
      <div className="flex">
        <dt className="flex-1 pr-6 font-medium text-gray-900 sm:w-64 dark:text-white">
          {email.name}
          <div className="text-xs font-normal text-gray-400 ">
            {email.description}
          </div>

          {(override || !!getIn(values, name)) && (
            <div className="mt-4 w-2/3">
              <Dropdown name={name} options={options} isUpdating />
            </div>
          )}
        </dt>
        <dd className="text-right">
          <div>
            <p className="dark:text-gray-500 text-xs mb-2">Override</p>
            <ToggleSwitch
              checked={override}
              handleChange={(checked: boolean) => {
                setOverride(checked);
              }}
            />
          </div>
        </dd>
      </div>
    </div>
  );
};
