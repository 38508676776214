import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import DatePicker from "../../../../crud/form/custom";
import { useEffect, useState } from "react";
import { SelectedField } from "../../../../models/form";
import { Field, getIn, useFormikContext } from "formik";
import { set, startOfDay, format } from "date-fns";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
  hideLabels?: boolean;
  name?: string;
}

export const TimeField = ({
  inFormBuilder,
  field,
  hideLabels,
  name,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const type = field.field.type;
  const { errors, values } = useFormikContext<any>();
  const [error, setError] = useState<any>("");
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setError(getIn(errors, name ? name : field.uuid) || "");
  }, [errors]);

  const handleDateChange = (newDate: Date) => {
    const fieldValue = new Date();
    // Get the time components from the original date
    const hours = fieldValue.getHours();
    const minutes = fieldValue.getMinutes();
    const seconds = fieldValue.getSeconds();
    const milliseconds = fieldValue.getMilliseconds();

    // Set just the date part
    const updatedDate = set(startOfDay(fieldValue), {
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      date: newDate.getDate(),
    });

    // Set the time components back to the updated date
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    updatedDate.setSeconds(seconds);
    updatedDate.setMilliseconds(milliseconds);

    const formattedDate = format(updatedDate, "yyyy-MM-dd");
    setFieldValue(field.uuid, formattedDate);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeValue = e.target.value;

    // if it's a time field, just set the time
    if (type === "time") {
      setFieldValue(name ? name : field.uuid, timeValue);
      return;
    } else {
      const [hours, minutes] = timeValue.split(":").map(Number);
      const fieldValue = new Date(
        name ? getIn(name, values) : values[field.uuid] || new Date()
      );

      const updatedDate = set(fieldValue, {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0,
      });

      const formattedDate = format(updatedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      setFieldValue(name ? name : field.uuid, formattedDate);
    }
  };

  const getBorderColour = () => {
    if (error && !inFormBuilder) {
      return "ring-red-500";
    }
    return "ring-gray-300 dark:ring-zinc-700";
  };

  return (
    <div className="">
      {!hideLabels && (
        <>
          {" "}
          <div className="flex justify-between">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              {field.name ? field.name : "Untitled"}
            </label>
            {field.required && (
              <span
                id="email-optional"
                className="text-sm leading-6 text-gray-500"
              >
                Required
              </span>
            )}
          </div>
          {field.description && (
            <p className="mt-1 text-sm/6 text-gray-500">{field.description}</p>
          )}
        </>
      )}

      <div className="flex">
        {(type === "date" || type === "dateAndTime") && (
          <div
            className={`mt-2 w-full ${type === "dateAndTime" ? "mr-4" : ""}`}
          >
            <DatePicker
              options={{
                autoHide: true,
                todayBtn: false,
                clearBtn: true,
                maxDate: new Date("2030-01-01"),
                minDate: new Date("1950-01-01"),
                theme: {
                  // todo: make dynamic based on dark mode
                  background: "",
                  todayBtn: "",
                  clearBtn: "",
                  icons: "",
                  text: "",
                  disabledText: "",
                  // todo: make dynamic based on dark mode
                  input: "bg-white",
                  inputIcon: "",
                  selected: "",
                },
                icons: {
                  prev: () => <ArrowLeftIcon className="w-6 h-6" />,
                  next: () => <ArrowRightIcon className="w-6 h-6" />,
                },
                datepickerClassNames: "top-12",
                // defaultDate: field.value ? new Date(field.value) : new Date(),
                language: "en",
              }}
              //   value={new Date(field.value)}
              onChange={handleDateChange}
              show={isOpen}
              setShow={setIsOpen}
            />
          </div>
        )}

        {(type === "time" || type === "dateAndTime") && (
          <div className="mt-2">
            <Field
              data-field-type={type}
              // value={inFormBuilder ? "" : field.value}
              type="time"
              name={
                type === "time"
                  ? name
                    ? name
                    : field.uuid
                  : name
                  ? `${name}-time`
                  : `${field.uuid}-time`
              }
              id={field.uuid}
              required={field.required}
              onChange={handleTimeChange}
              className={`${getBorderColour()} h-10 text-md items-center block dark:bg-zinc-800 dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6`}
            />
          </div>
        )}
      </div>

      {!inFormBuilder && (
        <>
          {error && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </>
      )}
    </div>
  );
};
