import { SelectedField } from "@/models/form";
import { FileIcon, UploadIcon } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { useFormikContext, getIn } from "formik";
import { useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { FileUploadField } from "@/crud/form/FileUploadField";

interface Props {
  // whether or not it's being created/edited in the form builder
  inFormBuilder?: boolean;
  field: SelectedField;
}

export const OtherField = ({ inFormBuilder, field }: Props) => {
  const type = field.field.type;
  const { setFieldValue, errors, values } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, field.uuid) || "");
  }, [errors]);

  const onDrop = (acceptedFiles: File[]) => {
    console.log("onDropd");
    setFieldValue(field.uuid, acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getBorderColour = () => {
    if (error && !inFormBuilder) {
      return "border-red-500";
    }
    return "border-gray-300 dark:border-zinc-700";
  };

  const onRemove = (index: number) => {
    const newFiles = files.filter((file: File, i: number) => i !== index);
    setFieldValue(field.uuid, newFiles);
  };

  const files = getIn(values, field.uuid);

  return (
    <div>
      {type === "fileUpload" && (
        <div>
          <div className="flex justify-between">
            <label
              htmlFor="file-upload"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
            >
              {field.name ? field.name : "Untitled"}
            </label>
            {field.required && (
              <span
                id="field-upload-optional"
                className="text-sm leading-6 text-gray-500"
              >
                Required
              </span>
            )}
          </div>

          {field.description && (
            <p className="mt-1 text-sm/6 text-gray-500">{field.description}</p>
          )}

          <FileUploadField name={field.uuid} />

          {!inFormBuilder && error && Object.keys(error).length > 0 && (
            <div className="mt-2 text-xs text-red-500">{error}</div>
          )}
        </div>
      )}
    </div>
  );
};
