import { DashboardLayout } from "@/components/dashboard/layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";

export const Flows = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout
      pageTitle="Flows"
      rightControls={
        <div>
          <button
            onClick={() => {
              navigate("/dashboard/flows/create");
            }}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create
          </button>
        </div>
      }
      requiredPermissions={[]}
    >
      <div className="overflow-x-scroll">
        <div className="w-full pb-36 pt-28">
          <div className="text-center">
            <h3 className="mt-2 text-xl font-semibold text-gray-900 dark:text-white">
              No Flows yet
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You can start by creating a Flow below
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => navigate("/dashboard/flows/create")}
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  ></path>
                </svg>
                Create Flow
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
