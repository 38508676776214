import React, { act, useEffect, useState } from "react";
import { BASE_URL } from "@/dashboardQueries";
import { AccreditationGroup } from "@/models/accreditation";
import { PaginatedResponse } from "@/models/Dashboard";
import axios from "axios";
import { BoxesIcon, Shapes, TrashIcon, UserIcon } from "lucide-react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAccreditationStore } from "@/store/accreditation";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
  useSensors,
  MouseSensor,
  useSensor,
} from "@dnd-kit/core";
import { SortableContext, useSortable, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { GroupOptionsButton } from "./GroupOptionsDropdown";
import { Pagination } from "../Pagination";
import { Search } from "../Search";

interface Props<T> {
  name: string;
  onRowClick: (groupId: number) => void;
  handleDelete: (group: T) => void;
  handleEditOpen: (groupId: number) => void;
  data: PaginatedResponse<T> | undefined;
  renderRowContent: (item: T) => React.ReactNode;
  groupHeading: React.ReactNode;
  onOrderUpdate: (
    model: string,
    payload: Array<{ itemId: number; order: number }>
  ) => void;
  onSearch?: (search: string) => void;
  hideOptionsButton?: boolean;
}

export const GroupList = <T extends {}>({
  name,
  onRowClick,
  handleDelete,
  handleEditOpen,
  data,
  renderRowContent,
  groupHeading,
  onOrderUpdate,
  onSearch,
  hideOptionsButton,
}: Props<T>) => {
  const { setCreateGroupOpen, setCreateGroupFromTemplateOpen } =
    useAccreditationStore();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [groupBeingDragged, setGroupBeingDragged] =
    useState<AccreditationGroup | null>(null);
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const [items, setItems] = useState<T[]>(data ? data.results : []);

  useEffect(() => {
    setItems(data ? data.results : []);
  }, [data]);

  // Customizing the MouseSensor to disable dragging on buttons
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 100, // Add delay to prevent accidental drags
      tolerance: 5, // Slight movement tolerance before starting a drag
    },
    onActivation: ({ event }) => {
      // Prevent drag activation if the target is a button
      if (
        event.target instanceof HTMLElement &&
        event.target.closest("button")
      ) {
        return false;
      }
      return true;
    },
  });

  const sensors = useSensors(mouseSensor);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
    const selectedGroup = items.find(
      (group: AccreditationGroup) => group.id === active.id
    );
    if (selectedGroup) {
      setGroupBeingDragged(selectedGroup);
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { over } = event;
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex(
        (group: AccreditationGroup) => group.id === active.id
      );
      const newIndex = items.findIndex(
        (group: AccreditationGroup) => group.id === over.id
      );

      const newItems = arrayMove(items, oldIndex, newIndex);
      await onOrderUpdate(
        name.toLowerCase(),
        newItems.map((item, index) => {
          return { itemId: item.id, order: index + 1 };
        })
      );
      setItems(newItems);
    }
    setActiveId(null);
    setOverId(null);
    setGroupBeingDragged(null);
  };

  interface SortableGroupRowProps<T> {
    group: T;
    onRowClick: (id: number) => void;
    handleDelete: (item: T) => void;
    handleEditOpen: (id: number) => void;
    renderRowContent: (item: T) => React.ReactNode;
    hideOptionsButton?: boolean;
  }

  const SortableGroupRow = <T extends { id: number }>({
    group,
    onRowClick,
    handleDelete,
    handleEditOpen,
    renderRowContent,
    hideOptionsButton,
  }: SortableGroupRowProps<T>) => {
    const { listeners, attributes, setNodeRef } = useSortable({ id: group.id });

    const isOver = overId === group.id;

    return (
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className={`flex justify-between items-center p-3 border-b border-gray-200 dark:border-white/10 relative ${
          false ? "px-4" : "px-6"
        }`}
        onClick={() => onRowClick(group.id)}
      >
        {isOver && (
          <div className="absolute inset-x-0 top-0 h-1 bg-blue-500"></div>
        )}
        {renderRowContent(group)}
        <div className="flex items-center space-x-2">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click when clicking the button
              handleDelete(group);
            }}
            type="button"
            className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20"
          >
            <TrashIcon className="text-red-500 h-4 w-4" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click when clicking the button
              handleEditOpen(group.id);
            }}
            type="button"
            className="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/20 ml-2"
          >
            <PencilSquareIcon className="text-green-500 h-4 w-4" />
          </button>
        </div>
      </div>
    );
  };

  return items.length > 0 ? (
    <div>
      {!false && (
        <div className="flex">
          <div className="w-full">
            <Search onSearch={(search: string) => onSearch(search)} />
          </div>
          {!hideOptionsButton && (
            <div className="flex flex-1 justify-end border-b dark:border-white/5 items-center pr-6">
              <GroupOptionsButton />
            </div>
          )}
        </div>
      )}
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items}>
          <div className="bg-white dark:bg-dark-secondary shadow overflow-hidden">
            <div
              className={`border-b dark:border-white/10 px-5 py-3 ${
                false ? "px-5" : "px-6"
              }`}
            >
              {groupHeading}
            </div>
            {items.map((group) => (
              <SortableGroupRow
                key={group.id}
                group={group}
                onRowClick={onRowClick}
                handleDelete={handleDelete}
                handleEditOpen={handleEditOpen}
                renderRowContent={renderRowContent}
                hideOptionsButton={hideOptionsButton}
              />
            ))}
          </div>
        </SortableContext>

        {groupBeingDragged && (
          <DragOverlay>
            <div className="bg-dark-secondary border-y border-white/10 pl-6 py-3">
              {renderRowContent(items.filter((item) => item.id == activeId)[0])}
            </div>
          </DragOverlay>
        )}
      </DndContext>
      {data && (
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(data.count / data.perPage)}
          onPageChange={(page: number) => setPage(page)}
        />
      )}
    </div>
  ) : (
    <></>
  );
};
