import { FORM_FIELDS } from "../../../constants/form";
import {
  FormFieldCategory,
  FormField as FormFieldModel,
} from "../../../models/form";
import { useFormStore } from "../../../store/form";
import { FormField } from "./FormField";
import { FieldArray, useFormikContext } from "formik";

interface Props {
  onPickCallback?: (category: FormFieldCategory, field: FormFieldModel) => void;
}

export const FieldPicker = ({ onPickCallback }: Props) => {
  const { setActiveField } = useFormStore();
  const { values } = useFormikContext<any>();

  const handleFieldSelection = (
    category: FormFieldCategory,
    field: FormFieldModel,
    push: (obj: any) => void
  ) => {
    let newField = {};
    // If it's a content field
    if (category.name === "Content") {
      newField = {
        id: values.fields.length + 1,
        uuid: "",
        // Give it a default name
        name: field.label,
        content: "",
        category: category,
        field: field,
      };
    } else {
      newField = {
        id: values.fields.length + 1,
        uuid: "",
        category: category,
        field: field,
        // Give it a default name
        name: field.label,
        description: "",
        helpText: "",
        required: false,
      };
    }

    if (category.name === "Choice") {
      newField = {
        ...newField,
        options: [],
      };
    }

    // Set the new field as the active field
    setActiveField(newField);

    // Push the new field to the FieldArray
    push(newField);
  };

  return (
    <FieldArray name="fields">
      {({ push }) => (
        <div className="flex flex-wrap">
          {Object.keys(FORM_FIELDS).map((category) => {
            const currentCategory = FORM_FIELDS[category];
            const fields = currentCategory.fields;
            return (
              <div key={category} className="flex flex-col w-1/3 mb-6 pr-4">
                <h2 className="text-sm font-medium px-1.5 dark:text-white">
                  {category}
                </h2>

                <div className="mt-1">
                  {fields.map((field: FormFieldModel, index: number) => (
                    <FormField
                      key={index}
                      field={field}
                      category={currentCategory}
                      onClick={() => {
                        handleFieldSelection(currentCategory, field, push);
                        if (onPickCallback) {
                          onPickCallback(currentCategory, field);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </FieldArray>
  );
};
